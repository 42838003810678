import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { blue } from 'tailwindcss/colors';
import withRouter from '@fuse/core/withRouter';
import CustomAxios from "app/shared-components/CustomAxios";
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().email('You must enter a valid email').required('You must enter a email'),
});

const defaultValues = {
    email: '',
};

function ForgotPassword({ setTabValue }) {
    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const [submitted, setSubmitted] = React.useState(false);
    const [submittedLoad, setSubmittedLoad] = React.useState(false);

    function onSubmit({ email }) {
        console.log(email)
        setSubmittedLoad(true)

        CustomAxios.POST(process.env.REACT_APP_WEB_API_FORGOT_PASSWORD,
            { email: email }).then((response) => {
                if (response.status == 200 && response.data !== null) {
                    setSubmitted(true)
                } else {

                }
            }).catch(() => {

            }).finally(() => {
                setSubmittedLoad(false)
            });
    }

    function goBackToSignIn() {
        console.log("hi")
        setTabValue()
    }
    return (
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0" style={{ minHeight: "310px" }}>
            <Typography className="flex items-center sm:mb-12 mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                <div onClick={() => goBackToSignIn()} style={{ cursor: "pointer" }}>
                    <FuseSvgIcon size={25} style={{ color: "blue", marginRight: "5px" }} >
                        heroicons-outline:arrow-sm-left
                    </FuseSvgIcon>
                </div>
                Forgot password?
            </Typography>

            <form
                name="registerForm"
                noValidate
                className="flex flex-col justify-center w-full mt-32"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-24"
                            label="Email"
                            type="email"
                            disabled={submitted}
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    )}
                />

                <LoadingButton
                    variant="contained"
                    color="secondary"
                    className=" w-full mt-4"
                    aria-label="Register"
                    loading={submittedLoad}
                    disabled={_.isEmpty(dirtyFields) || !isValid || submitted}
                    type="submit"
                    size="large"
                >
                    Reset Password
                </LoadingButton>

                {submitted && (
                    <Typography style={{ marginTop: "20px" }} variant="body1" color="textSecondary">
                        OK, we've sent you an email with instructions to reset your password.
                    </Typography>
                )}

            </form>
        </div>
    );
}

export default withRouter(ForgotPassword);