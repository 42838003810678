const locale = {
  InformService: "Inform Service",
  InformService2: "Cuztomize inform endpoint settings",
  InformService3: "Inform Service Settings",
  InformService4: "Inform service to make post request by given url and header",
  Licenses: "Licenses",
  Licenses2: 'Customize Licenses',
  Update: "Update",
  Update2: "Update via shared files",
  UpdateWhitelist: "Update Whitelist",
  UpdateWhitelist2: "A whitelist is a list of trusted applications or entities that are allowed to run or access a system, while everything else is blocked.",
  UpdateMalware: "Update Malwares",
  UpdateMalware2: "Current Malware Version",
  UpdateMalware3: "Updating the malware list involves regularly adding new known malware signatures or patterns.",
  licex: "License Expired",
  licex2: "is expired",
  Devices2: "Devices",
  Threats2: "Threats",
  Rd: "Registered Devices",
  Th: "Detected Threats",
  Rdth: "Registered-Devices vs Detected-Threats",

  APPLICATIONS: 'Applications',
  EXAMPLE: 'Example',
  DASHBOARD: 'Dashboard',
  ANALYSIS: "Analysis",
  MERCHANTS: "Devices",
  ALERTSS: "Alerts",
  USERS: "Users",
  API: "API",
  SETTINGS: "Settings",
  SETTINGS_SMTP: "SMTP",

  Applications: 'Applications',
  Example: 'Example',
  Dashboard: 'Dashboard',
  Analysis: "Analysis",
  Alertss: "Alerts",
  Users: "Users",
  Api: "API",
  Settings: "Settings",
  SettingsSmtp: "SMTP",

  Welcome: 'Welcome back',
  Notification: "Notifications",
  YouHave: "You have",
  YouHave2: "alert.",
  NoNotif: "There are no notifications for now.",
  Merchants: "Devices",
  LastMerch: "Last Device",
  Alerts: "Alerts",
  LastAlert: "Last Alert",
  Mails: "Mails",
  MailGroups: "Mail Groups",
  Mcc: "Merchant Category Codes",
  TopMcc: "Top MCC",
  AnalysisSummary: "Analysis Summary",
  EvsN: "Eligible vs Non-Eligible MCC's",
  Overview: "Overview",
  EligibleMerchants: "Eligible Merchants",
  NonEligibleMerchants: "Threat Detection",
  l1: "Mon",
  l2: "Tue",
  l3: "Wed",
  l4: "Thu",
  l5: "Fri",
  l6: "Sat",
  l7: "Sun",
  TestMerchants: "Test Merchants",
  NewAnalysis: "New Analysis of A Merchant",
  NewAnalysisDesc: "Single merchant analysis to test eligibility of given merchant category code.",
  MWebsite: "Merchant Website",
  Mmcc: "Merchant Category Codes(MCC)",
  FullAnalysis: "Full Analysis of The Merchants",
  FullAnalysisDesc: "Full analysis of all merchant category codes which are already in the system.",
  Scan: "Scan",
  PsWait: "Please Wait Scanning",
  Completed: "Completed",

  SingleSearchResult: "Search Analysis Results",
  Eligibility: "Eligibility",
  Result: "Result",
  Eligible: "Eligible",
  NonEligible: "Non-Eligible",
  Date: "Date",
  Merchant: "Merchant",
  MCC: "MCC",
  Ratio: "Ratio",
  Status: "Status",
  MerchantAnalyzeResultNText: "This merchant",
  MerchantAnalyzeResultNText2: "is",
  MerchantAnalyzeResultNText3: "lower then threshold with given",
  MerchantAnalyzeResultNText4: "MCC code.",
  MerchantAnalyzeResultText: "This merchant",
  MerchantAnalyzeResultText2: "looks clear with given",
  MerchantAnalyzeResultText3: "MCC code.",
  PleaseWaitScanningMerchs: "Please Wait Scanning Merchants",

  CustomizeMerchants: "Customize Devices",
  ManageMerchants: "Manage Devices",
  Remove: "REMOVE",
  Edit: "EDIT",
  Scan2: "SCAN",
  AddSingleMerchant: "Add Single Merchant",
  AddSDesc: "Add single merchant to the system.",
  AddMultipleMerchants: "Add Multiple Merchants",
  AddMDesc: "Insert excel to add multiple merchants.(A column is <b>Merchant</b> and B column is <b>MCC</b>)",
  UploadMerchs: "Upload Merchants Excel",
  NonEThreshold: "Non-Eligible Threshold",
  NonEThDesc: "Threshold value to tag merchants as non-eligible merchant. (The default value is <b>%80</b>)",
  Threshold: "Threshold",
  AutoAnalyze: "Auto Analysis Interval",
  AutoAnalyzeDesc: "Configure scan interval time to auto analyze merchants.",
  SelectInterval: "Select Interval",
  None: "None",
  Hourly: "Hourly",
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
  Save: "Save",
  MerchantInfo: "Merchant Info",
  RemoveSmall: "Remove",
  TabsInfo: "Info",
  TabsHistory: "History",
  AvarageRatio: "Avarage Ratio",
  MerchantRatioAnalysis: "Merchant Ratio Analysis",
  ThirtyDays: "30 Days",
  Overall: "Overall Score",
  OverallDesc: "Overall score is calculated by using the historical ratio according to status.",
  AvrRatioDesc: "Average ratio of last 30 days",
  EditMerchant: "Edit Merchant",
  Cancel: "Cancel",
  OK: "OK",

  CustomizeAlerts: "Customize Alerts",
  EditAlert: "Edit Alert",
  MailGroups: "Mail Groups",
  AlertName: "Name",
  AlertSentType: "Sent Type",
  AlertMailGroup: "Mail Group",
  AlertHistory: "Alert History",
  MailList: "Mail List",
  CustomizeMailList: 'Customize Mail List',
  EditMail: "Edit Mail",
  RemoveMails: "Remove Mails",
  RemoveMailsText: "Are you sure you want to delete these mails?",
  RemoveMailsText2: "All datas will be deleted associated with these mails.",
  AddMail: 'Add',

  MailGroups: "Mail Groups",
  MailGroupsC: "Customize Mail Groups",
  GroupName: "Group Name",
  GroupDesc: "Group Description",
  AddMailGroup: "Add Mail Group",
  RemoveMailsG: "Remove Mail Groups",
  RemoveMailsTextG: "Are you sure you want to delete these mail groups?",
  RemoveMailsTextG2: "All datas will be deleted associated with these mail groups.",

  AlertHistory: "Alert History",
  AlertHistoryC: "Customize Alert History",
  ResolveAll: "RESOLVE ALL",
  ResolveAllAlerts: "Resolve All Alerts",
  RaT1: "Are you sure you want to resolve all alerts?",
  RaT2: "All alerts will be marked as resolved.",
  Resolved: "Resolved",
  ResolvedM: "Mark as Resolved",

  Username: "Username",
  Permission: "Permission",
  CustomizeUsers: "Customize Users",
  AddUsers: "Add Users",
  SelectRole: "Select Role",
  RoleText1: "Can only view the content but cannot edit.",
  RoleText2: "Has full access and can view and edit the content.",
  LoginCredentials: "Login Credentials",
  RemoveUsers: "Remove Users",
  RmText1: "Are you sure you want to delete these users?",
  RmText2: "All datas will be deleted associated with these users.",
  RmText11Title: "Remove Licenses",
  RmText11: "Are you sure you want to delete these licenses?",
  RmText22: "All datas will be deleted associated with these licenses.",

  Customizesystemsettings: "Customize SMTP settings",
  SMTPsettings: "SMTP Settings",
  SmtpDesc: "Configure smtp settings to send alert notifications via e-mail.",

  RestApiUsage: "Rest Api Usage",

  MyProfile: "My Profile",
  SignOut: "Sign Out",

  Profile: "Profile",
  MainSettings: "Main Settings",
  MainSDesc: "This information will appear on your profile.",
  Phone: "Phone",
  CurrentPassword: "Current Password",
  TfaAuth: "Two-Factor Authentication",
  TfaAuthDesc: "Increase your account's security by enabling Two-Factor Authentication.",
  Status: "Status",
  Disabled: "Disabled",
  Enabled: "Enabled",
  NotConnected: "Not Connected",
  ManageTwoFactorAuth: "Setup Two Factor Authentication",
  SetupTwoFactor: "Setup 2FA",
  ScanQr: "Scan this QR code with your 2FA app",
  EnterQr: "Enter code from 2FA app",
  Connect2fa: "Connect to 2FA",
  ChangePassword: "Change Password",
  ChangePasswordDesc: "Avoid using common phrases, personal information, or consecutive characters.",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",

  Success: "Success",
  Success2: "Something Went Wrong",
  MailGroupAlreadyExists: "Mail Group Already Exists",
  DefaultGroupCRemoved: "Default Group Cannot Be Removed",
  MailExists: "Mail Already Exists",
  PleaseWait: "Please Wait",
  MccNotSupported: "MCC Not Supported",
  MerchSucc: "Success - ",
  MerchsAdded: " Merchants Added",
  ScanFirst: "Scan First To See Details",
  ScanStarted: "Started scanning. Merchants will be updated.",
  InvalidCode: "Invalid Code",
  CheckCurrentPassword: "Check Current Password",
  CheckPassword: "Check Password",
  Copy: "Copied",
  CopyT: "COPY",
  GenerateNewApi: "Generate New X-API-Key",

  LNo1: "Looks like you have no devices yet.",
  LNo2: "Looks like you have no users yet.",
  LNo3: "Looks like you have no logs yet.",

  Audit: "Audit",
  Audit2: "Audit Logs",

};

export default locale;
