import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Checkbox, Chip, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Skeleton, Stack, TableCell, TableRow, TextareaAutosize, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiDataTable from "mui-datatables";
import SettingsIcon from '@mui/icons-material/SettingsSuggestOutlined';
import withRouter from '@fuse/core/withRouter';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import AppleIcon from '@mui/icons-material/Apple';
import * as XLSX from 'xlsx';
import DeleteIcon from "@mui/icons-material/ToggleOff";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CardActions } from '@mui/material';
import CableIcon from '@mui/icons-material/ToggleOn';
import CableIconOff from '@mui/icons-material/ExtensionOff';
import CustomAxios from "app/shared-components/CustomAxios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { showMessage } from 'app/store/fuse/messageSlice';
import LoadingTableBody from 'app/shared-components/LoadingTableBody';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import MCCCodesSelect from 'app/shared-components/MCCCodesSelect';
import AndroidIcon from '@mui/icons-material/Android';

import { ThemeProvider } from '@mui/material/styles';
import { createMuiTheme } from "@mui/material/styles";
import ReactApexChart from 'react-apexcharts';
import SearchAnalysisChart from 'app/shared-components/SearchAnalysisChart';
import { GetDescMcc } from 'app/shared-components/GetDescMcc';
import { useTranslation } from 'react-i18next';
import ThreatColumn from 'app/shared-components/ThreatColumn';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



const themeMerchDialog = createMuiTheme({ overrides: { MUIDataTableToolbar: { root: { display: 'none' } } } })

const ThemeMerchDialog = styled(FusePageSimple)(({ theme }) => ({
    '& .MUIDataTableToolbar-root': {
        display: "none"
    },
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));
const formatDateIso = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'MMMM d, yyyy');
};
const formatDateIsoWithTime = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'MMMM d, yyyy - hh:mm a');
};
function formatDate(timestamp) {
    // Convert Unix timestamp to milliseconds by multiplying by 1000
    const date = new Date(timestamp * 1000);

    // Format the date using toLocaleDateString()
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return formattedDate;
}

const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        margin: "auto",
        maxWidth: "800px",
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    qrCode: {
        margin: theme.spacing(2),
    },
    input: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),

    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

const container = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};

const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
};


const data = [
    ["DUKPT", "5", "1", true],
    ["JWE", "6", "2", true],
    ["JWS", "7", "1", true],
    ["ENCRYPT", "3", "3", false],
];

const removeSchema = yup.object().shape({
    understandTick: yup.bool().oneOf([true], 'Field must be checked')
});
const defaultValues2 = {
    understandTick: false,
};

const editSchema = yup.object().shape({
    userName: yup.string().required('').matches(
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        "Invalid website URL"
    ),
    email: yup.string().required('')
});

const LicenseFilter = ({ filterList, onFilterChange }) => {
    const handleFilterChange = (value) => {
        onFilterChange(value, 0); // Assuming the license status filter is the first and only filter for this column
    };

    return (
        <select value={filterList[0]} onChange={(e) => handleFilterChange(e.target.value)}>
            <option value="">All</option>
            <option value="valid">Valid</option>
            <option value="invalid">Invalid</option>
        </select>
    );
};


function Devices(props) {
    const { t } = useTranslation("navigation");
    const [columns2, setColumns2] = React.useState([]);

    const { control: control2, formState: formState2, handleSubmit: handleSubmit2, setError: setError2, setValue: setValue2 } = useForm({
        mode: 'onChange',
        defaultValues2,
        resolver: yupResolver(removeSchema),
    });
    const { isValid: isValid2, dirtyFields: dirtyFields2, errors: errors2 } = formState2;

    const dispatch = useDispatch();
    const [plugins, setPlugins] = React.useState([]);
    const [settings, setSettings] = React.useState([]);

    const [isAdmin, setIsAdmin] = React.useState(false);

    const [packagenames, setPackagenames] = React.useState([]);

    function isLicenseValid(packageName, data) {

        const currentDate = new Date();
        console.log(data, " aloo")
        for (const item of data) {
            if (item.packageName === packageName) {
                const licenseExpireDate = new Date(item.licenseExpireDate);
                if (licenseExpireDate > currentDate) {
                    return true; // License is valid
                } else {
                    return false; // License has expired
                }
            }
        }

        return false; // Package name not found in the list
    }

    const [selectedDeviceAuditsColumns, setSelectedDeviceAuditsColumns] = React.useState([
        {
            name: "createdTime",
            label: "Time",
            options: {
                filter: true,
                sort: true,

                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{formatDateIsoWithTime(value)}</div></motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: "event",
            label: "Event",
            options: {
                filter: true,
                sort: true,

                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: "packageName",
            label: "PackageName",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                        <div style={{ color: "#4d758c", fontWeight: "normal", whiteSpace: "nowrap" }}>{value}</div>
                    </motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: "osVersion",
            label: "OsVersion",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: "sdkVersion",
            label: "SdkVersion",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: "mobileSdkVersion",
            label: "WhiteShieldSdkVersion",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
    ]);
    useEffect(() => {

        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_PACKAGES).then((response) => {
            if (response.status == 200) {
                setPackagenames(response.data)
                let packganameslocal = response.data
                setColumns2([
                    {
                        name: "DeviceId",
                        label: "DeviceId",
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div style={{ cursor: "pointer" }} initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ cursor: "pointer" }}> {value} </div></motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                            ),
                            display: true
                        }
                    },
                    {
                        name: "OsName",
                        label: "OsName",
                        options: {
                            display: 'excluded', // This will hide the column
                        },
                    },
                    {
                        name: "PackageName",
                        label: "PackageName",
                        options: {
                            filter: true,
                            sort: false,

                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                                    <AndroidIcon style={{ display: tableMeta.rowData[1] == "Android" ? "" : "none", float: "left", color: "#4d758c", opacity: ".6", marginRight: 5, marginTop: "-3px" }}></AndroidIcon>
                                    <AppleIcon style={{ display: tableMeta.rowData[1] == "Ios" ? "" : "none", float: "left", color: "#4d758c", opacity: ".6", marginRight: 5, marginTop: "-3px" }}></AppleIcon>
                                    <div style={{ color: "#4d758c", fontWeight: "normal", whiteSpace: "nowrap" }}>{value}</div>
                                </motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                            )
                        }
                    },
                    {
                        name: "OsVersion",
                        label: "OsVersion",
                        options: {
                            filter: true,
                            filterType: 'multiselect',
                            sort: true,

                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                            )
                        }
                    },
                    {
                        name: "MobileSdkVersion",
                        label: "WhiteShieldSdkVersion",
                        options: {
                            filter: true,
                            sort: true,

                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                            )
                        }
                    }, {
                        name: "ThreatInfo",
                        label: "License",
                        options: {
                            filter: false,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div style={{ cursor: "pointer" }} initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1, }}><div style={{ cursor: "pointer" }}>
                                        <FuseSvgIcon className={isLicenseValid(tableMeta.rowData[2], packganameslocal) ? "text-green" : "text-red"} size={25}>
                                            {isLicenseValid(tableMeta.rowData[2], packganameslocal) ? "heroicons-outline:check-circle" : "heroicons-outline:minus-circle"}
                                        </FuseSvgIcon>
                                    </div></motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                            )
                        }
                    }, {
                        name: "CreatedTime",
                        label: "RegisteredTime",
                        options: {
                            filter: true,
                            sort: true,

                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><div style={{ color: "#4d758c", fontWeight: "normal" }}>{value}</div></motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                            )
                        }
                    }, {
                        name: 'ThreatInfo',
                        label: 'Threats',
                        options: {
                            filter: true,
                            display: 'true',
                            sort: false,
                            filterType: 'custom',
                            filterOptions: {
                                logic: (value, filterValue) => {
                                    if (filterValue.length === 0) {
                                        return false; // Show all rows if no filter applied
                                    }
                                    const parsedData = JSON.parse(JSON.parse(value));

                                    return filterValue.every((threatType) => {
                                        if (threatType === 'MalwareDetected') {
                                            return !parsedData[threatType].length > 0;
                                        }
                                        return !parsedData[threatType];
                                    });
                                },

                                display: (filterList, onChange, index, column) => {
                                    const optionValues = ['Hooked', 'Rooted', 'RunningInEmulator', 'DebuggerAttached', 'ScreenMirror', 'IsRunningInVirtualEnvironment', 'MalwareDetected'];
                                    console.log(filterList)
                                    const threatFilters = {
                                        Hooked: false,
                                        Rooted: false,
                                        RunningInEmulator: false,
                                        DebuggerAttached: false,
                                        ScreenMirror: false,
                                        IsRunningInVirtualEnvironment: false,
                                        MalwareDetected: false,
                                    };

                                    const labelMappings = {
                                        Hooked: 'Hook',
                                        Rooted: 'Root',
                                        RunningInEmulator: 'Emulator',
                                        DebuggerAttached: 'Debug',
                                        ScreenMirror: 'ScreenMirror',
                                        IsRunningInVirtualEnvironment: 'Virtual',
                                        MalwareDetected: 'Malware',
                                    };

                                    const handleFilterChange = (threatType) => {
                                        threatFilters[threatType] = !threatFilters[threatType];
                                        const selectedFilters = Object.keys(threatFilters).filter(key => threatFilters[key]);
                                        onChange(selectedFilters, index, column);
                                    };

                                    return (
                                        <FormControl>
                                            <InputLabel id='select-multiple-chip' style={{ marginLeft: "-11px" }}>Threats</InputLabel>
                                            <Select
                                                labelId="select-multiple-chip"
                                                label="Threats"
                                                id="demo-simple-select"
                                                multiple
                                                variant='standard'
                                                value={filterList[index]}
                                                renderValue={selected => selected.join(', ')}
                                                onChange={event => {
                                                    filterList[index] = event.target.value;
                                                    onChange(filterList[index], index, column);
                                                }}
                                            >
                                                {optionValues.map(item => (
                                                    <MenuItem key={item} value={item}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={filterList[index].indexOf(item) > -1}
                                                        />
                                                        <ListItemText primary={labelMappings[item]} />
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl >


                                    );
                                }


                            },
                            customBodyRender: (value, tableMeta, updateValue) => (
                                <motion.div style={{ cursor: 'pointer' }} initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
                                    <div style={{ cursor: 'pointer' }}>
                                        <ThreatColumn hasThreats={value}></ThreatColumn>
                                    </div>
                                </motion.div>
                            ),
                            customHeadLabelRender: (columnMeta) => (
                                <div style={{ color: '#4d758c', fontWeight: 'normal' }}>{columnMeta.label}</div>
                            ),
                        },
                    },
                ])
            } else {

            }
        }).catch(() => {

        }).finally(() => {

        });

        setIsAdmin(localStorage.getItem("profileUserRole") === "Admin")

        fetchData(1, 100)


        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_SETTINGS).then((response) => {
            if (response.status == 200) {
                console.log(response.data)
                setSettings(response.data)

            } else {

            }
        }).finally(() => {

        });
    }, []);
    const classes = useStyles();
    const [updateLoading, setUpdateLoading] = React.useState(false);
    const { control, formState, handleSubmit, setError, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(editSchema),
    });
    function updateUser({ userName, email }) {
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))
        setUpdateLoading(true)

        CustomAxios.POST(process.env.REACT_APP_WEB_API_ADD_MERCHANTS, { merchants: [{ merchantUrl: userName, merchantCode: email }] }).then((response) => {
            if (response.status == 200) {
                console.log(response.data)
                setPlugins(response.data)
                dispatch(
                    showMessage({
                        message: t('Success'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'success'//success error info warning null
                    }))
            } else {

            }

        }).catch(() => {
            dispatch(
                showMessage({
                    message: t('MccNotSupported'),//text or html MccNotSupported
                    autoHideDuration: 3000,//ms
                    anchorOrigin: {
                        vertical: 'top',//top bottom
                        horizontal: 'center'//left center right
                    },
                    variant: 'warning'//success error info warning null
                }))
        }).finally(() => {
            setUpdateLoading(false)
        });



    }

    const [threshold, setThreshold] = React.useState(settings.warningThreshold);
    const [analyzeInterval, setAnalyzeInterval] = React.useState(settings.autoAnalyzeInterval);
    const [thresholdDisabled, setThresholdDisabled] = React.useState(true);
    const [analyzeIntervalDisabled, setAnalyzeIntervalDisabled] = React.useState(true);

    const onThresholdChange = (e) => {
        setThresholdDisabled(false)
        setThreshold(e.target.value)
    }

    const onAnalyzeIntervalChange = (e) => {
        setAnalyzeIntervalDisabled(false)
        setAnalyzeInterval(e.target.value)
    }


    function finishUpload() {
        dispatch(
            showMessage({
                message: t('Success'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'success'//success error info warning null
            }))
        setUpdateLoading(false)
    }
    function finishUpload2(e) {
        dispatch(
            showMessage({
                message: t('MerchSucc') + e + t('MerchsAdded'),//text or html
                autoHideDuration: 4000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'success'//success error info warning null
            }))
        setUpdateLoading(false)
    }

    const { isValid, dirtyFields, errors } = formState;
    function getLoadPlugin(key, status) {
        switch (key) {
            case 1:
                return setLoadingPlugin(status);
            case 2:
                return setLoadingPlugin2(status);
            case 3:
                return setLoadingPlugin3(status);
            case 4:
                return setLoadingPlugin4(status);
            case 5:
                return setLoadingPlugin5(status);
            case 6:
                return setLoadingPlugin6(status);
            case 7:
                return setLoadingPlugin7(status);
            case 8:
                return setLoadingPlugin8(status);
            case 9:
                return setLoadingPlugin9(status);
            case 10:
                return setLoadingPlugin10(status);
            default:
                return null;
        }
    }

    function removePlugin(pluginName, key) {
        getLoadPlugin(key, true);
        CustomAxios.POST(process.env.REACT_APP_WEB_API_DISABLE_PLUGINS,
            { ProfileId: localStorage.getItem('profileId'), ClientId: localStorage.getItem('clientId'), PluginName: pluginName }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    setPlugins(response.data)
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {
                getLoadPlugin(key, false);
            });
    };

    function addPlugin(pluginName, key) {
        getLoadPlugin(key, true);
        CustomAxios.POST(process.env.REACT_APP_WEB_API_ENABLE_PLUGINS,
            { ProfileId: localStorage.getItem('profileId'), ClientId: localStorage.getItem('clientId'), PluginName: pluginName }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    setPlugins(response.data)
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {
                getLoadPlugin(key, false);
            });
    };
    const [openLoading, setOpenLoading] = React.useState(false);
    const [openLoadingBackDrop, setOpenLoadingBackDrop] = React.useState(false)

    const [selectedMerchant, setSelectedMerchant] = React.useState(false);

    const [merchantHistory, setMerchantHistory] = React.useState([]);
    const [merchantHistoryColumns, setMerchantHistoryColumns] = React.useState([]);


    const [selectedMerchantGraph, setSelectedMerchantGraph] = React.useState(false);

    const [selectedMalwareInfo, setSelectedMalwareInfo] = React.useState([]);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [selectedDeviceId, setSelectedDeviceId] = React.useState(null);
    function handleRowClick(rowData, rowMeta) {

        console.log(plugins[rowMeta.dataIndex].ThreatInfo)
        const threatInfo = JSON.parse(JSON.parse(plugins[rowMeta.dataIndex].ThreatInfo))
        CustomAxios.POST(process.env.REACT_APP_WEB_API_GET_USER_SIGNATURE_DETAIL, { signatures: threatInfo?.MalwareDetected }).then((response) => {
            if (response.status == 200) {
                setSelectedMalwareInfo(response.data)
            }
        })

        function parseFormattedDate(formattedDate) {

            try {
                const dateObject = new Date(formattedDate);

                if (!isNaN(dateObject)) {
                    return dateObject
                }
            } catch (error) {
                console.log(error)
            }
            return null;
        }

        const deviceId = plugins[rowMeta.dataIndex].DeviceId
        setSelectedDeviceId(deviceId)
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_USER_DEVICE_HISTORY + "?deviceId=" + deviceId + "&page=1&perPage=100").then((response) => {
            if (response.status == 200) {
                setMerchantHistory(response.data.data)
                console.log(response.data)
                setMerchantHistoryColumns([{
                    name: "CreatedTime",
                    label: t('Date'),
                    options: {
                        filter: true,
                        sort: true,
                        filterType: 'custom', // Use custom filter type
                        filterOptions: {
                            names: ['Start Date', 'End Date'],
                            logic(value, filters) {
                                try {
                                    const start = filters[0];
                                    const end = filters[1];

                                    const dateValue = new Date(value);
                                    const startValue = new Date(start);
                                    const endValue = new Date(end);

                                    if (start !== undefined || end !== undefined) {
                                        const isDateInRange = dateValue >= startValue && dateValue <= endValue;
                                        return !isDateInRange;
                                    }
                                } catch (error) {

                                }

                                return false;
                            },
                            display: (filterList, onChange, index, column) => (
                                <div style={{ width: "300%" }}>
                                    {console.log(filterList)}
                                    <div style={{ margin: "10px", width: "300%" }} fullWidth>
                                        <DateTimePicker
                                            value={parseFormattedDate(filterList[0][0]) || null}
                                            onChange={(date) => {
                                                const newFilterList = [];
                                                const dateValue = new Date(date);

                                                // Format the date as "YYYY-MM-DD HH:mm:ss.SSS"
                                                const formattedDate = dateValue.toLocaleString('en-US', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                });

                                                try {
                                                    if (filterList[0][1] !== undefined) {
                                                        newFilterList[1] = filterList[0][1]
                                                    }
                                                } catch (error) {

                                                }

                                                newFilterList[0] = formattedDate;
                                                onChange(newFilterList, index, column);
                                            }}
                                            renderInput={(props) => <input {...props} />}
                                            label="Start Date"
                                            name="Start Date"
                                        />
                                    </div>
                                    <div style={{ margin: "10px", width: "300%" }} fullWidth>
                                        <DateTimePicker
                                            value={parseFormattedDate(filterList[0][1]) || null}
                                            onChange={(date) => {
                                                const newFilterList = [];
                                                const dateValue = new Date(date);

                                                // Format the date as "YYYY-MM-DD HH:mm:ss.SSS"
                                                const formattedDate = dateValue.toLocaleString('en-US', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                });

                                                try {
                                                    if (filterList[0][0] !== undefined) {
                                                        newFilterList[0] = filterList[0][0]
                                                    }
                                                } catch (error) {

                                                }

                                                newFilterList[1] = formattedDate;
                                                onChange(newFilterList, index, column);
                                            }}
                                            renderInput={(props) => <input {...props} />}
                                            label="End Date"
                                            name="End Date"
                                        />

                                    </div>


                                </div>
                            ),
                        },
                        customBodyRender: (value) => (
                            <motion.div
                                style={{}} // Your animation styles here
                                initial={{ y: 20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                            >
                                <div style={{}}>{format(new Date(value), 'hh:mm MM/dd/y')}</div>
                            </motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                {
                    name: "ThreatInfo",
                    label: "Hook",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).Hooked ? "text-red" : "text-green"} size={25}>
                                {(JSON.parse(JSON.parse(value))).Hooked ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                            </FuseSvgIcon></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                }, {
                    name: "ThreatInfo",
                    label: "Root",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).Rooted ? "text-red" : "text-green"} size={25}>
                                {(JSON.parse(JSON.parse(value))).Rooted ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                            </FuseSvgIcon></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                {
                    name: "ThreatInfo",
                    label: "Emulator",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).RunningInEmulator ? "text-red" : "text-green"} size={25}>
                                {(JSON.parse(JSON.parse(value))).RunningInEmulator ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                            </FuseSvgIcon></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                {
                    name: "ThreatInfo",
                    label: "VirtualEnvironment",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).IsRunningInVirtualEnvironment ? "text-red" : "text-green"} size={25}>
                                {(JSON.parse(JSON.parse(value))).IsRunningInVirtualEnvironment ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                            </FuseSvgIcon></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                {
                    name: "ThreatInfo",
                    label: "Debug",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).DebuggerAttached ? "text-red" : "text-green"} size={25}>
                                {(JSON.parse(JSON.parse(value))).DebuggerAttached ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                            </FuseSvgIcon></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                {
                    name: "ThreatInfo",
                    label: "ScreenMirror",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (
                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).ScreenMirror ? "text-red" : "text-green"} size={25}>
                                {(JSON.parse(JSON.parse(value))).ScreenMirror ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                            </FuseSvgIcon></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                {
                    name: "ThreatInfo",
                    label: "Malware",
                    options: {
                        filter: false,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => (

                            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}><Tooltip title={(JSON.parse(JSON.parse(value))).MalwareDetected.length > 0 ?
                                <ul>
                                    {(JSON.parse(JSON.parse(value))).MalwareDetected.map((malware, index) => (
                                        <li key={index}>{malware}</li>
                                    ))}
                                </ul>
                                : <></>}><FuseSvgIcon className={(JSON.parse(JSON.parse(value))).MalwareDetected.length > 0 ? "text-red" : "text-green"} size={25}>
                                    {(JSON.parse(JSON.parse(value))).MalwareDetected.length > 0 ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                </FuseSvgIcon></Tooltip></motion.div>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                        )
                    }
                },
                ])
            }
        })
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_DEVICE_AUDIT_LOGS + "?deviceId=" + deviceId).then((response) => {
            if (response.status == 200) {
                setSelectedDeviceAudits(response.data)
            }
        })

        setSelectedMerchant(plugins[rowMeta.dataIndex])
        setSelectedMerchantThreatInfo(threatInfo)
        setTabValue(2)

    }
    const [selectedDeviceAudits, setSelectedDeviceAudits] = React.useState([]);
    const [selectedMerchantThreatInfo, setSelectedMerchantThreatInfo] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const [tabValue2, setTabValue2] = useState(0);
    const [addGroup, setAddGroup] = useState(false);
    const [values, setValues] = React.useState([]);

    const handleChangeText = (e) => {
        setText(e.target.value);
    };
    const addValue = () => {
        setValues([...values, ""]);
    };
    const handleValueChange = (index, e) => {
        const updatedValues = values.map((value, i) => {
            if (i === index) {
                return e.target.value;
            } else {
                return value;
            }
        });
        setValues(updatedValues);
    };
    const [isSelectedCustom, setIsSelectedCustom] = React.useState(false);
    const deleteValue = (i) => {
        console.log(i)
        console.log(values)
        let v = []
        values.forEach((element, index) => {
            if (i !== index)
                v.push(element)
        });
        console.log(v)
        setValues(v);
    };
    const [loadingPlugin, setLoadingPlugin] = React.useState(false);
    const [loadingPlugin2, setLoadingPlugin2] = React.useState(false);
    const [loadingPlugin3, setLoadingPlugin3] = React.useState(false);
    const [loadingPlugin4, setLoadingPlugin4] = React.useState(false);
    const [loadingPlugin5, setLoadingPlugin5] = React.useState(false);
    const [loadingPlugin6, setLoadingPlugin6] = React.useState(false);
    const [loadingPlugin7, setLoadingPlugin7] = React.useState(false);
    const [loadingPlugin8, setLoadingPlugin8] = React.useState(false);
    const [loadingPlugin9, setLoadingPlugin9] = React.useState(false);
    const [loadingPlugin10, setLoadingPlugin10] = React.useState(false);

    function handleClick(item) {
        console.log(item)
        props.navigate(`/logs/1`);
    }
    function handleChangeTab(event, value) {
        setTabValue(value);
    }
    const [selectedDeviceIndexMenu, setSelectedDeviceIndexMenu] = useState([]);
    const [resetSelectedDatasTable, setResetSelectedDatasTable] = useState([]);

    const [editMerchUrlId, setEditMerchUrlId] = useState('');
    const [isSelectedCustomEdit, setIsSelectedCustomEdit] = useState(false);
    const [editMerchUrl, setEditMerchUrl] = useState('');
    const [editMerchCode, setEditMerchCode] = useState('');
    function onRowSelectionChangeCustom(rowsSelected) {
        setResetSelectedDatasTable(rowsSelected)
        setIsSelectedCustom(rowsSelected.length == 0 ? false : true);
        setIsSelectedCustomEdit(rowsSelected.length == 1 ? false : true);
        setSelectedDeviceIndexMenu(rowsSelected)
        if (rowsSelected.length === 1) {
            // setEditMerchUrlId(plugins[rowsSelected[0]].id)
            // setEditMerchUrl(plugins[rowsSelected[0]].merchantUrl)
            // setEditMerchCode(plugins[rowsSelected[0]].merchantCategoryCode.mccCode)
        }
        console.log(rowsSelected)

    }

    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalItems, setTotalItems] = useState(0);

    const fetchData = async (page, rowsPerPage) => {
        setRowsPerPage(rowsPerPage)
        setOpenLoading(true)
        let plugins = CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_USER_MERCHANTS + "?page=" + page + "&perPage=" + rowsPerPage).then((response) => {
            if (response.status == 200) {
                console.log(response.data)
                setPlugins(response.data.data)
                setTotalItems(response.data.totalCount)
            } else {

            }
        }).finally(() => {
            setOpenLoading(false)
        });
    };

    const optionsTest = {
        filterOptions: {
            fullWidth: true
        },
        serverSide: false,
        count: totalItems,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [100, 500, 1000, 10000],
        onChangePage: (currentPage) => fetchData(currentPage + 1, rowsPerPage),
        onChangeRowsPerPage: (rowsPerPage) => fetchData(1, rowsPerPage),

        download: false, print: false,
        selectableRows: false,
        filterType: "multiselect",
        onRowClick: handleRowClick,
        selectToolbarPlacement: "none",
        rowsSelected: resetSelectedDatasTable,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => (
            onRowSelectionChangeCustom(rowsSelected)
        ),
        textLabels: {
            body: {
                noMatch:
                    <motion.div
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="logo">
                            <Typography className="font-medium tracking-tight" color="text.secondary">{t('LNo1')}</Typography>
                        </div>
                    </motion.div>
            },
        },
    }


    const [rowsPerPageHistory, setRowsPerPageHistory] = useState(100);
    const [totalItemsHistory, setTotalItemsHistory] = useState(0);

    const fetchDataHistory = async (page, rowsPerPage) => {
        setRowsPerPageHistory(rowsPerPage)
        setOpenLoading(true)
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_USER_DEVICE_HISTORY + "?deviceId=" + selectedDeviceId + "&page=" + page + "&perPage=" + rowsPerPage).then((response) => {
            if (response.status == 200) {
                setMerchantHistory(response.data.data)
                setTotalItemsHistory(response.data.totalCount)
            }
        })
            .finally(() => {
                setOpenLoading(false)
            });
    };

    const optionsTestHistory = {
        serverSide: false,
        count: totalItemsHistory,
        rowsPerPage: rowsPerPageHistory,
        rowsPerPageOptions: [100, 500, 1000, 10000],
        onChangePage: (currentPage) => fetchDataHistory(currentPage + 1, rowsPerPageHistory),
        onChangeRowsPerPage: (rowsPerPage) => fetchDataHistory(1, rowsPerPage),

        filter: true,
        download: false,
        selectableRows: false,
        selectToolbarPlacement: "none",
        textLabels: {
            body: {
                noMatch:
                    <motion.div
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="logo">
                            <Typography className="font-medium tracking-tight" color="text.secondary">{t('LNo3')}</Typography>
                        </div>
                    </motion.div>
            },
        },
    }



    const BodyComponent = React.useMemo(
        () => (props) => (
            <LoadingTableBody loading={openLoading} {...props} />
        ),
        [openLoading]
    );
    function removeDevice({ understandTick }) {

        setOpenDelete(false)
        setOpenDelete2(false)

        console.log(selectedMerchant)
        console.log(selectedMerchantGraph)
        let devicesList = []
        if (selectedMerchantGraph) {
            devicesList.push(selectedMerchant.id)
        } else {
            selectedDeviceIndexMenu.forEach(element => {
                devicesList.push(plugins[element].id)
            });
        }

        CustomAxios.POST(process.env.REACT_APP_WEB_API_REMOVE_MERCHANT,
            { Id: devicesList }).then((response) => {
                if (response.status == 200) {
                    setPlugins(response.data)
                    setTabValue(0)
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                } else {
                    dispatch(
                        showMessage({
                            message: t('Success2'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                }
            })
            .catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            })
            .finally(() => {
                setResetSelectedDatasTable([])
                setIsSelectedCustom(false)
            });
    }

    function manuelScan() {
        dispatch(
            showMessage({
                message: t('ScanStarted'),//text or html ScanStarted
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))

        let devicesList = []
        selectedDeviceIndexMenu.forEach(element => {
            devicesList.push(plugins[element].id)
        });

        CustomAxios.POST(process.env.REACT_APP_WEB_API_MANUEL_SCAN,
            { Id: devicesList }).then((response) => {
                if (response.status == 200) {
                    setPlugins(response.data)

                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                } else {
                    dispatch(
                        showMessage({
                            message: t('Success2'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                }
            })
            .catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            })
            .finally(() => {

            });
        setResetSelectedDatasTable([])
        setIsSelectedCustom(false)
    }

    const [updateEditLoading, setUpdateEditLoading] = React.useState(false);
    function updatealarmGroupMerchant() {
        setUpdateEditLoading(true)
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_MERCHANT, { merchantId: editMerchUrlId, merchantUrl: editMerchUrl, merchantCode: editMerchCode }).then((response) => {
            if (response.status == 200) {
                dispatch(
                    showMessage({
                        message: t('Success'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'success'//success error info warning null
                    }))
                console.log(response.data)
                setPlugins(response.data)
            } else {

            }
        }).finally(() => {
            setUpdateEditLoading(false)
            setResetSelectedDatasTable([])
            setIsSelectedCustom(false)
            setOpen3(false)
        });
    }

    const [open3, setOpen3] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openDelete2, setOpenDelete2] = React.useState(false);
    const [isActivateEnabled, setActivateEnabled] = React.useState(false);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0);

    function handleDeviceState() {
        setTabValue(0)
        setOpenLoading(true)
        let plugins = CustomAxios.POST(process.env.REACT_APP_WEB_API_GET_ALL_PLUGINS,
            { ProfileId: localStorage.getItem('profileId'), ClientId: localStorage.getItem('clientId') }).then((response) => {
                if (response.status == 200) {
                    setPlugins(response.data)
                } else {

                }
            }).finally(() => {
                setOpenLoading(false)
            });
    }
    function handleDeviceState2() {
        setTabValue(1)
    }

    const [excelMerchantDatas, setExcelMerchantDatas] = React.useState([]);
    function handleFileChange(e) {
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const dataArray = rows.map(row => ({ columnA: row[0], columnB: row[1] }));

            console.log(dataArray)
            setExcelMerchantDatas(dataArray)

        };
        reader.onloadend = (event) => {
            setTimeout(() => setOpen(true), 200);
        };
        reader.readAsBinaryString(file);
    }
    function setPluginInfoPage(pluginName) {
        let isPluginExists = false
        plugins.forEach((element, index) => {
            if (element.pluginName === pluginName) {
                setSelectedDeviceIndex(index)
                isPluginExists = true
            }
        })
        setTabValue(3)
        // if (isPluginExists) {
        //     setTabValue(3)
        // }
        // else {
        //     dispatch(
        //         showMessage({
        //             message: 'Enable Plugin To See Content',//text or html
        //             autoHideDuration: 3000,//ms
        //             anchorOrigin: {
        //                 vertical: 'top',//top bottom
        //                 horizontal: 'center'//left center right
        //             },
        //             variant: 'info'//success error info warning null
        //         }))
        // }
    }
    const MyTableRow = ({ rowData, ...restProps }) => {
        const isActive = rowData[3];

        return (
            <TableRow
                style={{
                    backgroundColor: isActive ? "white" : "#f2f2f2",
                    pointerEvents: isActive ? "auto" : "none",
                    // add other styles as needed
                }}
                {...restProps}
            />
        );
    };
    const columnss = ["Date", "Endpoint", "MCC", "Ratio", "Status"]

    const [rowss, setRowss] = React.useState(JSON.parse(localStorage.getItem('myMCCArray')));

    const [uploadMultipleMerchantLoad, setUploadMultipleMerchantLoad] = React.useState(false);
    function uploadMultipleMerchants() {
        setUploadMultipleMerchantLoad(true)



        let merchants = []
        excelMerchantDatas.forEach(element => {
            merchants.push({ merchantUrl: element.columnA, merchantCode: element.columnB })
        });

        CustomAxios.POST(process.env.REACT_APP_WEB_API_ADD_MERCHANTS,
            { merchants: merchants }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    setPlugins(response.data)
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {
                fileInputRef = null;
                setUploadMultipleMerchantLoad(false)
                setOpen(false);
            });

    }
    let fileInputRef = React.useRef(null);

    const [updateLoadingThreshold, setUpdateLoadingThreshold] = React.useState(false);
    const [updateLoadingInterval, setUpdateLoadingInterval] = React.useState(false);

    function updateThreshold() {
        setUpdateLoadingThreshold(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_THRESHOLD,
            { threshold: threshold }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))


                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {
                setUpdateLoadingThreshold(false)
            });
    }

    function handleTabChange(event, value) {
        setTabValue2(value);
    }

    function updateInterval() {
        setUpdateLoadingInterval(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_INTERVAL,
            { interval: analyzeInterval }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))

                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {
                setUpdateLoadingInterval(false)
            });
    }
    function getDomainFromUrl(urlString) {
        if (!urlString || typeof urlString !== "string") {
            return "NA"; // Return "NA" if the URL string is invalid or empty
        }

        const url = new URL(urlString);
        const domain = url.hostname.startsWith("www.")
            ? url.hostname.substring(4)
            : url.hostname;

        return domain;
    }

    return (
        <Root
            header={<div />}
            content={
                <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingBackDrop}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
                        <DialogTitle >Upload Multiple Merchants</DialogTitle>
                        <DialogContent>
                            <div className="justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
                                <tr fullWidth>

                                    <Typography fullWidth variant="subtitle2">Verify that the excel merchants matches below the table and press OK.</Typography>
                                </tr>
                                <tr>

                                    <MuiDataTable

                                        columns={[{
                                            name: "columnA",
                                            label: "Merchant",
                                            options: {
                                                filter: false,
                                                sort: false,
                                            }
                                        }, {
                                            name: "columnB",
                                            label: "MCC",
                                            options: {
                                                filter: false,
                                                sort: false,
                                            }
                                        }]}
                                        data={excelMerchantDatas}
                                        options={{ download: false, filter: false, print: false, customToolbar: () => <></>, selectableRows: false, selectToolbarPlacement: "none", toolbar: "false" }}
                                    ></MuiDataTable>
                                </tr>

                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button color='primary' variant='outlined' onClick={() => setOpen(false)}>Cancel</Button>
                            <LoadingButton loading={uploadMultipleMerchantLoad} color='primary' variant='contained' onClick={() => uploadMultipleMerchants()}>OK</LoadingButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={handleClose2} open={open2} fullWidth>
                        <DialogTitle>Configure Client Certificate</DialogTitle>
                        <DialogContent>
                            <div className="justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
                                <table style={{ width: "100%" }}>
                                    <tr>

                                        <Button color='secondary' variant='outlined'>Upload Client Certificate</Button>
                                    </tr>
                                    <tr>
                                        <TextareaAutosize minRows={4} style={{ marginTop: 30, width: "100%", border: "1px solid black" }}></TextareaAutosize>
                                    </tr>
                                    <tr>

                                        <Button style={{ marginTop: 30 }} color='secondary' variant='outlined'>Upload Private Key</Button>
                                    </tr>

                                    <tr>
                                        <TextareaAutosize minRows={4} style={{ marginTop: 30, width: "100%", border: "1px solid black" }}></TextareaAutosize>
                                    </tr>
                                </table>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button color='primary' variant='outlined'>Cancel</Button>
                            <Button color='primary' variant='contained'>OK</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={() => setOpenDelete(false)} open={openDelete} PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}>
                        <form
                            validateOnChange={false}
                            validateOnBlur={false}
                            name="loginForm"
                            noValidate
                            onSubmit={handleSubmit2(removeDevice)}
                        >
                            <DialogTitle >Remove Devices</DialogTitle>

                            <DialogContent className="grid place-items-center">

                                <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                                    <Typography>Are you sure you want to delete these devices?</Typography>
                                    <Controller
                                        name="understandTick"
                                        control={control2}
                                        render={({ field }) => (
                                            <FormControl
                                                {...field}
                                                error={!!errors2.understandTick}
                                                helperText={errors2?.understandTick?.message}
                                                variant="standard"
                                                fullWidth
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox name="understandTick" checked={field.value} />
                                                    }
                                                    label="All datas will be deleted associated with these devices."
                                                />
                                            </FormControl>
                                        )}
                                    />

                                </div>

                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button color='primary' variant='outlined' onClick={() => setOpenDelete(false)}>Cancel</Button>
                                <Button disabled={_.isEmpty(dirtyFields2) || !isValid2} type='submit' color='error' variant='contained'
                                    startIcon={<FuseSvgIcon className="hidden sm:flex">heroicons-outline:trash</FuseSvgIcon>}
                                >Remove</Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog onClose={() => setOpenDelete2(false)} open={openDelete2} PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}>
                        <form
                            validateOnChange={false}
                            validateOnBlur={false}
                            name="loginForm"
                            noValidate
                            onSubmit={handleSubmit2(removeDevice)}
                        >
                            <DialogTitle >Remove Device</DialogTitle>

                            <DialogContent className="grid place-items-center">

                                <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                                    <Typography>Are you sure you want to delete this device?</Typography>
                                    <Controller
                                        name="understandTick"
                                        control={control2}
                                        render={({ field }) => (
                                            <FormControl
                                                {...field}
                                                error={!!errors2.understandTick}
                                                helperText={errors2?.understandTick?.message}
                                                variant="standard"
                                                fullWidth
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox name="understandTick" checked={field.value} />
                                                    }
                                                    label="All datas will be deleted associated with this device."
                                                />
                                            </FormControl>
                                        )}
                                    />

                                </div>

                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button color='primary' variant='outlined' onClick={() => setOpenDelete2(false)}>Cancel</Button>
                                <Button disabled={_.isEmpty(dirtyFields2) || !isValid2} type='submit' color='error' variant='contained'
                                    startIcon={<FuseSvgIcon className="hidden sm:flex">heroicons-outline:trash</FuseSvgIcon>}
                                >Remove</Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog md onClose={() => setOpen3(false)} open={open3} PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}>
                        <DialogTitle >Block Device</DialogTitle>

                        <DialogContent style={{ width: "600px" }} className="grid place-items-center">
                            <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                                <Typography>Are you sure you want to block this device?</Typography>
                                <Controller
                                    name="understandTick"
                                    control={control2}
                                    render={({ field }) => (
                                        <FormControl
                                            {...field}
                                            error={!!errors2.understandTick}
                                            helperText={errors2?.understandTick?.message}
                                            variant="standard"
                                            fullWidth
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="understandTick" checked={field.value} />
                                                }
                                                label="Device will be blocked and wont be able to use sdk anymore."
                                            />
                                        </FormControl>
                                    )}
                                />

                            </div>



                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button color='primary' variant='outlined' onClick={() => setOpen3(false)}>{t('Cancel')}</Button>
                            <LoadingButton loading={updateEditLoading} color='primary' variant='contained'
                                onClick={() => updatealarmGroupMerchant()}
                            >{t('OK')}</LoadingButton>
                        </DialogActions>
                    </Dialog>
                    <>
                        {tabValue === 0 && <motion.div >
                            <motion.div
                                style={{ borderBottom: "1px solid #dce5f3" }}
                                className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                                initial={{ x: 20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                            >
                                <div className="flex flex-col flex-auto">
                                    <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                        {t('MERCHANTS')}
                                    </Typography>
                                    <Typography className="font-medium tracking-tight" color="text.secondary">
                                        {t('CustomizeMerchants')}
                                    </Typography>
                                </div>
                                <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
                                    {isAdmin && <motion.div
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                                    >

                                    </motion.div>
                                    }

                                </div>
                            </motion.div>

                            <motion.div
                                className="items-center justify-between py-32 px-24 md:px-32"
                                initial={{ x: 20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                            >
                                <Card >

                                    <MuiDataTable
                                        data={plugins}
                                        columns={columns2}
                                        options={optionsTest}
                                        components={{ TableBody: BodyComponent }}
                                    />
                                </Card>
                            </motion.div>


                        </motion.div>}
                        {tabValue === 1 && <motion.div
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>

                            <motion.div
                                style={{ borderBottom: "1px solid #dce5f3" }}
                                className="flex flex-col sm:flex-row space-y-0 sm:space-y-0 flex-1 w-full items-center justify-between py-12 px-24 md:px-32"
                                initial={{ x: 20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                            >
                                <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-0 sm:space-y-0 py-12 px-24 md:px-32">
                                    <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
                                        <motion.div
                                            initial={{ x: 20, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                                        >
                                            <Typography
                                                className="flex items-center sm:mb-12"
                                                onClick={() => setTabValue(0)}
                                                role="button"

                                                color="inherit"
                                            >
                                                <FuseSvgIcon size={20}>
                                                    {theme.direction === 'ltr'
                                                        ? 'heroicons-outline:arrow-sm-left'
                                                        : 'heroicons-outline:arrow-sm-right'}
                                                </FuseSvgIcon>
                                                <span className="flex mx-4 font-medium">{t('MERCHANTS')}</span>
                                            </Typography>
                                        </motion.div>

                                        <div className="flex items-center max-w-full">
                                            <motion.div
                                                className="hidden sm:flex"
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1, transition: { delay: 0.3 } }}
                                            >
                                                <div><FuseSvgIcon style={{ float: "left", color: "purple", marginRight: 5 }}>material-outline:store</FuseSvgIcon></div>
                                            </motion.div>
                                            <motion.div
                                                className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
                                                initial={{ x: -20 }}
                                                animate={{ x: 0, transition: { delay: 0.3 } }}
                                            >
                                                <Typography className="text-16 sm:text-20 truncate font-semibold">
                                                    {t('ManageMerchants')}
                                                </Typography>

                                            </motion.div>
                                        </div>
                                    </div>

                                </div>
                            </motion.div>
                            <motion.div
                                className="items-center justify-between py-32 px-24 md:px-32"
                                variants={container}
                                initial="hidden"
                                animate="show"
                            >

                                <Card style={{ paddingTop: "30px", paddingLeft: "40px", paddingRight: "20px", paddingBottom: "-20px" }} className='grid grid-cols-1 gap-4 p-24 m-24'>

                                    <div >
                                        <div style={{ maxWidth: "1000px" }}>
                                            <Grid item xs={12} sm={6}>

                                                <Typography variant="h5"> {t('AddSingleMerchant')}</Typography>
                                                <Typography variant="subtitle2">
                                                    {t('AddSDesc')}
                                                </Typography>
                                                <form
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    name="loginForm"
                                                    noValidate
                                                    onSubmit={handleSubmit(updateUser)}
                                                >
                                                    <Controller
                                                        name="userName"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                margin="normal"
                                                                label={t('Merchant')}
                                                                type="text"
                                                                error={!!errors.userName}
                                                                helperText={errors?.userName?.message}

                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <MCCCodesSelect {...field}></MCCCodesSelect>
                                                        )}
                                                    />


                                                    <LoadingButton type='submit' loading={updateLoading} disabled={_.isEmpty(dirtyFields) || !isValid} style={{ float: "right", marginTop: "10px" }} variant="outlined" color="secondary" endIcon={<SendIcon />}>{t('Save')}</LoadingButton>
                                                    <div style={{ paddingBottom: "90px" }}></div>
                                                </form>
                                            </Grid>

                                        </div>
                                    </div>
                                    <hr style={{ maxWidth: "1000px", marginTop: "20px", marginBottom: "20px" }}></hr>
                                    <div >
                                        <div style={{ maxWidth: "1000px" }}>
                                            <Grid item xs={12} sm={6} style={{ marginBottom: "40px" }}>

                                                <Typography variant="h5"> {t('AddMultipleMerchants')}</Typography>
                                                <Typography variant="subtitle2">
                                                    <div style={{ fontWeight: "500" }} dangerouslySetInnerHTML={{ __html: t('AddMDesc') }} />
                                                </Typography>

                                                <Button startIcon={<FuseSvgIcon>material-outline:cloud_upload</FuseSvgIcon>} style={{ marginTop: "15px" }} color='secondary' variant='outlined' component="label" onClick={() => (fileInputRef == null)}>
                                                    {t('UploadMerchs')}
                                                    <input hidden type="file" accept=".csv,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xml,.xlam,.xla,.xlw,.xlr" onChange={(e) => handleFileChange(e)} onClick={(e) => {
                                                        e.target.value = null; // Clear the file input value
                                                    }} />
                                                </Button>
                                            </Grid>

                                            <div style={{ paddingBottom: "40px" }}></div>
                                        </div>
                                    </div>
                                    <hr style={{ maxWidth: "1000px", marginBottom: "20px" }}></hr>
                                    <div >
                                        <div style={{ maxWidth: "1000px" }}>
                                            <Grid item xs={12} sm={6} style={{ marginBottom: "40px" }}>

                                                <Typography variant="h5">{t('NonEThreshold')}</Typography>
                                                <Typography variant="subtitle2">
                                                    <div style={{ fontWeight: "500" }} dangerouslySetInnerHTML={{ __html: t('NonEThDesc') }} />
                                                </Typography>
                                                <TextField
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 100, min: 0
                                                        }
                                                    }}
                                                    defaultValue={settings.warningThreshold}
                                                    onChange={onThresholdChange}
                                                    value={threshold}
                                                    margin="normal"
                                                    label={t('Threshold')}
                                                    type="number"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                            <LoadingButton loading={updateLoadingThreshold} disabled={thresholdDisabled} style={{ float: "right", marginTop: "10px" }} variant="outlined" color="secondary" endIcon={<SendIcon />} onClick={updateThreshold}>{t('Save')}</LoadingButton>

                                            <div style={{ paddingBottom: "40px" }}></div>
                                        </div>
                                    </div>
                                    <hr style={{ maxWidth: "1000px", marginTop: "20px", marginBottom: "20px" }}></hr>
                                    <div style={{ marginBottom: "-100px" }}>
                                        <div style={{ maxWidth: "1000px" }}>
                                            <Grid item xs={12} sm={6} style={{ marginBottom: "20px" }}>

                                                <Typography variant="h5"> {t('AutoAnalyze')}</Typography>
                                                <Typography variant="subtitle2" style={{ marginBottom: "15px" }}>
                                                    {t('AutoAnalyzeDesc')}
                                                </Typography>

                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label">{t('SelectInterval')}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        label="Chose Type"
                                                        defaultValue={settings.autoAnalyzeInterval}
                                                        onChange={onAnalyzeIntervalChange}
                                                        value={analyzeInterval}
                                                    >
                                                        <MenuItem value="-1">{t('None')}</MenuItem>
                                                        <MenuItem value="3600">{t('Hourly')}</MenuItem>
                                                        <MenuItem value="86400">{t('Daily')}</MenuItem>
                                                        <MenuItem value="604800">{t('Weekly')}</MenuItem>
                                                        <MenuItem value="2629746">{t('Monthly')}</MenuItem>
                                                    </Select>

                                                </FormControl>
                                                <LoadingButton loading={updateLoadingInterval} disabled={analyzeIntervalDisabled} style={{ float: "right", marginTop: "10px" }} variant="outlined" color="secondary" endIcon={<SendIcon />} onClick={updateInterval}>{t('Save')}</LoadingButton>
                                                <div style={{ paddingBottom: "70px" }}></div>
                                            </Grid>
                                            <div style={{ marginBottom: "80px" }}></div>
                                        </div>

                                    </div>


                                </Card>


                            </motion.div>

                        </motion.div>}
                        {tabValue === 2 && <motion.div
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>

                            <motion.div
                                style={{ borderBottom: "1px solid #dce5f3" }}
                                className="flex flex-col sm:flex-row space-y-0 sm:space-y-0 flex-1 w-full items-center justify-between py-12 px-24 md:px-32"
                                initial={{ x: 20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                            >
                                <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-0 sm:space-y-0 py-12 px-24 md:px-32">
                                    <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
                                        <motion.div
                                            initial={{ x: 20, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                                        >
                                            <Typography
                                                className="flex items-center sm:mb-12"
                                                onClick={() => { setTabValue(0); setTabValue2(0); setSelectedMerchantGraph(false); }}
                                                role="button"

                                                color="inherit"
                                            >
                                                <FuseSvgIcon size={20}>
                                                    {theme.direction === 'ltr'
                                                        ? 'heroicons-outline:arrow-sm-left'
                                                        : 'heroicons-outline:arrow-sm-right'}
                                                </FuseSvgIcon>
                                                <span className="flex mx-4 font-medium">{t('MERCHANTS')}</span>
                                            </Typography>
                                        </motion.div>

                                        <div className="flex items-center max-w-full">
                                            <motion.div
                                                className="hidden sm:flex"
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1, transition: { delay: 0.3 } }}
                                            >
                                                <div><FuseSvgIcon style={{ float: "left", color: "purple", marginRight: 5 }}>material-outline:store</FuseSvgIcon></div>
                                            </motion.div>
                                            <motion.div
                                                className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
                                                initial={{ x: -20 }}
                                                animate={{ x: 0, transition: { delay: 0.3 } }}
                                            >
                                                <Typography className="text-16 sm:text-20 truncate font-semibold">
                                                    Device Info
                                                </Typography>
                                                <Chip label={selectedMerchant.DeviceId} color="secondary"></Chip>
                                            </motion.div>

                                        </div>
                                    </div>
                                    <motion.div
                                        className="flex"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                    >

                                    </motion.div>

                                </div>
                            </motion.div>
                            <motion.div
                                className="items-center justify-between py-32 px-24 md:px-32"
                                variants={container}
                                initial="hidden"
                                animate="show"
                            >

                                <Card>
                                    <Tabs
                                        value={tabValue2}
                                        onChange={handleTabChange}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        variant="scrollable"
                                        scrollButtons="false"
                                        classes={{ root: 'w-full h-64 border-b-1' }}
                                    >
                                        <Tab className="h-64" label={t('TabsInfo')} />
                                        <Tab className="h-64" label={t('TabsHistory')} />
                                        <Tab className="h-64" label={t('Audit2')} />
                                    </Tabs>

                                    {tabValue2 == 0 &&
                                        <div className="p-16 sm:p-24 max-w-5xl w-full">
                                            <div>
                                                <div className="pb-48">
                                                    <div className="pb-16 flex items-center">
                                                        <FuseSvgIcon color="action">material-outline:app_settings_alt</FuseSvgIcon>
                                                        <Typography className="h2 mx-12 font-medium" color="text.secondary">
                                                            Device
                                                        </Typography>
                                                    </div>

                                                    <div className="mb-24">
                                                        <div className="table-responsive mb-48">
                                                            <table className="simple">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <Typography className="font-semibold">DeviceCodeName</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">PackageName</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">WhiteShieldSdkVersion</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">SdkVersion</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">OsVersion</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">OsVersionPatchReleaseDate</Typography>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Typography className="truncate">{selectedMerchant.DeviceCodeName}</Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="truncate">{selectedMerchant.PackageName}</Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="truncate">{selectedMerchant.MobileSdkVersion}</Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="truncate">{selectedMerchant.SdkVersion}</Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="truncate">{selectedMerchant.OsVersion}</Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="truncate">{formatDateIso(selectedMerchant.OsVersionPatchReleaseDate)}</Typography>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                            <div>
                                                <div className="pb-48">
                                                    <div className="pb-16 flex items-center">
                                                        <FuseSvgIcon color="action">material-outline:remove_moderator</FuseSvgIcon>
                                                        <Typography className="h2 mx-12 font-medium" color="text.secondary">
                                                            Threat Checks
                                                        </Typography>
                                                        <ThreatColumn hasThreats={JSON.stringify(JSON.stringify(selectedMerchantThreatInfo))}></ThreatColumn>
                                                    </div>

                                                    <div className="mb-24">
                                                        <div className="table-responsive mb-48">
                                                            <table className="simple">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <Typography className="font-semibold">Hook</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Root</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Emulator</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">VirtualEnvironment</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Debug</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">ScreenMirror</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Malware</Typography>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="flex items-center">

                                                                                <Typography className="truncate mx-8">
                                                                                    <FuseSvgIcon className={selectedMerchantThreatInfo?.Hooked ? "text-red" : "text-green"} size={25}>
                                                                                        {selectedMerchantThreatInfo?.Hooked ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                                    </FuseSvgIcon>
                                                                                </Typography>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="truncate"><FuseSvgIcon className={selectedMerchantThreatInfo?.Rooted ? "text-red" : "text-green"} size={25}>
                                                                                {selectedMerchantThreatInfo?.Rooted ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                            </FuseSvgIcon></Typography>
                                                                        </td>
                                                                        <td >
                                                                            <Typography className="truncate"><FuseSvgIcon className={selectedMerchantThreatInfo?.RunningInEmulator ? "text-red" : "text-green"} size={25}>
                                                                                {selectedMerchantThreatInfo?.RunningInEmulator ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                            </FuseSvgIcon></Typography>
                                                                        </td>


                                                                        <td>
                                                                            <span className="truncate"><FuseSvgIcon className={selectedMerchantThreatInfo?.IsRunningInVirtualEnvironment ? "text-red" : "text-green"} size={25}>
                                                                                {selectedMerchantThreatInfo?.IsRunningInVirtualEnvironment ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                            </FuseSvgIcon></span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="truncate"><FuseSvgIcon className={selectedMerchantThreatInfo?.DebuggerAttached ? "text-red" : "text-green"} size={25}>
                                                                                {selectedMerchantThreatInfo?.DebuggerAttached ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                            </FuseSvgIcon></span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="truncate"><FuseSvgIcon className={selectedMerchantThreatInfo?.ScreenMirror ? "text-red" : "text-green"} size={25}>
                                                                                {selectedMerchantThreatInfo?.ScreenMirror ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                            </FuseSvgIcon></span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="truncate"><FuseSvgIcon className={selectedMerchantThreatInfo?.MalwareDetected.length > 0 ? "text-red" : "text-green"} size={25}>
                                                                                {selectedMerchantThreatInfo?.MalwareDetected.length > 0 ? "material-outline:warning_amber" : "material-outline:gpp_good"}
                                                                            </FuseSvgIcon></span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                            {selectedMerchantThreatInfo?.MalwareDetected.length > 0 && <div>
                                                <div className="pb-48">
                                                    <div className="pb-16 flex items-center">
                                                        <FuseSvgIcon color="action">material-outline:coronavirus</FuseSvgIcon>
                                                        <Typography className="h2 mx-12 font-medium" color="text.secondary">
                                                            Malware
                                                        </Typography>

                                                    </div>

                                                    <div className="mb-24">
                                                        <div className="table-responsive mb-48">
                                                            <table className="simple">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <Typography className="font-semibold">PackageName</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Threat</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Extension</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Os</Typography>
                                                                        </th>
                                                                        <th>
                                                                            <Typography className="font-semibold">Date</Typography>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {selectedMalwareInfo.map((selectedMalwareInfo, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="flex items-center">
                                                                                    <Typography className="truncate mx-8">
                                                                                        {selectedMalwareInfo.package}
                                                                                    </Typography>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Typography className="truncate">{selectedMalwareInfo.threat}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography className="truncate">{selectedMalwareInfo.extension}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <Typography className="truncate">{selectedMalwareInfo.os}</Typography>
                                                                            </td>
                                                                            <td>
                                                                                <span className="truncate">{formatDate(selectedMalwareInfo.date)}</span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>}

                                        </div>
                                    }
                                    {tabValue2 == 1 && <div><MuiDataTable
                                        data={merchantHistory}
                                        columns={merchantHistoryColumns}
                                        options={optionsTestHistory}
                                        components={{ TableBody: BodyComponent }} /></div>}
                                    {tabValue2 == 2 && <div><MuiDataTable
                                        data={selectedDeviceAudits}
                                        columns={selectedDeviceAuditsColumns}
                                        options={optionsTestHistory}
                                        components={{ TableBody: BodyComponent }} /></div>}
                                </Card>


                            </motion.div>

                        </motion.div>}
                    </>
                </ div>
            }
        />



    );
}
export default withRouter(Devices);