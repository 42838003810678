import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';

function HomeHeader(props) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [selectedProject, setSelectedProject] = useState({
        id: 1,
        menuEl: null,
    });

    function handleChangeProject(id) {
        setSelectedProject({
            id,
            menuEl: null,
        });
    }

    function handleOpenProjectMenu(event) {
        setSelectedProject({
            id: selectedProject.id,
            menuEl: event.currentTarget,
        });
    }

    function handleCloseProjectMenu() {
        setSelectedProject({
            id: selectedProject.id,
            menuEl: null,
        });
    }

    return (
        <div className="flex flex-col w-full px-24 sm:px-32">


        </div>
    );
}

export default HomeHeader;
