import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import { getProjects, selectProjects } from './store/projectsSlice';
import NotificationPanelToggleButton from 'app/theme-layouts/shared-components/notificationPanel/NotificationPanelToggleButton';
import { toggleNotificationPanel } from 'app/theme-layouts/shared-components/notificationPanel/store/stateSlice';
import { useTranslation } from 'react-i18next';
import CustomAxios from "app/shared-components/CustomAxios";

function ProjectDashboardAppHeader(props) {
  const { t } = useTranslation("navigation");
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const user = useSelector(selectUser);

  const [selectedProject, setSelectedProject] = useState({
    id: 1,
    menuEl: null,
  });

  const [alerts, setAlerts] = useState(0);
  useEffect(() => {
    CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_PACKAGES).then((response) => {

      if (response.status == 200) {
        // Get the current time
        const currentTime = new Date();

        // Map over the packages and filter based on the expiration date
        const filteredPackages = response.data
          .filter(item => new Date(item.licenseExpireDate) < currentTime)
          .map((item, index) => ({
            id: (index + 1).toString(),
            icon: "heroicons-solid:exclamation",
            title: "License Expired",
            description: `<b>${item.packageName}</b> is expired.`,
            time: item.licenseExpireDate,
            read: false,
            link: "/licenses",
            useRouter: true,
          }));

        setAlerts(filteredPackages.length);
      } else {

      }
    }).catch(() => {

    }).finally(() => {

    });
  }, []);

  function handleChangeProject(id) {
    setSelectedProject({
      id,
      menuEl: null,
    });
  }

  function handleOpenProjectMenu(event) {
    setSelectedProject({
      id: selectedProject.id,
      menuEl: event.currentTarget,
    });
  }

  function handleCloseProjectMenu() {
    setSelectedProject({
      id: selectedProject.id,
      menuEl: null,
    });
  }
  function GetUserNameCustomLogic(value) {
    const prefix = "_+*_";
    if (value.startsWith(prefix)) {
      return value.substring(11);
    } else {
      return value;
    }
  }

  return (
    <div className="flex flex-col w-full px-24 sm:px-32">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-32 sm:my-48">
        <div className="flex flex-auto items-center min-w-0">

          <div className="flex flex-col min-w-0 mx-16">
            <Typography className="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">
              <p>{t('Welcome')}{`, ${GetUserNameCustomLogic(localStorage.getItem("profileUsername"))}!`}</p>
            </Typography>

            <div className="flex items-center">
              <FuseSvgIcon size={20} color="action">
                heroicons-solid:bell
              </FuseSvgIcon>
              <Typography className="mx-6 leading-6 truncate" color="text.secondary">
                {t('YouHave')} {alerts} {t('YouHave2')}
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
          <Button
            className="whitespace-nowrap"
            variant="contained"
            color="primary"
            startIcon={<FuseSvgIcon size={20}>heroicons-solid:bell</FuseSvgIcon>}
            onClick={(ev) => dispatch(toggleNotificationPanel())}
          >
            {t('Notification')}
          </Button>

        </div>
      </div>

    </div>
  );
}

export default ProjectDashboardAppHeader;
