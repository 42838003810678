import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { Chip } from '@mui/material';
import CustomAxios from "app/shared-components/CustomAxios";
import { format, parseISO } from 'date-fns';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);
  const [settings, setSettings] = useState([]);
  useEffect(() => {
    CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_SETTINGS).then((response) => {
      if (response.status == 200) {
        console.log(response.data)
        setSettings(response.data)

      } else {

      }
    }).finally(() => {

    });

  }, []);
  function formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return inputDate.toLocaleDateString('en-US', options);
  }


  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? footerTheme.palette.background.paper
              : footerTheme.palette.background.default,
        }}
      >
        <Toolbar className="min-h-24 md:min-h-32 px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
          <Chip size='small' label='Version 1.0.0'></Chip>
          <div className="flex grow shrink-0 px-12 justify-end">
            <div className="items-center">
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout1);
