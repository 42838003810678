import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import { Chip } from '@mui/material';
import { setNavigation } from 'app/store/fuse/navigationSlice';
import { useTranslation } from 'react-i18next';

function UserMenu(props) {
  const { t } = useTranslation("navigation");
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const [loggedInfoMail, setLoggedInfoMail] = useState("");
  const [loggedInfoUsername, setLoggedInfoUsername] = useState("");
  const [loggedInfoTwoFa, setLoggedInfoTwoFa] = useState("");
  const [loggedInfoHasAuth, setLoggedInfoHasAuth] = useState("");
  React.useEffect(() => {
    setLoggedInfoMail(localStorage.getItem('profileMail'));
    setLoggedInfoTwoFa(localStorage.getItem('profileTwoFactorEnabled'));
    setLoggedInfoHasAuth(localStorage.getItem('profileHasAuth'));
    setLoggedInfoUsername(GetUserNameCustomLogic(localStorage.getItem('profileUsername')))

  }, []);
  function updateNewUsernameValue() {
    setLoggedInfoUsername(localStorage.getItem('profileUsername'))
  }

  function GetUserNameCustomLogic(value) {
    const prefix = "_+*_";
    if (value.startsWith(prefix)) {
      return value.substring(11);
    } else {
      return value;
    }
  }

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex" style={{ marginRight: "4px" }}>
            {loggedInfoUsername}
          </Typography>

          <Chip sx={{
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
              fontSize: "12px"
            },
          }} size='small' label={localStorage.getItem('profileUserRole') == "Admin" ? "Admin" : "Read-only account"}></Chip>

        </div>
        <Avatar className="md:mx-4"><FuseSvgIcon>heroicons-outline:user</FuseSvgIcon></Avatar>

      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem component={Link} to="/profile" onClick={userMenuClose} role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('MyProfile')} />
            </MenuItem>
            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('SignOut')} />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
