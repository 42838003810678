import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';
import Home from '../main/home/Home';

import Error404Config from '../main/404/Error404Config';
import Devices from '../main/devices/Devices';

import { authRoles } from '../auth';
import ForgotPassword from '../main/sign-in/ForgotPassword';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import TwoFactorAuthentication from '../main/sign-in/TwoFactorAuthentication';
import AboutUs from '../main/aboutus/AboutUs';
import Profile from '../main/users/Profile';
import Users from '../main/users/Users';
import Api from '../main/api/Api.js';
import ProjectDashboardAppConfig from '../main/project/ProjectDashboardAppConfig';
import SMTPSettings from '../main/users/SMTPSettings';
import UploadPage from '../main/upload/UploadPage';
import Packagename from '../main/packagename/Packagename';
import InformSettings from '../main/users/InformSettings';
import Audit from '../main/audit/Audit';

const routeConfigs = [Error404Config, SignOutConfig, SignInConfig, ResetPasswordConfig, ProjectDashboardAppConfig];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="/home" />,
    auth: authRoles.admin,
  },
  {
    path: 'aboutus',
    element: <AboutUs />,
    auth: authRoles.admin,
  },
  {
    path: 'audit',
    element: <Audit />,
    auth: authRoles.admin,
  },
  {
    path: 'profile',
    element: <Profile />,
    auth: authRoles.admin,
  },
  {
    path: 'settings/smtp',
    element: <SMTPSettings />,
    auth: authRoles.admin,
  },
  {
    path: 'settings/inform',
    element: <InformSettings />,
    auth: authRoles.admin,
  },
  {
    path: 'devices',
    element: <Devices />,
    auth: authRoles.admin,
  },
  {
    path: 'users',
    element: <Users />,
    auth: authRoles.admin,
  },
  {
    path: 'apidoc',
    element: <Api />,
    auth: authRoles.admin,
  },
  {
    path: 'licenses',
    element: <Packagename />,
    auth: authRoles.admin,
  },
  {
    path: 'update',
    element: <UploadPage />,
    auth: authRoles.admin,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
