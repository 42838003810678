import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './app/configs/navigation-i18n/en';
import tr from './app/configs/navigation-i18n/tr';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: en,
  tr: tr,
};

const storedLanguage = localStorage.getItem('languageSelectedi18');
const defaultLanguage = storedLanguage || 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// Update the stored language in localStorage when it changes
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('languageSelectedi18', lng);
});

export default i18n;
