import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import CustomAxios from "app/shared-components/CustomAxios";

export const getNotifications = createAsyncThunk('notificationPanel/getData', async () => {
  let finaldata = []
  await CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_PACKAGES).then((response) => {
    console.log(response.data)
    if (response.status == 200) {
      // Get the current time
      const currentTime = new Date();

      // Map over the packages and filter based on the expiration date
      const filteredPackages = response.data
        .filter(item => new Date(item.licenseExpireDate) < currentTime)
        .map((item, index) => ({
          id: (index + 1).toString(),
          icon: "heroicons-solid:exclamation",
          title: "License Expired",
          description: `<b>${item.packageName}</b> is expired.`,
          time: item.licenseExpireDate,
          read: false,
          link: "/licenses",
          useRouter: true,
        }));

      finaldata = filteredPackages;
    } else {

    }
  }).catch(() => {

  }).finally(() => {

  });
  console.log(finaldata)
  return finaldata

});

export const dismissAll = createAsyncThunk('notificationPanel/dismissAll', async () => {
  const response = await axios.delete('/api/notifications');
  await response.data;

  return true;
});

export const dismissItem = createAsyncThunk('notificationPanel/dismissItem', async (id) => {
  const response = await axios.delete(`/api/notifications/${id}`);
  await response.data;

  return id;
});

export const addNotification = createAsyncThunk(
  'notificationPanel/addNotification',
  async (item) => {
    const response = await axios.post(`/api/notifications`, { ...item });
    const data = await response.data;

    return data;
  }
);

const notificationsAdapter = createEntityAdapter({});

const initialState = notificationsAdapter.upsertMany(notificationsAdapter.getInitialState(), []);

export const { selectAll: selectNotifications, selectById: selectNotificationsById } =
  notificationsAdapter.getSelectors((state) => state.notificationPanel.data);

const dataSlice = createSlice({
  name: 'notificationPanel/data',
  initialState,
  reducers: {},
  extraReducers: {
    [dismissItem.fulfilled]: (state, action) =>
      notificationsAdapter.removeOne(state, action.payload),
    [dismissAll.fulfilled]: (state, action) => notificationsAdapter.removeAll(state),
    [getNotifications.fulfilled]: (state, action) =>
      notificationsAdapter.addMany(state, action.payload),
    [addNotification.fulfilled]: (state, action) =>
      notificationsAdapter.addOne(state, action.payload),
  },
});

export default dataSlice.reducer;
