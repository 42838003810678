import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Checkbox, Chip, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiDataTable from "mui-datatables";
import SettingsIcon from '@mui/icons-material/SettingsSuggestOutlined';
import withRouter from '@fuse/core/withRouter';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import CableIcon from '@mui/icons-material/Cable';
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CustomAxios from "app/shared-components/CustomAxios";
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { showMessage } from 'app/store/fuse/messageSlice';
import LoadingTableBody from 'app/shared-components/LoadingTableBody';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { add } from 'lodash';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import CopyComponent from 'app/shared-components/CopyComponent';

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

const removeSchema = yup.object().shape({
    understandTick: yup.bool().oneOf([true], 'Field must be checked')
});
const defaultValues2 = {
    understandTick: false,
};

const userCreateSchema = yup.object().shape({
    mail: yup.string().email('Invalid email address')
        .matches(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            'Invalid email address'
        )
        .required('Email is required'),
    username: yup.string().required().max(8),
    role: yup.string().required(),
});

function Packagename(props) {
    const { t } = useTranslation("navigation");

    const [openDialog, setOpenDialog] = useState(false);
    const [isMalware, setIsMalware] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileContentString, setFileContentString] = useState('');

    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        fileContent(event.target.files[0])
    };

    const handleDialogOpen = (isMalware) => {
        setOpenDialog(true);
        setIsMalware(isMalware);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const [settings, setSettings] = useState([]);

    const fileContent = (file) => {
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))
        const selectedFile = file;

        if (!selectedFile) {
            console.error("No file selected.");
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const fileContent = e.target.result;

                setFileContentString(fileContent);

            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };

        reader.onloadend = (e) => {
            try {
                handleUpload(e.target.result)
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };

        reader.readAsText(selectedFile);
    }
    const handleUpload = (fileContentDirect) => {
        setLoading(true)
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))

        CustomAxios.POST(process.env.REACT_APP_WEB_API_ADD_PACKAGENAME, { packageFileContent: fileContentDirect }).then((response) => {
            dispatch(
                showMessage({
                    message: t('Success'),//text or html
                    autoHideDuration: 3000,//ms
                    anchorOrigin: {
                        vertical: 'top',//top bottom
                        horizontal: 'center'//left center right
                    },
                    variant: 'success'//success error info warning null
                }))
            setUsers(response.data)
            setOpenDialog(false);
            setFile(null);


        }).catch(() => {
            dispatch(
                showMessage({
                    message: t('Success2'),//text or html
                    autoHideDuration: 3000,//ms
                    anchorOrigin: {
                        vertical: 'top',//top bottom
                        horizontal: 'center'//left center right
                    },
                    variant: 'error'//success error info warning null
                }))
        }).finally(() => {
            setLoading(false)
        });

    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFile(null);
        setUploadProgress(0);
    };

    function daysLeftFromCurrentTime(dateTimeString) {
        // Parse the input datetime string to a Date object
        const targetDate = new Date(dateTimeString);

        // Get the current date and time
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = targetDate - currentDate;

        // Calculate the number of days left
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        // Check if the datetime has already passed
        if (daysLeft < 0) {
            return "Expired";
        } else {
            return daysLeft + " days left";
        }
    }
    const encodeToBase64 = (originalString) => {
        const base64EncodedString = btoa(originalString);
        return base64EncodedString;
    };
    const columns2 = [{
        name: "packageName",
        label: "PackageName",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                <div>{value}</div>
            ),
            customHeadLabelRender: (columnMeta) => (
                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
            )
        }
    },
    {
        name: "licenseExpireDate",
        label: "LicenseExpireDate",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip label={format(new Date(value), 'MMM dd, y') + " - " + daysLeftFromCurrentTime(value)} style={{ color: daysLeftFromCurrentTime(value) == "Expired" ? "red" : "green" }}></Chip>
            ),
            customHeadLabelRender: (columnMeta) => (
                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
            )
        }
    }, {
        name: "signature",
        label: "SdkLicense",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                <div><CopyComponent textToCopy={encodeToBase64(tableMeta.rowData[0] + ";" + format(new Date(tableMeta.rowData[1]), 'yyyy-MM-dd') + ";" + value)} /></div>
            ),
            customHeadLabelRender: (columnMeta) => (
                <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
            )
        }
    },];
    const [users, setUsers] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);

    React.useEffect(() => {
        setIsAdmin(localStorage.getItem("profileUserRole") === "Admin")
        setOpenLoading(true)
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_PACKAGES).then((response) => {
            if (response.status == 200) {
                setUsers(response.data)
            } else {

            }
        }).catch(() => {

        }).finally(() => {
            setOpenLoading(false)
        });
    }, []);

    const options = {

    }

    const [selectedDeviceIndexMenu, setSelectedDeviceIndexMenu] = useState([]);
    const [resetSelectedDatasTable, setResetSelectedDatasTable] = useState([]);

    const [editMerchUrlId, setEditMerchUrlId] = useState('');
    const [isSelectedCustomEdit, setIsSelectedCustomEdit] = useState(false);
    const [editMerchUrl, setEditMerchUrl] = useState('');
    const [editMerchCode, setEditMerchCode] = useState('');
    const [isSelectedCustom, setIsSelectedCustom] = React.useState(false);
    function onRowSelectionChangeCustom(rowsSelected) {
        setResetSelectedDatasTable(rowsSelected)
        setIsSelectedCustom(rowsSelected.length == 0 ? false : true);
        setIsSelectedCustomEdit(rowsSelected.length == 1 ? false : true);
        setSelectedDeviceIndexMenu(rowsSelected)
        if (rowsSelected.length === 1) {

        }
        console.log(rowsSelected)

    }
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const [openDelete, setOpenDelete] = React.useState(false);
    const [updateLoading, setUpdateLoading] = React.useState(false);

    const [open2, setOpen2] = React.useState(false);

    const { control: control2, formState: formState2, handleSubmit: handleSubmit2, setError: setError2, setValue: setValue2 } = useForm({
        mode: 'onChange',
        defaultValues2,
        resolver: yupResolver(removeSchema),
    });
    const { isValid: isValid2, dirtyFields: dirtyFields2, errors: errors2 } = formState2;


    const { control, formState, handleSubmit, setError, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(userCreateSchema),
    });
    const { isValid, dirtyFields, errors } = formState;

    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const [addGroup, setAddGroup] = useState(false);
    const [values, setValues] = React.useState([]);

    const [openLoginInfo, setOpenLoginInfo] = useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);
    const BodyComponent = React.useMemo(
        () => (props) => (
            <LoadingTableBody loading={openLoading} {...props} />
        ),
        [openLoading]
    );
    function generateRandomString() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '_+*_';

        for (let i = 1; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }
    function GetUserNameCustomLogic(value) {
        const prefix = "_+*_";
        if (value.startsWith(prefix)) {
            return value.substring(11);
        } else {
            return value;
        }
    }
    const [mailA, setMailA] = React.useState("");
    const [usernameA, setUsernameA] = React.useState("");
    const [passwordA, setPasswordA] = React.useState("");

    const dispatch = useDispatch()
    function removeDevice({ understandTick }) {
        setOpenDelete(false)

        let devicesList = []
        selectedDeviceIndexMenu.forEach(element => {
            devicesList.push(users[element].id)
        });

        CustomAxios.POST(process.env.REACT_APP_WEB_API_REMOVE_PACKAGENAME,
            { Id: devicesList }).then((response) => {
                if (response.status == 200) {
                    setUsers(response.data)
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                } else {
                    dispatch(
                        showMessage({
                            message: t('Success2'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                }
            })
            .catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            })
            .finally(() => {
                setResetSelectedDatasTable([])
                setIsSelectedCustom(false)
            });
    }

    function createAccountUser({ mail, username, role }) {
        setUpdateLoading(true)

        CustomAxios.POST(process.env.REACT_APP_WEB_API_CREATE_ACCOUNT_USER,
            { mail: mail, username: usernameA + username, password: passwordA, role: role }).then((response) => {
                if (response.status == 200) {
                    setMailA(mail)
                    setUsers(response.data)
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))

                    setOpenLoginInfo(true)
                } else {
                    dispatch(
                        showMessage({
                            message: t('Success2'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                }
            })
            .catch(() => {
                dispatch(
                    showMessage({
                        message: t('MailExists'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'warning'//success error info warning null
                    }))
            })
            .finally(() => {
                setUpdateLoading(false)
            });
    }
    function afterCloseLoginInfo() {
        setOpenLoginInfo(false)
        setAddGroup(false)
    }
    return (
        <Root
            header={<div />}
            content={
                <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
                    <Dialog open={openDialog}>
                        <DialogTitle >Add License</DialogTitle>

                        <DialogContent className="grid place-items-center">

                            <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                                <input
                                    type="file"
                                    accept=".cynlic"
                                    onChange={handleFileChange}
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="fileInput" style={{ textAlign: "center" }}>
                                    <Button variant="outlined" color="primary" component="span" style={{ textAlign: "center" }}>
                                        Select File
                                    </Button>
                                </label>
                                {file && (
                                    <div>
                                        <Typography variant="subtitle1">
                                            Selected File: {file.name}
                                        </Typography>

                                    </div>
                                )}


                            </div>

                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button type='submit' color='primary' onClick={handleCloseDialog}>Close</Button>
                            <LoadingButton loading={loading} disabled={!file} type='submit' color='primary' variant='contained' onClick={handleUpload}>Upload</LoadingButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={() => setOpenDelete(false)} open={openDelete} PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}>
                        <form
                            validateOnChange={false}
                            validateOnBlur={false}
                            name="loginForm"
                            noValidate
                            onSubmit={handleSubmit2(removeDevice)}
                        >
                            <DialogTitle >{t('RmText11Title')}</DialogTitle>

                            <DialogContent className="grid place-items-center">

                                <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                                    <Typography>{t('RmText11')}</Typography>
                                    <Controller
                                        name="understandTick"
                                        control={control2}
                                        render={({ field }) => (
                                            <FormControl
                                                {...field}
                                                error={!!errors2.understandTick}
                                                helperText={errors2?.understandTick?.message}
                                                variant="standard"
                                                fullWidth
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox name="understandTick" checked={field.value} />
                                                    }
                                                    label={t('RmText22')}
                                                />
                                            </FormControl>
                                        )}
                                    />

                                </div>

                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button color='primary' variant='outlined' onClick={() => setOpenDelete(false)}>{t('Cancel')}</Button>
                                <Button disabled={_.isEmpty(dirtyFields2) || !isValid2} type='submit' color='error' variant='contained'
                                    startIcon={<FuseSvgIcon className="hidden sm:flex">heroicons-outline:trash</FuseSvgIcon>}
                                >{t('RemoveSmall')}</Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog open={openLoginInfo}>

                        <DialogTitle >{t('LoginCredentials')}</DialogTitle>

                        <DialogContent className="grid place-items-center">

                            <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">

                                <TextField

                                    margin="normal"
                                    label="Email"
                                    type="text"
                                    value={mailA}
                                    variant="standard"
                                    required
                                    fullWidth
                                />
                                <TextField

                                    margin="normal"
                                    label="Password"
                                    type="text"
                                    value={passwordA}
                                    variant="standard"
                                    required
                                    fullWidth
                                />
                            </div>

                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button color='primary' variant='contained' onClick={() => afterCloseLoginInfo()}>{t('OK')}</Button>

                        </DialogActions>
                    </Dialog>

                    {!addGroup && <motion.div>
                        <motion.div
                            style={{ borderBottom: "1px solid #dce5f3" }}
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <div className="flex flex-col flex-auto">
                                <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                    {t('Licenses')}
                                </Typography>
                                <Typography className="font-medium tracking-tight" color="text.secondary">
                                    {t('Licenses2')}
                                </Typography>
                            </div>
                            <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
                                {isAdmin && <motion.div
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                                >

                                    <div>
                                        <input
                                            type="file"
                                            accept=".cynlic"
                                            onChange={handleFileChange}
                                            id="fileInput2"
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor="fileInput2" style={{ textAlign: "center" }}>
                                            <Button
                                                component="span" style={{ textAlign: "center" }}
                                                variant="outlined"
                                                color="secondary"
                                                startIcon={<FuseSvgIcon>material-outline:add</FuseSvgIcon>}

                                            >
                                                {t('AddMail')}
                                            </Button>
                                        </label>
                                    </div>

                                </motion.div>}
                            </div>
                        </motion.div>
                        <motion.div className="items-center justify-between py-32 px-24 md:px-32" initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
                            <Card >
                                {isAdmin && <div className='' style={!isSelectedCustom ? { borderBottom: "2px solid #dce5f3", width: "100%", float: "left", marginTop: "10px", color: "rgb(11, 61, 90)", pointerEvents: "none", opacity: "0.2" }
                                    : { borderBottom: "1px solid #dce5f3", width: "100%", float: "left", marginTop: "10px", color: "rgb(11, 61, 90)" }}>

                                    <div onClick={() => setOpenDelete(true)} className='customToolbarItem' style={{ cursor: "pointer" }}>
                                        <FuseSvgIcon size={26} style={{ float: "left", marginLeft: "15px", marginTop: "1px" }}>material-outline:delete_sweep</FuseSvgIcon><p style={{ float: "left", marginLeft: "5px", marginTop: "5px", fontWeight: "700" }}>{t('Remove')}</p>
                                    </div>
                                </div>

                                }
                                <MuiDataTable
                                    data={users}
                                    columns={columns2}
                                    options={{
                                        filter: false,
                                        download: false,
                                        selectableRows: isAdmin,
                                        selectToolbarPlacement: "none",
                                        rowsSelected: resetSelectedDatasTable,
                                        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => (
                                            onRowSelectionChangeCustom(rowsSelected)
                                        ),
                                        textLabels: {
                                            body: {
                                                noMatch:
                                                    <motion.div
                                                        initial={{ x: 10, opacity: 0 }}
                                                        animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
                                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div className="logo">
                                                            <Typography className="font-medium tracking-tight" color="text.secondary">{t('LNo3')}</Typography>
                                                        </div>
                                                    </motion.div>
                                            },
                                        },
                                    }}
                                    components={{ TableBody: BodyComponent }}
                                />
                            </Card>
                        </motion.div>
                    </motion.div>}

                    {addGroup &&
                        <motion.div initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
                            <motion.div
                                style={{ borderBottom: "1px solid #dce5f3" }}
                                className="flex flex-col sm:flex-row space-y-0 sm:space-y-0 flex-1 w-full items-center justify-between py-12 px-24 md:px-32"
                                initial={{ x: 20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                            >
                                <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-0 sm:space-y-0 py-12 px-24 md:px-32">
                                    <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
                                        <motion.div
                                            initial={{ x: 20, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                                        >
                                            <Typography
                                                className="flex items-center sm:mb-12"
                                                onClick={() => setAddGroup(false)}
                                                role="button"

                                                color="inherit"
                                            >
                                                <FuseSvgIcon size={20}>
                                                    {theme.direction === 'ltr'
                                                        ? 'heroicons-outline:arrow-sm-left'
                                                        : 'heroicons-outline:arrow-sm-right'}
                                                </FuseSvgIcon>
                                                <span className="flex mx-4 font-medium">{t('USERS')}</span>
                                            </Typography>
                                        </motion.div>

                                        <div className="flex items-center max-w-full">
                                            <motion.div
                                                className="hidden sm:flex"
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1, transition: { delay: 0.3 } }}
                                            >
                                                <div><FuseSvgIcon style={{ float: "left", color: "purple", marginRight: 5 }}>heroicons-outline:user-group</FuseSvgIcon></div>
                                            </motion.div>
                                            <motion.div
                                                className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
                                                initial={{ x: -20 }}
                                                animate={{ x: 0, transition: { delay: 0.3 } }}
                                            >
                                                <Typography className="text-16 sm:text-20 truncate font-semibold">
                                                    {t('AddUsers')}
                                                </Typography>

                                            </motion.div>
                                        </div>
                                    </div>

                                </div>
                            </motion.div>
                            <motion.div
                                className="items-center justify-between py-32 px-24 md:px-32"
                                variants={container}
                                initial="hidden"
                                animate="show"
                            >

                                <Card style={{ paddingTop: "30px", paddingLeft: "40px", paddingRight: "20px", paddingBottom: "-20px" }} className='grid grid-cols-1 gap-4 p-24 m-24'>

                                    <div className="w-full max-w-3xl text-center">
                                        <form
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            name="loginForm"
                                            noValidate
                                            onSubmit={handleSubmit(createAccountUser)}
                                        >
                                            <Controller
                                                name="mail"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        margin="normal"
                                                        label="Email"
                                                        type="text"
                                                        error={!!errors.mail}
                                                        helperText={errors?.mail?.message}

                                                        variant="standard"
                                                        required
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                            <div fullWidth className="grid grid-cols-4">
                                                <div class="col-span-4">
                                                    <Controller
                                                        name="username"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                margin="normal"
                                                                label={t('Username')}
                                                                type="text"
                                                                error={!!errors.username}
                                                                helperText={errors?.username?.message}

                                                                variant="standard"
                                                                required
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>

                                            </div>
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl variant="standard" sx={{ width: "100%", marginTop: "10px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">{t('SelectRole')}</InputLabel>
                                                        <Select
                                                            {...field}
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            margin="normal"
                                                            type="text"
                                                            error={!!errors.role}
                                                            helperText={errors?.role?.message}
                                                            variant="standard"
                                                            required
                                                            fullWidth
                                                            label="Select Role"
                                                        >
                                                            <MenuItem value="User"><b>Read-only:</b> {t('RoleText1')}</MenuItem>
                                                            <MenuItem value="Admin"><b>Admin:</b> {t('RoleText2')}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                            <LoadingButton type='submit' loading={updateLoading} disabled={_.isEmpty(dirtyFields) || !isValid} style={{ float: "right", marginTop: "20px" }} variant="outlined" color="secondary" endIcon={<SendIcon />}>{t('Save')}</LoadingButton>


                                        </form>
                                    </div>


                                </Card>


                            </motion.div>
                        </motion.div>}
                </ div>
            }
        />



    );
}
export default withRouter(Packagename);