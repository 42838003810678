import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import jwtService from '../../auth/services/jwtService';
import Axios from "app/shared-components/CustomAxios";
import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import ForgotPassword from './ForgotPassword';
import withRouter from '@fuse/core/withRouter';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import { makeStyles } from '@mui/styles';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { resetNavigation, setNavigation } from 'app/store/fuse/navigationSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),

  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  qrCode: {
    margin: theme.spacing(2),
  },
  input: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}));
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(4, 'Password is too short - must be at least 4 chars.'),
});

const defaultValues = {
  email: '',
  password: '',
  remember: true,
};

function SignInPage() {
  const dispatch = useDispatch();
  const [openLoading, setOpenLoading] = React.useState(false);
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue('email', '', { shouldDirty: false, shouldValidate: false });
    setValue('password', '', { shouldDirty: false, shouldValidate: false });
  }, [setValue]);

  function onSubmit({ email, password }) {
    setOpenLoading(true)

    Axios.Post_UnAuth(process.env.REACT_APP_WEB_API_LOGIN, { email: email, password: password })
      .then((response) => {
        if (response.status == 200) {
          dispatch(resetNavigation())
          if (response.data.role === "Admin") {
            dispatch(
              setNavigation(
                [
                  {
                    id: '1',
                    title: 'Dashboard',
                    translate: 'Dashboard',
                    type: 'item',
                    icon: 'heroicons-outline:chart-pie',
                    url: '/home',
                  },

                  {
                    id: '3',
                    title: 'Devices',
                    translate: 'Merchants',
                    type: 'item',
                    icon: 'material-outline:system_security_update_good',
                    url: '/devices',
                  },
                  {
                    id: '8',
                    title: 'Users',
                    translate: 'Users',
                    type: 'item',
                    icon: 'heroicons-outline:user-group',
                    url: '/users',
                  },
                  {
                    id: '9',
                    title: 'API',
                    translate: 'API',
                    type: 'item',
                    icon: 'heroicons-outline:cloud',
                    url: '/apidoc',
                  },
                  {
                    id: '10',
                    title: 'Update',
                    translate: 'Update',
                    type: 'item',
                    icon: 'heroicons-outline:upload',
                    url: '/update',
                  },
                  {
                    id: '4',
                    title: 'Licenses',
                    translate: 'Licenses',
                    type: 'item',
                    icon: 'material-outline:apps',
                    url: '/licenses',
                  },
                  {
                    id: '22',
                    title: 'Audit',
                    translate: 'Audit',
                    type: 'item',
                    icon: 'heroicons-outline:document-report',
                    url: '/audit',
                  },
                  {
                    id: 'apps.ecommerce',
                    title: 'Settings',
                    type: 'collapse',
                    icon: 'material-outline:settings',
                    translate: 'Settings',
                    children: [
                      {
                        id: 'e-commerce-products',
                        title: 'SMTP',
                        type: 'item',
                        url: 'settings/smtp',
                        end: true,
                      },
                      {
                        id: 'e-commerce-products2',
                        title: 'Inform Service',
                        type: 'item',
                        translate: 'InformService',
                        url: 'settings/inform',
                        end: true,
                      }
                    ],
                  },
                ]
              )
            );
          }
          else {
            dispatch(
              setNavigation(
                [
                  {
                    id: '1',
                    title: 'Dashboard',
                    translate: 'Dashboard',
                    type: 'item',
                    icon: 'heroicons-outline:chart-pie',
                    url: '/home',
                  },
                  {
                    id: '3',
                    title: 'Devices',
                    translate: 'Merchants',
                    type: 'item',
                    icon: 'material-outline:system_security_update_good',
                    url: '/devices',
                  },

                  {
                    id: '9',
                    title: 'API',
                    translate: 'API',
                    type: 'item',
                    icon: 'heroicons-outline:cloud',
                    url: '/apidoc',
                  },
                  {
                    id: '4',
                    title: 'Licenses',
                    translate: 'Licenses',
                    type: 'item',
                    icon: 'material-outline:apps',
                    url: '/licenses',
                  },
                ]
              )
            );

          }

          localStorage.setItem("profileUserRole", response.data.role)
          localStorage.setItem('profileMail', email);
          localStorage.setItem('profilePhone', response.data.phoneNumber);
          localStorage.setItem('profileTwoFactorEnabled', response.data.requiresTwoFactor);
          localStorage.setItem('profileHasAuth', response.data.hasAuthenticator);
          localStorage.setItem('profileUsername', response.data.username);
          //localStorage.setItem('jwt_access_token', response.data.token);

          if (response.data.requiresTwoFactor) {
            //Goto 2fa
            setPassSaved(password)
            setMailSaved(email)
            setTabValue(2)
          } else {
            jwtService
              .signInWithEmailAndPassword(response.data.token)
              .then((user) => {
                setOpenLoading(false)
                // No need to do anything, user data will be set at app/auth/AuthContext
              }).catch(() => {
                setOpenLoading(false)

              })
              .finally(() => {
                setOpenLoading(false)
              });
          }
        } else {

        }
      }).catch(() => {
        setOpenLoading(false)
      }).finally(() => {
        setOpenLoading(false)
      });
  }
  const [tabValue, setTabValue] = React.useState(0);
  function handleTabState() {
    setTabValue(0)
  }
  const classes = useStyles();
  const [twoFactorCode, setTwoFactorCode] = React.useState('');
  const [passSaved, setPassSaved] = React.useState('');
  const [mailSaved, setMailSaved] = React.useState('');

  const handleTwoFactorCodeChange = event => {
    setTwoFactorCode(event.target.value);
  };
  const verifyTwoFactorCode = () => {
    setOpenLoading(true)

    Axios.POST(process.env.REACT_APP_WEB_API_VERIFY_2FA, { email: mailSaved, verificationCode: twoFactorCode })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.message === "Your authenticator app has been verified") {
            jwtService
              .signInWithEmailAndPassword(mailSaved, passSaved)
              .then((user) => {
                setOpenLoading(false)
                // No need to do anything, user data will be set at app/auth/AuthContext
              }).catch(() => {
                setOpenLoading(false)

              })
              .finally(() => {
                setOpenLoading(false)
              });
          } else {
            dispatch(
              showMessage({
                message: 'Invalid code',//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                  vertical: 'top',//top bottom
                  horizontal: 'center'//left center right
                },
                variant: 'error'//success error info warning null
              }))
            setOpenLoading(false)
          }
        } else {
          dispatch(
            showMessage({
              message: 'Invalid code',//text or html
              autoHideDuration: 3000,//ms
              anchorOrigin: {
                vertical: 'top',//top bottom
                horizontal: 'center'//left center right
              },
              variant: 'error'//success error info warning null
            }))
          setOpenLoading(false)
        }
      }).catch(() => {
        dispatch(
          showMessage({
            message: 'Invalid code',//text or html
            autoHideDuration: 3000,//ms
            anchorOrigin: {
              vertical: 'top',//top bottom
              horizontal: 'center'//left center right
            },
            variant: 'error'//success error info warning null
          }))
        setOpenLoading(false)
      }).finally(() => {

      });
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      verifyTwoFactorCode()
    }
  }
  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          {tabValue === 0 && <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">

            <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
              Sign in
            </Typography>

            <form
              validateOnChange={false}
              validateOnBlur={false}
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full mt-32"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Email"
                    autoFocus
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <div >
                <a className="text-md font-medium" style={{ float: "right", cursor: "pointer" }} onClick={() => setTabValue(1)}>
                  Forgot password?
                </a>
              </div>


              <LoadingButton
                variant="contained"
                color="secondary"
                className=" w-full mt-16"
                aria-label="Sign in"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
                size="large"
                loading={openLoading}
              >
                Sign in
              </LoadingButton>
            </form>
          </div>}
          {tabValue === 1 &&
            <motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }} >
              <ForgotPassword setTabValue={handleTabState}></ForgotPassword>
            </motion.div>
          }
          {tabValue === 2 &&
            <motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }} >
              <div className={classes.paper}>
                <Typography variant="h5" style={{ width: "max-content" }}>Two Factor Authentication</Typography>
                <TextField className={classes.input} label="Enter code" type="password" onKeyPress={handleKeyDown} value={twoFactorCode} onChange={handleTwoFactorCodeChange} />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={verifyTwoFactorCode}
                  loading={openLoading}
                >
                  Verify Two Factor Code
                </LoadingButton>
              </div>

            </motion.div>
          }
        </div>


      </Paper>
    </div>
  );
}

export default SignInPage;
