import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Paper, Button, TextField, Grid } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import CustomAxios from "app/shared-components/CustomAxios";
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),

    },
    paper: {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paperRecoveryCodes: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    qrCode: {
        margin: theme.spacing(2),
    },
    input: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const TwoFactorAuthentication = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("navigation");
    const [twoLoading, setTwoLoading] = useState(false);
    const [isSetup, setIsSetup] = useState(props.isSetup);
    const classes = useStyles();
    const [qrCodeData, setQrCodeData] = useState(null);
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const [recoveryCodes, setRecoveryCodes] = useState(props.recoveryCodes);

    const generateQRCode = () => {
        // Make an API call to generate QR code data
        fetch('https://example.com/api/generateQRCode')
            .then(response => response.json())
            .then(data => {
                setQrCodeData(data);
                setQrCodeValue(data.qrCodeValue);
            });
    };

    const connectTo2FAApp = () => {
        setTwoLoading(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_VERIFY_2FA, { email: props.mail, verificationCode: qrCodeValue })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.message === "Your authenticator app has been verified") {

                        setTwoLoading(false)
                        dispatch(
                            showMessage({
                                message: t('Success'),//text or html
                                autoHideDuration: 3000,//ms
                                anchorOrigin: {
                                    vertical: 'top',//top bottom
                                    horizontal: 'center'//left center right
                                },
                                variant: 'success'//success error info warning null
                            }))
                        if (response.data.data != null) {
                            localStorage.setItem('profileTwoFactorEnabled', "true");
                            localStorage.setItem('profileHasAuth', "true");
                            setRecoveryCodes(response.data.data.recoveryCodes)
                            props.UpdateLocalValues(response.data.data.recoveryCodes)
                        }
                    } else {
                        dispatch(
                            showMessage({
                                message: t('InvalidCode'),//text or html InvalidCode
                                autoHideDuration: 3000,//ms
                                anchorOrigin: {
                                    vertical: 'top',//top bottom
                                    horizontal: 'center'//left center right
                                },
                                variant: 'error'//success error info warning null
                            }))
                        setTwoLoading(false)
                    }
                } else {
                    dispatch(
                        showMessage({
                            message: t('InvalidCode'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                    setTwoLoading(false)
                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('InvalidCode'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
                setTwoLoading(false)
            }).finally(() => {
                setTwoLoading(false)
            });
    };

    const handleTwoFactorCodeChange = event => {
        setTwoFactorCode(event.target.value);
    };

    const verifyTwoFactorCode = () => {

    };

    return (
        <Container maxWidth="sm" className={classes.container}>
            <div className={classes.paper}>
                <Typography variant="h5">QR Code</Typography>
                {true ? (
                    <>
                        {true && (
                            <>
                                <QRCode size={250} value={props.otpUrl} className={classes.qrCode} />
                                <Typography variant="body1">{t('ScanQr')}:</Typography>
                                <Typography variant="body2">{props.sharedKey}</Typography>
                                <TextField
                                    label={t('EnterQr')}
                                    variant="outlined"
                                    className={classes.input}
                                    value={qrCodeValue}
                                    type="password"
                                    onChange={event => setQrCodeValue(event.target.value)}
                                />
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={connectTo2FAApp}
                                    loading={twoLoading}
                                >
                                    {t('Connect2fa')}
                                </LoadingButton>
                                {recoveryCodes != undefined && recoveryCodes.length != 0 && (
                                    <Paper className={classes.paperRecoveryCodes}>
                                        <List>
                                            {recoveryCodes.map((code) => (
                                                <ListItem key={code}>
                                                    <ListItemText primary={code} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Paper>
                                )
                                }

                            </>
                        )}
                    </>
                ) : (
                    <>

                        <TextField className={classes.input} label="Enter code" type="text" value={twoFactorCode} onChange={handleTwoFactorCodeChange} />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={verifyTwoFactorCode}
                        >
                            Verify Two Factor Code
                        </Button>

                    </>
                )
                }
            </div>
        </Container >
    );
};

export default TwoFactorAuthentication;