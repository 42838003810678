import Typography from '@mui/material/Typography';
import mockApiJson from './mock-api.json';
import apiYaml from './api.yaml';
import { RedocStandalone } from 'redoc';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Root = styled('div')(({ theme, ...props }) => ({
    '& .menu-content': {
        top: '64px!important',
        bottom: 64,
        height: 'calc(100vh - 128px)!important',
    },
}));

function MockApiDoc() {
    return (
        <Root className="w-full">

            <Paper className="sticky top-0 w-full shadow">
                <RedocStandalone
                    spec={mockApiJson}
                    options={{
                        theme: {
                            colors: {
                                primary: {
                                    main: '#009688', // Change primary color to teal
                                },
                            },
                            typography: {
                                fontFamily: 'Roboto, sans-serif', // Change font to Roboto
                                headings: {
                                    fontFamily: 'Roboto, sans-serif', // Change heading font to Roboto
                                },
                            },
                            schema: {
                                nestedBackground: '#F5F5F5', // Change background color for nested schemas
                            },
                        },
                        hideDownloadButton: true, // Hide the download button
                        hideLoading: true, // Hide the loading spinner
                        menuToggle: true, // Enable the menu toggle button
                        hideHostname: true,
                        hideInfoSection: true,
                        hideInfoDescription: true,
                        hideDownloadButton: true,
                        noAutoAuth: true,
                        hideLoading: true,
                        nativeScrollbars: true,
                        jsonSampleExpandLevel: 1,

                        sortPropsAlphabetically: true,
                        sortTagsAlphabetically: true,
                        pathInMiddlePanel: true,
                    }}
                />
            </Paper>
        </Root>
    );
}

export default MockApiDoc;
