import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectWidgets } from '../../../store/widgetsSlice';
import { useTranslation } from 'react-i18next';
import { format, isValid, isBefore } from 'date-fns';
function OverdueWidget(props) {
  const { t } = useTranslation("navigation");
  const lastAlertDate = new Date(props.totalObjects.lastAlert);
  const isDateValid = isValid(lastAlertDate);
  const isDateBefore2020 = isBefore(lastAlertDate, new Date(2020, 0, 1));

  let formattedDate;
  if (isDateValid && !isDateBefore2020) {
    formattedDate = format(lastAlertDate, 'hh:mm a MM/dd/y');
  } else {
    formattedDate = "No Last Alert";
  }
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
        <Typography
          className="px-16 text-lg font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          <FuseSvgIcon>material-outline:access_alarm</FuseSvgIcon>
        </Typography>
        <IconButton aria-label="more" size="large">
          <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="text-center mt-8">
        <Typography className="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-red-500">
          {props.totalObjects.alerts}
        </Typography>
        <Typography className="text-lg font-medium text-red-600"> {t('Alerts')}</Typography>
      </div>
      <Typography
        className="flex items-baseline justify-center w-full mt-20 mb-24"
        color="text.secondary"
      >
        <span className="truncate"> {t('LastAlert')}</span>:
        <b className="px-8">{formattedDate}</b>
      </Typography>
    </Paper>
  );
}

export default memo(OverdueWidget);
