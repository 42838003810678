import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Paper from '@mui/material/Paper';
import ReactApexChart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CustomAxios from "app/shared-components/CustomAxios";
import { Card, Chip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import format from 'date-fns/format';
import clsx from 'clsx';

const Root = styled(Paper)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}));

function OperationsOverview() {
    const contrastTheme = useTheme();
    const [tabValue, setTabValue] = useState(1);


    const ranges = {
        "this-year": "This Year",
        "last-year": "Last Year"
    }
    let series = {
        "1": [
            {
                "name": "Operations",
                "data": [

                ]
            },
        ],
        "0": [
            {
                "name": "OperationsMonth",
                "data": [

                ]
            }
        ]
    }
    const [seriesState, setSeriesState] = useState(series);
    const chartOptions = {
        chart: {
            animations: {
                speed: 400,
                animateGradually: {
                    enabled: false,
                },
            },
            fontFamily: 'inherit',
            foreColor: 'inherit',
            width: '100%',
            height: '100%',
            type: 'area',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: true,
            },
        },
        colors: [contrastTheme.palette.secondary.light],
        dataLabels: {
            enabled: false,
        },
        fill: {
            colors: [contrastTheme.palette.secondary.dark],
        },
        grid: {
            show: false,
            borderColor: contrastTheme.palette.divider,

        },
        stroke: {
            width: 2,
        },
        tooltip: {
            followCursor: true,
            theme: 'dark',

            y: {
                formatter: (value) => `${value}`,
            },
        },
        xaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                stroke: {
                    color: contrastTheme.palette.divider,
                    dashArray: 0,
                    width: 2,
                },
            },
            labels: {
                offsetY: -20,
                style: {
                    colors: contrastTheme.palette.text.secondary,
                },
            },
            tooltip: {
                enabled: false,
            },
            type: 'datetime',
            tickPlacement: 'on'
        },
        yaxis: {
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },

            show: false,
        },
    };
    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
        // A function that increment 👆🏻 the previous state like here 
        // is better than directly setting `setValue(value + 1)`
    }
    const forceUpdate = useForceUpdate();
    useEffect(() => {

        CustomAxios.GET(process.env.REACT_APP_WEB_API_DASHBOARD_LOGS_DAY).then((response) => {
            series["0"][0].data = response.data
            setSeriesState(series);

        }).finally(() => {
            setTabValue(0)
        });

        CustomAxios.GET(process.env.REACT_APP_WEB_API_DASHBOARD_LOGS_MONTH).then((response) => {
            series["1"][0].data = response.data
            setSeriesState(series);


        }).finally(() => {

        });

    }, [seriesState]);

    const columns = ["Date", "Endpoint", "MCC", "Ratio", "Status"]
    const rows = [
        { date: '2022-10-07T22:22:37.274Z', endpoint: 'https://timestamp.online', mcc: "6653", ratio: '%30', status: 'success' },
        { date: '2022-12-18T14:51:24.461Z', endpoint: 'https://civi.online', mcc: "3345", ratio: '%33', status: 'success' },
        { date: '2022-12-25T17:52:14.304Z', endpoint: 'https://ayakkabi.com', mcc: "7087", ratio: '%57', status: 'failed' },
        { date: '2022-11-29T06:32:16.111Z', endpoint: 'https://saat.com.tr', mcc: "3867", ratio: '%30', status: 'success' },
        { date: '2022-11-24T12:13:23.064Z', endpoint: 'https://motor.com', mcc: "8776", ratio: '%10', status: 'success' },

    ]
    return (
        <Card>
            <div className="table-responsive mt-24">
                <Table className="simple w-full min-w-full">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index}>
                                    <Typography
                                        color="text.secondary"
                                        className="font-semibold text-12 whitespace-nowrap ml-8"
                                    >
                                        {column}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                {Object.entries(row).map(([key, value]) => {
                                    switch (key) {
                                        case 'mcc': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="" color="text.secondary">
                                                        <Chip label={value}></Chip>
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        case 'date': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="ml-8">
                                                        {format(new Date(value), 'MMM dd, y')}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        case 'amount': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="">
                                                        {value.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                        })}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        case 'status': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography
                                                        className={clsx(
                                                            'inline-flex items-center font-bold text-10 px-10 py-2 rounded-full tracking-wide uppercase',
                                                            value === 'failed' &&
                                                            'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                                                            value === 'success' &&
                                                            'bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50'
                                                        )}
                                                    >
                                                        {value}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        default: {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="">
                                                        {value}
                                                    </Typography>
                                                </TableCell>

                                            );
                                        }
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

        </Card>

    );
}

export default OperationsOverview;
