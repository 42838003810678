import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { motion } from 'framer-motion';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Popover, Tab, Tabs, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { showMessage } from 'app/store/fuse/messageSlice';
import CustomAxios from "app/shared-components/CustomAxios";
import UploadPageShared from 'app/shared-components/UploadPageShared';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    text: {
        marginBottom: theme.spacing(4),
    },
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

function UploadPage() {
    const { t } = useTranslation("navigation");
    const dispatch = useDispatch()

    useEffect(() => {


    }, []);

    return (
        <Root
            header={<>

            </>}
            content={
                <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
                    <motion.div >


                        <motion.div
                            style={{ borderBottom: "1px solid #dce5f3" }}
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <div className="flex flex-col flex-auto">
                                <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                    {t('Update')}
                                </Typography>
                                <Typography className="font-medium tracking-tight" color="text.secondary">
                                    {t('Update2')}
                                </Typography>
                            </div>
                        </motion.div>

                        <motion.div
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >

                            <Card style={{ width: "100%" }}>
                                <UploadPageShared></UploadPageShared>
                            </Card>
                        </motion.div>

                    </motion.div>
                </div>
            }
        />

    );
}

export default UploadPage;
