import FusePageSimple from '@fuse/core/FusePageSimple';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ProjectDashboardAppHeader from './ProjectDashboardAppHeader';
import reducer from './store';
import { getWidgets, selectWidgets } from './store/widgetsSlice';
import HomeTab from './tabs/home/HomeTab';
import TeamTab from './tabs/team/TeamTab';
import BudgetTab from './tabs/budget/BudgetTab';
import FuseLoading from '@fuse/core/FuseLoading';
import CustomAxios from "app/shared-components/CustomAxios";

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
  },
}));

function ProjectDashboardApp(props) {

  const dispatch = useDispatch();
  const widgets = useSelector(selectWidgets);
  const [totalObjects, setTotalObjects] = useState([]);
  const [tabValue, setTabValue] = useState(1);

  useEffect(() => {
    CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_USER_DASHBOARD_DATA).then((response) => {
      if (response.status == 200) {
        console.log(response.data)
        setTotalObjects(response.data);
        // localStorage.setItem("merchants", response.data.homeView.merchants)
        // localStorage.setItem("nmerchants", response.data.homeView.lastMerchantUrl)
      } else {

      }

    }).finally(() => {
      setTabValue(0);
    });

  }, []);

  function handleChangeTab(event, value) {
    setTabValue(value);
  }

  if (tabValue === 1) {
    return (
      <FuseLoading />
    )
  }
  return (
    <Root
      header={<ProjectDashboardAppHeader totalObjects={totalObjects} />}
      content={
        <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
          {tabValue === 0 && <HomeTab totalObjects={totalObjects} />}
          {tabValue === 1 && <FuseLoading />}
        </div>
      }
    />
  );
}

export default withReducer('projectDashboardApp', reducer)(ProjectDashboardApp);
