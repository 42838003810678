let userMenu = (localStorage.getItem('profileUserRole') === "Admin")
import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import ShieldMoonOutlinedIcon from '@mui/icons-material/ShieldMoonOutlined';
i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: '1',
    title: 'Dashboard',
    translate: 'DASHBOARD',
    type: 'item',
    icon: 'heroicons-outline:chart-pie',
    url: '/home',
  },

  {
    id: '3',
    title: 'Devices',
    translate: 'Merchants',
    type: 'item',
    icon: 'material-outline:system_security_update_good',
    url: '/devices',
  },
  userMenu &&
  {
    id: '8',
    title: 'Users',
    translate: 'USERS',
    type: 'item',
    icon: 'heroicons-outline:user-group',
    url: '/users',
  },
  {
    id: '9',
    title: 'API',
    translate: 'API',
    type: 'item',
    icon: 'heroicons-outline:cloud',
    url: '/apidoc',
  },
  userMenu && {
    id: '10',
    title: 'Update',
    translate: 'Update',
    type: 'item',
    icon: 'heroicons-outline:upload',
    url: '/update',
  },
  {
    id: '4',
    title: 'Licenses',
    translate: 'Licenses',
    type: 'item',
    icon: 'material-outline:apps',
    url: '/licenses',
  },
  userMenu && {
    id: '22',
    title: 'Audit',
    translate: 'Audit',
    type: 'item',
    icon: 'heroicons-outline:document-report',
    url: '/audit',
  },
  userMenu &&
  {
    id: 'apps.ecommerce',
    title: 'Settings',
    type: 'collapse',
    icon: 'material-outline:settings',
    translate: 'SETTINGS',
    children: [
      {
        id: 'e-commerce-products',
        title: 'SMTP',
        type: 'item',
        translate: 'SETTINGS_SMTP',
        url: 'settings/smtp',
        end: true,
      },
      {
        id: 'e-commerce-products2',
        title: 'Inform Service',
        type: 'item',
        translate: 'InformService',
        url: 'settings/inform',
        end: true,
      }
    ],
  },
];

export default navigationConfig;
