import { motion } from 'framer-motion';
import Operations from './Operations';
import OperationsOverview from './OperationsOverview';
import RecentLogs from './RecentLogs';


function HomeDetail() {
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    return (
        <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
            <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
                variants={container}
                initial="hidden"
                animate="show"
            >
                <motion.div variants={item} className="sm:col-span-2 md:col-span-4">
                    <Operations></Operations>
                </motion.div>

                <motion.div variants={item} className="sm:col-span-2 md:col-span-4">
                    <OperationsOverview></OperationsOverview>
                </motion.div>
            </motion.div>
        </div>

    );
}

export default HomeDetail;
