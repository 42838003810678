import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { styled } from '@mui/material/styles';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        opacity: "0"

    },
}));


const JsonViewer = ({ data, icon }) => {
    const classes = useStyles();

    const renderNode = (key, value) => {
        if (key == "Authorization")
            return ""
        if (typeof value === 'object' && value !== null) {
            return (
                <div key={key}>
                    <Typography variant="subtitle1" className={classes.nested}>
                        {key}
                    </Typography>
                    <div className={classes.nested}>
                        {Array.isArray(value) ? (
                            <TreeViewer data={value} />
                        ) : (
                            <JsonViewer data={value} />
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <Typography variant="body2" className={classes.nested} key={key}>
                    <span style={{ fontWeight: 'bold' }}>{`${key}:`}</span> {value} <br />
                </Typography>
            );
        }
    };

    const TreeViewer = ({ data }) => (
        <>
            {data.map((item, index) => (
                <React.Fragment key={index}>
                    {Object.entries(item).map(([key, value]) => renderNode(key, value))}
                </React.Fragment>
            ))}
        </>
    );

    return (
        <LightTooltip title={<Paper style={{ minWidth: "600px", maxHeight: "600px" }} className={classes.root}>
            {Array.isArray(data) ? (
                <TreeViewer data={data} />
            ) : (
                Object.entries(data).map(([key, value]) => renderNode(key, value))
            )}
        </Paper>}>

            {icon == "env" ? <SettingsApplicationsOutlinedIcon /> : <ViewInArOutlinedIcon />}

        </LightTooltip>

    );
};

export default JsonViewer;
