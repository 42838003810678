const locale = {
  Audit: "Audit",
  Audit2: "Audit Logları",

  InformService: "Servis Bilgilendirme",
  InformService2: "Bilgilendirme uç noktası ayarlarını özelleştirme",
  InformService3: "Servis Bilgilendirme Ayarları",
  InformService4: "Verilen URL ve başlığa göre gönderi isteği yapmak için servisi bilgilendirin",
  Licenses: "Lisanslar",
  Licenses2: 'Lisansları Özelleştir',
  Update: "Güncelleme",
  Update2: "Paylaşılan dosyalar aracılığıyla güncelleme",
  UpdateWhitelist: "Beyaz Listeyi Güncelle",
  UpdateWhitelist2: "Beyaz liste, bir sistemi çalıştırmasına veya bir sisteme erişmesine izin verilen ve diğer her şey engellenen güvenilir uygulamaların veya varlıkların listesidir.",
  UpdateMalware: "Kötü Amaçlı Yazılımları Güncelle",
  UpdateMalware2: "Mevcut Kötü Amaçlı Yazılım Sürümü",
  UpdateMalware3: "Kötü amaçlı yazılım listesinin güncellenmesi, düzenli olarak yeni bilinen kötü amaçlı yazılım imzalarının veya kalıplarının eklenmesini içerir.",
  licex: "Lisans Süresi Doldu",
  licex2: "",
  Devices2: "Cihazlar",
  Threats2: "Tehditler",
  Rd: "Kayıtlı Cihazlar",
  Th: "Tespit Edilen Tehditler",
  Rdth: "Kayıtlı Cihazlar ve Tespit Edilen Tehditler",

  APPLICATIONS: 'Programlar',
  EXAMPLE: 'Örnek Sayfa',
  DASHBOARD: 'Gösterge Paneli',
  ANALYSIS: "Analiz",
  MERCHANTS: "Cihazlar",
  ALERTSS: "Uyarı",
  USERS: "Kullanıcılar",
  API: "API",
  SETTINGS: "Ayarlar",
  SETTINGS_SMTP: "SMTP",

  Applications: 'Programlar',
  Example: 'Örnek Sayfa',
  Dashboard: 'Gösterge Paneli',
  Analysis: "Analiz",
  Alertss: "Uyarı",
  Users: "Kullanıcılar",
  Api: "API",
  Settings: "Ayarlar",
  SettingsSmtp: "SMTP",

  Welcome: 'Hoşgeldin',
  Notification: "Bildirimler",
  YouHave: "",
  YouHave2: "tane uyarın var.",
  NoNotif: "Şu anda herhangi bir bildirim bulunmamaktadır.",
  Merchants: "Cihazlar",
  LastMerch: "Son Cihaz",
  Alerts: "Uyarı",
  LastAlert: "Son Uyarı",
  Mails: "Mail",
  MailGroups: "Mail Grupları",
  Mcc: "İşyeri Kategori Kodu",
  TopMcc: "Top MCC",
  AnalysisSummary: "Analiz Özeti",
  EvsN: "Uygun ve Uygun Olmayan MCC'ler",
  Overview: "Genel",
  EligibleMerchants: "Uygun İş Yerleri",
  NonEligibleMerchants: "Uygun Olmayan İş Yerleri",
  l1: "Pzt",
  l2: "Salı",
  l3: "Çrş",
  l4: "Per",
  l5: "Cuma",
  l6: "Crt",
  l7: "Paz",
  TestMerchants: "İş Yerlerini Test Et",
  NewAnalysis: "Yeni İş Yeri Analizi",
  NewAnalysisDesc: "Verilen işyeri websitesi ve işyeri kategorisi kodunun uygunluğunu test etmek için tek işyeri analizi.",
  MWebsite: "İş Yeri Web Sitesi",
  Mmcc: "İş Yeri Kategori Kodu(MCC)",
  FullAnalysis: "Tüm İş Yerleri Analizi",
  FullAnalysisDesc: "Sistemde zaten bulunan tüm iş yerleri ve iş yerleri kategori kodlarının tam analiz testi.",
  Scan: "Tara",
  PsWait: "Lütfen Bekleyin Taranıyor",
  Completed: "Bitti",

  SingleSearchResult: "Analiz Tarama Sonucu",
  Eligibility: "Uygunluk",
  Result: "Sonuç",
  Eligible: "Uygun",
  NonEligible: "Uygunsuz",
  Date: "Tarih",
  Merchant: "Cihazlar",
  MCC: "MCC",
  Ratio: "Oran",
  Status: "Durum",
  MerchantAnalyzeResultNText: "Bu iş yeri",
  MerchantAnalyzeResultNText2: "verilen eşikten",
  MerchantAnalyzeResultNText3: "daha düşüktür.",
  MerchantAnalyzeResultNText4: "",
  MerchantAnalyzeResultText: "Bu iş yeri",
  MerchantAnalyzeResultText2: "verilen kategori kodu ile eşleşiyor.",
  MerchantAnalyzeResultText3: "",
  PleaseWaitScanningMerchs: "İş Yerleri Taranıyor Lütfen Bekleyin",

  CustomizeMerchants: "Cihazları Özelleştirme",
  ManageMerchants: "İş Yerlerini Yönet",
  Remove: "SİL",
  Edit: "DÜZENLE",
  Scan2: "TARA",
  AddSingleMerchant: "Tek İş Yeri Ekleme",
  AddSDesc: "Sisteme yeni bir tane iş yeri ekleyin.",
  AddMultipleMerchants: "Birden Çok İş Yeri Ekleme",
  AddMDesc: " Birden çok satıcı eklemek için excel ekleyin. (A sütunu <b>İş Yeri</b> ve B sütunu <b>MCC</b> olmalıdır)",
  UploadMerchs: "İş Yerleri Excelini Yükle",
  NonEThreshold: "Uygun Olmayan Eşik Değeri",
  NonEThDesc: "İş yerlerinin uygunluğunu belirleyen eşik değeridir. (Varsayılan değer <b>%80</b>'dir)",
  Threshold: "Eşik",
  AutoAnalyze: "Otomatik Analiz Aralığı",
  AutoAnalyzeDesc: "İş yerlerini otomatik olarak analiz etmek için tarama aralığı süresini yapılandırın.",
  SelectInterval: "Aralık Seç",
  None: "Hiç",
  Hourly: "Saatlik",
  Daily: "Günlük",
  Weekly: "Haftalık",
  Monthly: "Aylık",
  Save: "Kaydet",
  MerchantInfo: "İş Yeri Bilgisi",
  RemoveSmall: "Sil",
  TabsInfo: "Bilgi",
  TabsHistory: "Geçmiş",
  AvarageRatio: "Ortalama Oran",
  MerchantRatioAnalysis: "İş Yeri Analiz Oranı",
  ThirtyDays: "30 Gün",
  Overall: "Genel Puan",
  OverallDesc: "Genel puan, duruma göre tarihsel oran kullanılarak hesaplanır.",
  AvrRatioDesc: "Son 30 günün ortalama oranı",
  EditMerchant: "İş Yerini Düzenle",
  Cancel: "İptal",
  OK: "Tamam",

  CustomizeAlerts: "Uyarıları Özelleştirme",
  EditAlert: "Uyarı Düzenleme",
  MailGroups: "Mail Grupları",
  AlertName: "Adı",
  AlertSentType: "Gönderim Şekli",
  AlertMailGroup: "Mail Grup",
  AlertHistory: "Uyarı Geçmişi",
  MailList: "Mail Listesi",
  CustomizeMailList: 'Mail Listesi Özelleştirme',
  EditMail: "Mail Düzenleme",
  RemoveMails: "Mail Sil",
  RemoveMailsText: "Bu mailleri silmek istediğinize emin misiniz?",
  RemoveMailsText2: "Bu maillerle ilişkili tüm veriler silinecektir.",
  AddMail: 'Ekle',

  MailGroups: "Mail Grupları",
  MailGroupsC: "Mail Grupları Özelleştirme",
  GroupName: "Grup Adı",
  GroupDesc: "Grup Açıklaması",
  AddMailGroup: "Mail Grubu Ekleme",
  RemoveMailsG: "Mail Gruplarını Silme",
  RemoveMailsTextG: "Bu mail gruplarını silmek istediğinize emin misiniz?",
  RemoveMailsTextG2: "Bu mail grupları ile ilişkili tüm veriler silinecektir.",

  AlertHistory: "Uyarı Geçmişi",
  AlertHistoryC: "Uyarı Geçmişi Özelleştirme",
  ResolveAll: "HEPSİNİ ÇÖZ",
  ResolveAllAlerts: "Tüm Uyarıları Çöz",
  RaT1: "Bütün uyarıları çözmek istedeğinize emin misiniz?",
  RaT2: "Tüm uyarılar çözüldü olarak işaretlenecektir.",
  Resolved: "Çözüldü",
  ResolvedM: "Çözüldü Olarak İşaretle",

  Username: "Kullanıcı Adı",
  Permission: "Yetki",
  CustomizeUsers: "Kullanıcı Özelleştirme",
  AddUsers: "Kullanıcı Ekleme",
  SelectRole: "Rol Seçiniz",
  RoleText1: "Sadece içeriği görebilir ama editleyemez.",
  RoleText2: "Tüm yetkililere sahiptir ve içeriği değiştirebilir.",
  LoginCredentials: "Giriş Bilgileri",
  RemoveUsers: "Kullanıcıları Silme",
  RmText1: "Bu kullanıcıları silmek istediğinize emin misiniz?",
  RmText2: "Bu kullanıclar ile ilişkili tüm veriler silinecektir.",
  RmText11Title: "Lisansları Silme",
  RmText11: "Bu lisansları silmek istediğinize emin misiniz?",
  RmText22: "Bu lisanslar ile ilişkili tüm veriler silinecektir.",

  Customizesystemsettings: "SMTP Ayarlarını Özelleştirme",
  SMTPsettings: "SMTP Ayarları",
  SmtpDesc: "Uyarı bildirimlerini e-posta yoluyla göndermek için smtp ayarlarını yapılandırın.",

  RestApiUsage: "Rest Api Kullanımı",

  MyProfile: "Profilim",
  SignOut: "Çıkış Yap",

  Profile: "Profil",
  MainSettings: "Ana Ayarlar",
  MainSDesc: "Bu bilgiler profilinizde görünecektir.",
  Phone: "Telefon",
  CurrentPassword: "Şuanki Şifre",
  TfaAuth: "İki Faktörlü Kimlik Doğrulama",
  TfaAuthDesc: "İki Faktörlü Kimlik Doğrulamayı etkinleştirerek hesabınızın güvenliğini artırın.",
  Status: "Durum",
  Disabled: "Kapalı",
  Enabled: "Açık",
  NotConnected: "Bağlı Değil",
  ManageTwoFactorAuth: "İki Faktörlü Kimlik Doğrulamayı Kurun",
  SetupTwoFactor: "2FA'yı Kurun",
  ScanQr: "2FA uygulamanızla bu QR kodunu taratın",
  EnterQr: "2FA uygulamasındaki kodu girin",
  Connect2fa: "2FA Bağlan",
  ChangePassword: "Şifreyi Değiştir",
  ChangePasswordDesc: "Yaygın ifadeler, kişisel bilgiler veya ardışık karakterler kullanmaktan kaçının.",
  NewPassword: "Yeni Şifre",
  ConfirmNewPassword: "Yeni Şifreyi Doğrula",

  Success: "Başarılı",
  Success2: "Bir Şeyler Ters Gitti",
  MailGroupAlreadyExists: "Mail Grubu Zaten Var",
  DefaultGroupCRemoved: "Varsayılan Grup Kaldırılamaz",
  MailExists: "Mail Already Exists",
  PleaseWait: "Lütfen Bekleyin",
  MccNotSupported: "MCC Desteklenmiyor",
  MerchSucc: "Başarılı - ",
  MerchsAdded: " İş Yeri Eklendi",
  ScanFirst: "Detayları Görmek İçin Taratın",
  ScanStarted: "Tarama başladı. İş yerleri güncellenecektir.",
  InvalidCode: "Yanlış Kod",
  CheckCurrentPassword: "Şifreyi Kontrol Edin",
  CheckPassword: "Şifreyi Kontrol Edin",
  Copy: "Kopyalandı",
  CopyT: "Kopyala",
  GenerateNewApi: "Yeni X-API-Key Oluştur",

  LNo1: "Görünüşe göre henüz bir cihaziniz yok.",
  LNo2: "Görünüşe göre henüz bir kullanıcınız yok.",
  LNo3: "Görünüşe göre henüz bir log yok.",

};

export default locale;
