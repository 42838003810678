import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import ResetSuccesRedirect from './ResetSuccesRedirect';
import CustomAxios from "app/shared-components/CustomAxios";
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    password: yup
        .string()
        .required('Please enter your password.')
        .min(8, 'Password is too short - should be 8 chars minimum.'),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
    password: '',
    passwordConfirm: '',
};

function ResetPassword() {
    const location = useLocation();
    const [resetToken, setResetToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isPasswordReseted, setIsPasswordReseted] = useState(false);
    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const userId = params.get('userId');
        const token = params.get('token');
        setResetToken(token);
        setUserId(userId)
    }, [location.search]);

    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    function onSubmit({ password }) {
        setLoading(true)

        CustomAxios.POST(process.env.REACT_APP_WEB_API_RESET_PASSWORD,
            { password: password, token: resetToken, clientId: userId }).then((response) => {
                if (response.status == 200 && response.data !== null) {
                    reset(defaultValues);
                    setIsPasswordReseted(true)
                } else {

                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            });
    }

    if (isPasswordReseted) {
        return (
            <ResetSuccesRedirect></ResetSuccesRedirect>
        )
    }

    return (
        <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
            <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
                <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">

                    <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                        Reset your password
                    </Typography>
                    <Typography className="font-medium">Create a new password for your account</Typography>

                    <form
                        name="registerForm"
                        noValidate
                        className="flex flex-col justify-center w-full mt-32"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-24"
                                    label="Password"
                                    type="password"
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            )}
                        />

                        <Controller
                            name="passwordConfirm"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-24"
                                    label="Password (Confirm)"
                                    type="password"
                                    error={!!errors.passwordConfirm}
                                    helperText={errors?.passwordConfirm?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            )}
                        />

                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            color="secondary"
                            className=" w-full mt-4"
                            aria-label="Register"
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            type="submit"
                            size="large"
                        >
                            Reset your password
                        </LoadingButton>

                    </form>
                </div>
            </Paper>
        </div>
    );
}

export default ResetPassword;
