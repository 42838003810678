import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { motion } from 'framer-motion';
import TwoFactorAuthentication from '../sign-in/TwoFactorAuthentication';
import SendIcon from '@mui/icons-material/Send';
import Draggable from 'react-draggable';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { lg } from '@tailwindcss/typography/src/styles';
import QRCode from 'qrcode.react';
import FormGroup from '@mui/material/FormGroup';
import CustomAxios from "app/shared-components/CustomAxios";
import Switch from '@mui/material/Switch';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        margin: "auto",
        maxWidth: "800px",
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    qrCode: {
        margin: theme.spacing(2),
    },
    input: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),

    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const editSchema = yup.object().shape({
    userName: yup.string().required(''),
    email: yup.string().required(''),
    phoneNumber: yup.string().required('').matches(phoneRegExp, 'Phone number is not valid'),
    password: yup.string().required('')
});

const Profile = () => {
    const { t } = useTranslation("navigation");
    const dispatch = useDispatch();

    const [loggedInfoMail, setLoggedInfoMail] = useState('');
    const [loggedInfoPhone, setLoggedInfoPhone] = useState('');
    const [loggedInfoUsername, setLoggedInfoUsername] = useState('');
    const [loggedInfoTwoFa, setLoggedInfoTwoFa] = useState('');
    const [loggedInfoHasAuth, setLoggedInfoHasAuth] = useState('');
    const [recoveryCodes, setRecoveryCodes] = useState([]);
    const [setupOtpUrl, setSetupOtpUrl] = useState('');
    const [sharedKey, setSharedKey] = useState('');


    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordsMatchRegex, setPasswordsMatchRegex] = useState(true);
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const isValidPassword = (password) => {
        // Use a regular expression to check for at least one uppercase letter, one lowercase letter, one digit, and one special character
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])/;
        return passwordRegex.test(password);
    };
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordsMatchRegex(isValidPassword(e.target.value))
        console.log(isValidPassword(e.target.value))
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(newPassword === e.target.value)
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();
        setUpdateLoading2(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_CHANGE_PASSWORD,
            {
                currentPassword: currentPassword,
                newPassword: newPassword,
                clientId: "s"
            }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('CheckCurrentPassword'),//text or html CheckCurrentPassword
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'warning'//success error info warning null
                    }))
            }).finally(() => {
                setUpdateLoading2(false)
            });

    };
    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
        // A function that increment 👆🏻 the previous state like here 
        // is better than directly setting `setValue(value + 1)`
    }
    const forceUpdate = useForceUpdate();

    React.useEffect(() => {
        UpdateLocalValues()

        CustomAxios.POST(process.env.REACT_APP_WEB_API_SETUP_2FA,
            {
                userMail: localStorage.getItem('profileMail')
            }).then((response) => {
                if (response.status == 200) {
                    setSetupOtpUrl(response.data.authenticatorUri)
                    setSharedKey(response.data.sharedKey)
                } else {

                }
            }).catch(() => {

            }).finally(() => {

            });
    }, []);

    function UpdateLocalValues(recoveryCodes) {
        setRecoveryCodes(recoveryCodes)
        setLoggedInfoMail(localStorage.getItem('profileMail'));
        setLoggedInfoPhone(localStorage.getItem('profilePhone'));
        setLoggedInfoTwoFa(localStorage.getItem('profileTwoFactorEnabled') === "true");
        setLoggedInfoHasAuth(localStorage.getItem('profileHasAuth') === "true");
        setLoggedInfoUsername(localStorage.getItem('profileUsername'))
        setValue('userName', GetUserNameCustomLogic(localStorage.getItem('profileUsername')), { shouldDirty: false, shouldValidate: false });
        setValue('email', localStorage.getItem('profileMail'), { shouldDirty: false, shouldValidate: false });
        setValue('phoneNumber', localStorage.getItem('profilePhone'), { shouldDirty: false, shouldValidate: false });
    }

    function GetUserNameCustomLogic(value) {
        const prefix = "_+*_";
        if (value.startsWith(prefix)) {
            return value.substring(11);
        } else {
            return value;
        }
    }
    const classes = useStyles();
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const handleCloseTest = () => {
        setOpenEditDialog(false);
    };
    const [qrCodeValue, setQrCodeValue] = useState('');
    const connectTo2FAApp = () => {

    };

    function handelSwitch(e) {
        var doSet = e.target.checked
        CustomAxios.POST(process.env.REACT_APP_WEB_API_MANAGE_2FA,
            {
                mail: localStorage.getItem('profileMail'),
                disable: doSet
            }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: doSet ? t('Enabled') : t('Disabled'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    setLoggedInfoTwoFa(doSet)
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {

            });

    }
    const [updateLoading, setUpdateLoading] = React.useState(false);
    const [updateLoading2, setUpdateLoading2] = React.useState(false);
    const { control, formState, handleSubmit, setError, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(editSchema),
    });
    const { isValid, dirtyFields, errors } = formState;

    function generateRandomString() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '_+*_';

        for (let i = 1; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }

    function updateUser({ userName, email, phoneNumber, password }) {
        setUpdateLoading(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_USER,
            { ClientId: localStorage.getItem('clientId'), userName: generateRandomString() + userName, email: email, phoneNumber: phoneNumber, password: password }).then((response) => {
                if (response.status == 200) {
                    localStorage.setItem('profileUsername', userName)
                    setLoggedInfoUsername(userName)
                    localStorage.setItem('profilePhone', phoneNumber)
                    setLoggedInfoPhone(phoneNumber)
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    window.location.reload();
                } else {
                    dispatch(
                        showMessage({
                            message: t('Success2'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('CheckPassword'),//text or html CheckPassword
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            })
            .finally(() => {
                setUpdateLoading(false)
            });
    }
    return (
        <Root
            header={<div />}
            content={
                <div className="w-full p-12 pt-2 sm:pt-2 lg:ltr:pr-0 lg:rtl:pl-0">
                    <Dialog PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={openEditDialog} PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}>

                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{t('SetupTwoFactor')}<div onClick={handleCloseTest} style={{ cursor: "pointer", float: "right", marginTop: "-8px" }}><FuseSvgIcon>material-outline:close</FuseSvgIcon></div></DialogTitle>

                        <DialogContent className="grid place-items-center p-0" >
                            <TwoFactorAuthentication otpUrl={setupOtpUrl} sharedKey={sharedKey} mail={loggedInfoMail} UpdateLocalValues={UpdateLocalValues} recoveryCodes={recoveryCodes}></TwoFactorAuthentication>

                        </DialogContent>

                    </Dialog>
                    <motion.div >
                        <motion.div
                            style={{ borderBottom: "1px solid #dce5f3" }}
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <div className="flex flex-col flex-auto" style={{ textAlign: 'center' }}>
                                <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                    {t('Profile')}
                                </Typography>
                                <Typography className="font-medium tracking-tight" color="text.secondary">
                                    {localStorage.getItem('profileUserRole') == "Admin" ? "Admin" : "Read-only account"}
                                </Typography>
                            </div>
                        </motion.div>

                        <motion.div
                            className="items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <Card style={{ paddingTop: "50px", paddingLeft: "60px", paddingRight: "20px", paddingBottom: "-20px" }} className='grid grid-cols-1 gap-4 p-24 m-24'>

                                <div >
                                    <div style={{ maxWidth: "1000px" }}>
                                        <Grid item xs={12} sm={6}>

                                            <Typography variant="h5">{t('MainSettings')}</Typography>
                                            <Typography variant="subtitle2">
                                                {t('MainSDesc')}
                                            </Typography>
                                            <form
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                name="loginForm"
                                                noValidate
                                                onSubmit={handleSubmit(updateUser)}
                                            >
                                                <Controller
                                                    name="userName"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            margin="normal"
                                                            label={t('AlertName')}
                                                            type="text"
                                                            error={!!errors.userName}
                                                            helperText={errors?.userName?.message}
                                                            defaultValue={loggedInfoUsername}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            margin="normal"
                                                            label="Email"
                                                            type="text"
                                                            disabled
                                                            error={!!errors.email}
                                                            helperText={errors?.email?.message}
                                                            defaultValue={loggedInfoMail}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name="phoneNumber"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            margin="normal"
                                                            label={t('Phone')}
                                                            type="text"
                                                            error={!!errors.phoneNumber}
                                                            helperText={errors?.phoneNumber?.message}
                                                            defaultValue={loggedInfoPhone}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}

                                                            label={t('CurrentPassword')}
                                                            type="password"
                                                            error={!!errors.password}
                                                            helperText={errors?.password?.message}
                                                            margin="normal"
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                        />
                                                    )}
                                                />

                                                <LoadingButton type='submit' loading={updateLoading} disabled={_.isEmpty(dirtyFields) || !isValid} style={{ float: "right", marginTop: "10px" }} variant="outlined" color="secondary" endIcon={<SendIcon />}>{t('Save')}</LoadingButton>
                                                <div style={{ paddingBottom: "70px" }}></div>
                                            </form>
                                        </Grid>

                                    </div>
                                </div>
                                <hr style={{ maxWidth: "1000px", marginTop: "20px", marginBottom: "20px" }}></hr>
                                <div >
                                    <div style={{ maxWidth: "1000px" }}>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: "40px" }}>

                                            <Typography variant="h5">{t('TfaAuth')}</Typography>
                                            <Typography variant="subtitle2">
                                                {t('TfaAuthDesc')}
                                            </Typography>
                                            <Typography variant="subtitle2" style={{ margin: "10px" }}>
                                                {t('Status')} :  {!loggedInfoHasAuth ? <Chip className='bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50' size="small" label={t('NotConnected')} /> :
                                                    (!loggedInfoTwoFa ? <Chip className='bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50' size="small" label={t('Disabled')} /> :
                                                        <Chip className='bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50' size="small" label={t('Enabled')} />)}
                                            </Typography>
                                            <Button variant="contained" color="success" onClick={() => setOpenEditDialog(true)}>
                                                {t('ManageTwoFactorAuth')}
                                            </Button>
                                            <FormControlLabel
                                                disabled={!loggedInfoHasAuth}
                                                control={<MaterialUISwitch sx={{ m: 1 }} checked={loggedInfoTwoFa} onChange={(e) => handelSwitch(e)} />}
                                                label=""
                                            />
                                        </Grid>

                                        <div style={{ paddingBottom: "40px" }}></div>
                                    </div>
                                </div>

                                <hr style={{ maxWidth: "1000px", marginTop: "20px", marginBottom: "20px" }}></hr>
                                <div style={{ marginBottom: "-100px" }}>
                                    <div style={{ maxWidth: "1000px" }}>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: "20px" }}>

                                            <Typography variant="h5">{t('ChangePassword')}</Typography>
                                            <Typography variant="subtitle2">
                                                {t('ChangePasswordDesc')}
                                            </Typography>
                                            <form onSubmit={handleSubmit2}>
                                                <TextField
                                                    label={t('CurrentPassword')}
                                                    type="password"
                                                    value={currentPassword}
                                                    onChange={handleCurrentPasswordChange}
                                                    required
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <TextField
                                                    label={t('NewPassword')}
                                                    type="password"
                                                    value={newPassword}
                                                    onChange={handleNewPasswordChange}
                                                    required
                                                    fullWidth
                                                    margin="normal"
                                                    error={!passwordsMatchRegex}
                                                    helperText={!passwordsMatchRegex && "Password must include at least one uppercase letter, one number, one lowercase letter, and one special character."}
                                                />

                                                <TextField
                                                    label={t('ConfirmNewPassword')}
                                                    type="password"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPasswordChange}
                                                    required
                                                    fullWidth
                                                    margin="normal"
                                                    error={!passwordsMatch}
                                                    helperText={!passwordsMatch && "Passwords do not match"}
                                                />

                                                <LoadingButton type='submit' loading={updateLoading2} disabled={!passwordsMatch || !passwordsMatchRegex || confirmPassword.length == 0 || newPassword.length == 0 || currentPassword.length == 0} style={{ float: "right", marginTop: "10px", marginBottom: "20px" }} variant="outlined" color="secondary" endIcon={<SendIcon />}>{t('Save')}</LoadingButton>

                                            </form>
                                            <div style={{ paddingBottom: "70px" }}></div>
                                        </Grid>
                                        <div style={{ marginBottom: "80px" }}></div>
                                    </div>

                                </div>


                            </Card>
                        </motion.div>


                    </motion.div>

                </ div>
            }
        />

    );
};

export default Profile;
