import React from 'react';
import { MUIDataTableBody, TableBody, TableBodyCell, TableBodyRow } from 'mui-datatables';
import {
    CircularProgress,
    Typography,
    TableBody as MuiTableBody
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';


const LoadingTableBody = ({ loading, options, columns, children, ...others }) => {
    const visibleColCnt = columns.filter(c => c.display === 'true').length;

    return loading ? (
        <MuiTableBody style={{ pointerEvents: "none" }}>
            <TableBodyRow options={options}>
                <TableBodyCell
                    colSpan={
                        options.selectableRows !== 'none' || options.expandableRows
                            ? visibleColCnt + 1
                            : visibleColCnt
                    }
                    options={options}
                    colIndex={0}
                    rowIndex={0}
                >
                    <Typography variant="body1" component={'div'}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="logo">
                                <img width="128" src="assets/a.gif" alt="logo" />
                            </div>
                        </div>
                    </Typography>
                </TableBodyCell>
            </TableBodyRow>
        </MuiTableBody>
    ) : (
        <AnimatePresence>
            <TableBody options={options} columns={columns} {...others}>
                <motion.div
                    initial={{}}
                    animate={{}}
                    exit={{ opacity: 0 }}
                    style={{ height: "100%", width: "100%" }}
                >
                    {children}
                </motion.div>
            </TableBody>
        </AnimatePresence>

    );
};

export default LoadingTableBody;
