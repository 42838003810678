import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectWidgets } from '../../../store/widgetsSlice';
import { useTranslation } from 'react-i18next';

function SummaryWidget(props) {
  const { t } = useTranslation("navigation");

  function handleChangeRange(ev) {
    setCurrentRange(ev.target.value);
  }

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
        <Typography
          className="px-16 text-lg font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          <FuseSvgIcon>material-outline:store</FuseSvgIcon>
        </Typography>
        {!props.analysis && <IconButton aria-label="more" size="large">
          <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
        </IconButton>}

      </div>
      <div className="text-center mt-8" style={props.analysis ? { marginBottom: "40px" } : {}}>
        <Typography className="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-blue-500">
          {props.analysis ? props.merchantSize : props.totalObjects.merchants}
        </Typography>
        <Typography className="text-lg font-medium text-blue-600 dark:text-blue-500">
          {t('Merchants')}
        </Typography>
      </div>
      {!props.analysis && <Typography
        className="flex items-baseline justify-center w-full mt-20 mb-24"
        color="text.secondary"
      >
        <span className="truncate">{t('LastMerch')}</span>:
        <b className="px-8">{localStorage.getItem("nmerchants") != "null" ? new URL(props.analysis ? localStorage.getItem("nmerchants") : props.totalObjects.lastMerchantUrl).hostname.startsWith('www.') ? new URL(props.analysis ? localStorage.getItem("nmerchants") : props.totalObjects.lastMerchantUrl).hostname.substring(4) : new URL(props.analysis ? localStorage.getItem("nmerchants") : props.totalObjects.lastMerchantUrl).hostname : "NA"} </b>
      </Typography>}
    </Paper>
  );
}

export default memo(SummaryWidget);
