import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Checkbox, Chip, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiDataTable from "mui-datatables";
import SettingsIcon from '@mui/icons-material/SettingsSuggestOutlined';
import withRouter from '@fuse/core/withRouter';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import CableIcon from '@mui/icons-material/Cable';
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CustomAxios from "app/shared-components/CustomAxios";
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { showMessage } from 'app/store/fuse/messageSlice';
import LoadingTableBody from 'app/shared-components/LoadingTableBody';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { add } from 'lodash';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import CopyComponent from 'app/shared-components/CopyComponent';
import Person4OutlinedIcon from '@mui/icons-material/Person';
import JsonViewer from 'app/shared-components/JsonViewer';
import ErrorBoundary from 'app/shared-components/ErrorBoundary';


const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

const removeSchema = yup.object().shape({
    understandTick: yup.bool().oneOf([true], 'Field must be checked')
});
const defaultValues2 = {
    understandTick: false,
};

const userCreateSchema = yup.object().shape({
    mail: yup.string().email('Invalid email address')
        .matches(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            'Invalid email address'
        )
        .required('Email is required'),
    username: yup.string().required().max(8),
    role: yup.string().required(),
});

function Packagename(props) {
    const { t } = useTranslation("navigation");

    const [openDialog, setOpenDialog] = useState(false);
    const [isMalware, setIsMalware] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileContentString, setFileContentString] = useState('');

    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        fileContent(event.target.files[0])
    };

    const encodeToBase64 = (originalString) => {
        const base64EncodedString = btoa(originalString);
        return base64EncodedString;
    };
    const formatDateIso = (dateString) => {
        const date = parseISO(dateString);
        return format(date, 'MMMM d, yyyy - hh:mm a');
    };
    function camelCaseToSpaced(inputString) {
        return inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    const columns2 = [
        {
            name: 'data',
            label: 'Time',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                        <div style={{ color: "#4d758c", fontWeight: "normal" }}>{formatDateIso(JSON.parse(value).StartDate)}</div>
                    </motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: 'data',
            label: 'User',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const parsedValue = JSON.parse(value);
                    return (
                        <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                            <Person4OutlinedIcon style={{ float: "left", color: "#4d758c", opacity: ".6", marginRight: 5, marginTop: "-3px" }}></Person4OutlinedIcon>
                            <div style={{ color: "#4d758c", fontWeight: "normal", whiteSpace: "nowrap" }}>
                                {parsedValue.Action.ActionName == "Login" ? parsedValue.Action.ActionParameters.request.Email : GetUserNameCustomLogic(parsedValue.Action.UserName)}
                            </div>
                        </motion.div>
                    )
                },
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: 'data',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                        <div style={{ color: "#4d758c", fontWeight: "normal" }}>{camelCaseToSpaced(JSON.parse(value).Action.ActionName)}</div>
                    </motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: 'data',
            label: 'Headers',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                        <div style={{ color: "#4d758c", fontWeight: "normal" }}><JsonViewer data={(JSON.parse(value).Action.Headers)}></JsonViewer></div>
                    </motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
        {
            name: 'data',
            label: 'Environment',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, }}>
                        <div style={{ color: "#4d758c", fontWeight: "normal" }}><JsonViewer data={(JSON.parse(value).Environment)} icon="env"></JsonViewer></div>
                    </motion.div>
                ),
                customHeadLabelRender: (columnMeta) => (
                    <div style={{ color: "#4d758c", fontWeight: "normal" }}>{columnMeta.label}</div>
                )
            }
        },
    ];
    const [logs, setLogs] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);


    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalItems, setTotalItems] = useState(0);
    const fetchData = async (page, rowsPerPage) => {
        setRowsPerPage(rowsPerPage)
        setOpenLoading(true)
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_AUDIT_LOGS + "?page=" + page + "&perPage=" + rowsPerPage).then((response) => {
            if (response.status == 200) {
                setLogs(response.data.logs)
                setTotalItems(response.data.count)
            } else {

            }
        }).finally(() => {
            setTabValue(0)
            setOpenLoading(false)
        });
    };
    const optionsTest = {
        filterOptions: {
            fullWidth: true
        },
        serverSide: false,
        count: totalItems,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [100, 500, 1000, 10000],
        onChangePage: (currentPage) => fetchData(currentPage + 1, rowsPerPage),
        onChangeRowsPerPage: (rowsPerPage) => fetchData(1, rowsPerPage),

        download: false, print: false,
        selectableRows: false,
        filter: false,
        filterType: "multiselect",

        selectToolbarPlacement: "none",

        textLabels: {
            body: {
                noMatch:
                    <motion.div
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="logo">
                            <Typography className="font-medium tracking-tight" color="text.secondary">{t('LNo3')}</Typography>
                        </div>
                    </motion.div>
            },
        },
    }
    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
        // A function that increment 👆🏻 the previous state like here 
        // is better than directly setting `setValue(value + 1)`
    }
    const forceUpdate = useForceUpdate();

    React.useEffect(() => {
        setIsAdmin(localStorage.getItem("profileUserRole") === "Admin")
        fetchData(1, 100)
    }, []);
    const [hasErrorLoading, setHasErrorLoading] = useState(false);
    const tryAgainFunction = () => {
        setHasErrorLoading(true)
        setOpenLoading(true)

        CustomAxios.GET(process.env.REACT_APP_WEB_API_CHECK_AUDIT_LOGS).then((response) => {
            if (response.status == 200) {
                setHasErrorLoading(false)
                setTabValue(1)
                fetchData(1, 100)
            } else {

            }
        }).finally(() => {
            setHasErrorLoading(false)
            setOpenLoading(false)
        });
    };
    const options = {

    }

    const [selectedDeviceIndexMenu, setSelectedDeviceIndexMenu] = useState([]);
    const [resetSelectedDatasTable, setResetSelectedDatasTable] = useState([]);

    const [editMerchUrlId, setEditMerchUrlId] = useState('');
    const [isSelectedCustomEdit, setIsSelectedCustomEdit] = useState(false);
    const [editMerchUrl, setEditMerchUrl] = useState('');
    const [editMerchCode, setEditMerchCode] = useState('');
    const [isSelectedCustom, setIsSelectedCustom] = React.useState(false);
    function onRowSelectionChangeCustom(rowsSelected) {
        setResetSelectedDatasTable(rowsSelected)
        setIsSelectedCustom(rowsSelected.length == 0 ? false : true);
        setIsSelectedCustomEdit(rowsSelected.length == 1 ? false : true);
        setSelectedDeviceIndexMenu(rowsSelected)
        if (rowsSelected.length === 1) {

        }
        console.log(rowsSelected)

    }
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const [openDelete, setOpenDelete] = React.useState(false);
    const [updateLoading, setUpdateLoading] = React.useState(false);

    const [open2, setOpen2] = React.useState(false);

    const { control: control2, formState: formState2, handleSubmit: handleSubmit2, setError: setError2, setValue: setValue2 } = useForm({
        mode: 'onChange',
        defaultValues2,
        resolver: yupResolver(removeSchema),
    });
    const { isValid: isValid2, dirtyFields: dirtyFields2, errors: errors2 } = formState2;


    const { control, formState, handleSubmit, setError, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(userCreateSchema),
    });
    const { isValid, dirtyFields, errors } = formState;

    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const [addGroup, setAddGroup] = useState(false);
    const [values, setValues] = React.useState([]);

    const [openLoginInfo, setOpenLoginInfo] = useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);
    const BodyComponent = React.useMemo(
        () => (props) => (
            <LoadingTableBody loading={openLoading} {...props} />
        ),
        [openLoading]
    );
    function generateRandomString() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '_+*_';

        for (let i = 1; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }
    function GetUserNameCustomLogic(value) {
        if (value == undefined)
            return value
        const prefix = "_+*_";
        if (value.startsWith(prefix)) {
            return value.substring(11);
        } else {
            return value;
        }
    }


    const dispatch = useDispatch()

    return (

        <Root
            header={<div />}
            content={
                <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">



                    {!addGroup && <motion.div>
                        <motion.div
                            style={{ borderBottom: "1px solid #dce5f3" }}
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <div className="flex flex-col flex-auto">
                                <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                    {t('Audit')}
                                </Typography>
                                <Typography className="font-medium tracking-tight" color="text.secondary">
                                    {t('Audit2')}
                                </Typography>
                            </div>
                            <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">

                            </div>
                        </motion.div>
                        <motion.div className="items-center justify-between py-32 px-24 md:px-32" initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
                            {tabValue === 0 && <Card >
                                <ErrorBoundary hasErrorLoading={hasErrorLoading} tryAgainFunction={tryAgainFunction}>
                                    <MuiDataTable
                                        data={logs}
                                        columns={columns2}
                                        options={
                                            optionsTest
                                        }
                                        components={{ TableBody: BodyComponent }}
                                    />
                                </ErrorBoundary>
                            </Card>}
                            {tabValue === 1 && <Card >
                                <Typography variant="body1" component={'div'}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div className="logo">
                                            <img width="128" src="assets/a.gif" alt="logo" />
                                        </div>
                                    </div>
                                </Typography>
                            </Card>}

                        </motion.div>
                    </motion.div>}


                </ div>
            }
        />


    );
}
export default withRouter(Packagename);