import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { DeveloperBoard } from '@mui/icons-material';
import Key from '@mui/icons-material/VpnKey';
import Plugin from '@mui/icons-material/Extension';
import Alarm from '@mui/icons-material/Alarm';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Operations from './Operations';
import OperationsOverview from './OperationsOverview';
import RecentLogs from './RecentLogs';
import { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeDetail from './HomeDetail';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import HomeHeader from './HomeHeader';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
  },
}));

function Home() {
  const [selectedProject, setSelectedProject] = useState({
    id: 1,
    menuEl: null,
  });


  const [tabValue, setTabValue] = useState(0);

  function handleChangeTab(event, value) {
    setTabValue(value);
  }

  function handleChangeProject(id) {
    setSelectedProject({
      id,
      menuEl: null,
    });
  }

  function handleOpenProjectMenu(event) {
    setSelectedProject({
      id: selectedProject.id,
      menuEl: event.currentTarget,
    });
  }

  function handleCloseProjectMenu() {
    setSelectedProject({
      id: selectedProject.id,
      menuEl: null,
    });
  }

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <Root
      header={<HomeHeader />}
      content={
        <HomeDetail />
      }
    />



  );
}

export default Home;
