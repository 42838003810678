import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Chip, DialogActions, DialogTitle, LinearProgress } from '@mui/material';
import CustomAxios from "./CustomAxios";
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    explanation: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
}));

const UploadPageShared = () => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [isMalware, setIsMalware] = useState(false);
    const [isMalwareStr, setIsMalwareStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [fileContentString, setFileContentString] = useState([]);

    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event, flag) => {
        setIsMalware(flag);
        setFile(event.target.files[0]);
        fileContent(event.target.files[0], flag)

    };

    const handleDialogOpen = (isMalware) => {
        setIsMalware(isMalware);
        setIsMalwareStr(isMalware ? "Update Malware Db" : "Update Whitelist")
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_SETTINGS).then((response) => {
            if (response.status == 200) {
                console.log(response.data)
                setSettings(response.data)

            } else {

            }
        }).finally(() => {

        });

    }, []);

    const fileContent = (file, flag) => {
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))
        const selectedFile = file;

        if (!selectedFile) {
            console.error("No file selected.");
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const fileContent = e.target.result;
                // Parse the fileContent as JSON
                const jsonObject = JSON.parse(fileContent);
                // Convert the object back to a JSON string with spaces and line breaks removed
                const cleanedText = JSON.stringify(jsonObject);
                console.log(cleanedText)
                setFileContentString(cleanedText);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };

        reader.onloadend = (e) => {
            try {
                handleUpload(e.target.result, flag)
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };

        reader.readAsText(selectedFile);
    }
    const handleUpload = (fileContentStringDirect, flag) => {
        setLoading(true)
        dispatch(
            showMessage({
                message: t('PleaseWait'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'info'//success error info warning null
            }))


        CustomAxios.POST(flag ? process.env.REACT_APP_WEB_API_UPDATE_MALWARES : process.env.REACT_APP_WEB_API_UPDATE_WHITELIST, { malwares: fileContentStringDirect }).then((response) => {
            setOpenDialog(false);
            setFile(null);

            dispatch(
                showMessage({
                    message: t('Success'),//text or html
                    autoHideDuration: 3000,//ms
                    anchorOrigin: {
                        vertical: 'top',//top bottom
                        horizontal: 'center'//left center right
                    },
                    variant: 'success'//success error info warning null
                }))
        }).catch(() => {
            dispatch(
                showMessage({
                    message: t('Success2'),//text or html
                    autoHideDuration: 3000,//ms
                    anchorOrigin: {
                        vertical: 'top',//top bottom
                        horizontal: 'center'//left center right
                    },
                    variant: 'error'//success error info warning null
                }))
        }).finally(() => {
            setLoading(false)
        });

    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFile(null);
        setUploadProgress(0);
    };
    const { t } = useTranslation("navigation");
    return (
        <div style={{ margin: "40px" }}>

            <input
                type="file"
                accept=".db"
                onChange={(e) => { setIsMalware(false); handleFileChange(e, false); }}
                id="fileInputW"
                style={{ display: 'none' }}
            />
            <label htmlFor="fileInputW" style={{ textAlign: "center" }}>
                <Button className={classes.button} variant="outlined" color="secondary" component="span" style={{ textAlign: "center" }}>
                    {t('UpdateWhitelist')}
                </Button>
            </label>

            <Typography className="ml-20 font-medium tracking-tight" color="text.secondary">
                {t('UpdateWhitelist2')}
            </Typography>

            <input
                type="file"
                accept=".db"
                onChange={(e) => { setIsMalware(true); handleFileChange(e, true); }}
                id="fileInputM"
                style={{ display: 'none' }}
            />
            <label htmlFor="fileInputM" style={{ textAlign: "center" }}>
                <Button className={classes.button} variant="outlined" color="secondary" component="span" style={{ textAlign: "center" }}>
                    {t('UpdateMalware')}
                </Button>
            </label>

            <Typography variant="subtitle2" className="ml-20 mb-5">
                {t('UpdateMalware2')} :  <Chip size="small" label={settings.malwareVersion} />
            </Typography>
            <Typography className="ml-20 font-medium tracking-tight" color="text.secondary">
                {t('UpdateMalware3')}

            </Typography>
            <Dialog open={openDialog}>
                <DialogTitle >{isMalwareStr}</DialogTitle>

                <DialogContent className="grid place-items-center">

                    <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                        <input
                            type="file"
                            accept=".db"
                            onChange={handleFileChange}
                            id="fileInput"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="fileInput" style={{ textAlign: "center" }}>
                            <Button variant="outlined" color="primary" component="span" style={{ textAlign: "center" }}>
                                Select File
                            </Button>
                        </label>
                        {file && (
                            <div>
                                <Typography variant="subtitle1">
                                    Selected File: {file.name}
                                </Typography>

                            </div>
                        )}


                    </div>

                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button type='submit' color='primary' onClick={handleCloseDialog}>Close</Button>
                    <LoadingButton loading={loading} disabled={!file} type='submit' color='primary' variant='contained' onClick={handleUpload}>Upload</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UploadPageShared;
