import axios from "axios";
import React from "react";

class CustomAxios {
    async GET(uri) {
        let res = null;
        await axios.get(uri,
            { headers: { "Authorization": `Bearer ${window.localStorage.getItem('jwt_access_token')}` } })
            .then(function (response) {
                res = response
            })
        return res;
    }


    async POST(uri, body) {
        let res = null;
        await axios.post(uri,
            body,
            { headers: { "Authorization": `Bearer ${window.localStorage.getItem('jwt_access_token')}` } })
            .then(function (response) {
                res = response
            })
        return res;
    }

    async Post_UnAuth(uri, body) {
        let res = null;
        await axios.post(uri,
            body)
            .then(function (response) {
                res = response
            })
        return res;
    }
}
const instance = new CustomAxios();

export default instance;
