import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { showMessage } from 'app/store/fuse/messageSlice';
import CustomAxios from "app/shared-components/CustomAxios";
import FuseLoading from "@fuse/core/FuseLoading";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const SMTPSettingsForm = (props) => {
    const { t } = useTranslation("navigation");
    const [settings, setSettings] = useState([]);
    useEffect(() => {
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_SETTINGS).then((response) => {
            if (response.status == 200) {
                setSettings(response.data)
                setEncryption(response.data.smtpSendMode)
                setFromAddress(response.data.smtpFromAddress)
                setServerAddress(response.data.smtpServerAddress)
                setPort(response.data.smtpPort)
                setUsername(response.data.smtpUsername)
                setPassword(response.data.smtpPassword)
            } else {

            }
        }).catch(() => {

        }).finally(() => {
            setUpdateLoading(false)
        });
    }, []);

    const classes = useStyles();
    const [sendMode, setSendMode] = useState("SMTP");
    const [encryption, setEncryption] = useState("SSL/TLS");
    const [fromAddress, setFromAddress] = useState("no-reply@cynte.com");
    const [authMethod, setAuthMethod] = useState("LOGIN");
    const [authRequired, setAuthRequired] = useState(false);
    const [serverAddress, setServerAddress] = useState("smtp.office365.com");
    const [port, setPort] = useState("597");
    const [username, setUsername] = useState("no-reply@cynte.com");
    const [password, setPassword] = useState("afi230913awd");
    const [updateLoading, setUpdateLoading] = useState(true);
    const [updateLoading2, setUpdateLoading2] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setUpdateLoading2(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_SETTINGS, {
            sendMode: encryption,
            from: fromAddress,
            server: serverAddress,
            port: port,
            user: username,
            pass: password
        }).then((response) => {
            if (response.status == 200) {
                setSettings(response.data)
                props.successAlert()
            } else {

            }
        }).catch(() => {

        }).finally(() => {
            setUpdateLoading2(false)
        });
    };

    if (updateLoading) {
        return (
            <FuseLoading />
        )
    }
    return (
        <motion.div

            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
            <form onSubmit={handleSubmit}>
                <tr>
                    <td style={{ minWidth: "370px" }}>
                        <FormControl className={classes.formControl} fullWidth style={{ marginTop: "15px" }}>
                            <InputLabel id="send-mode-label">Send Mode</InputLabel>
                            <Select
                                labelId="send-mode-label"
                                id="send-mode"
                                value={sendMode}
                                label="Send Mode"
                                onChange={(e) => setSendMode(e.target.value)}
                            >
                                <MenuItem value="SMTP">SMTP</MenuItem>
                            </Select>
                        </FormControl>
                    </td>
                    <td>

                    </td >
                    <td style={{ minWidth: "370px" }}>
                        <FormControl className={classes.formControl} fullWidth style={{ marginTop: "15px" }}>
                            <InputLabel id="encryption-label">Encryption</InputLabel>
                            <Select
                                labelId="encryption-label"
                                id="encryption"
                                value={encryption}
                                label="Encryption"
                                onChange={(e) => setEncryption(e.target.value)}
                            >
                                <MenuItem value="none">None</MenuItem>
                                <MenuItem value="tls">SSL/TLS</MenuItem>
                                <MenuItem value="ssl">StartTLS</MenuItem>
                            </Select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <td style={{ minWidth: "370px" }}>
                        <TextField
                            style={{ marginLeft: "8px", marginTop: "1px" }}
                            fullWidth
                            label="From Address"
                            value={fromAddress}
                            onChange={(e) => setFromAddress(e.target.value)}
                        />
                    </td>
                    <td>

                    </td >
                    <td style={{ minWidth: "370px" }}>

                    </td>
                </tr>
                <tr>
                    <td style={{ minWidth: "370px" }}>
                        <FormControl fullWidth style={{ marginLeft: "8px", marginTop: "10px" }}>
                            <InputLabel id="auth-method-label">Authentication Method</InputLabel>
                            <Select
                                labelId="auth-method-label"
                                id="auth-method"
                                value={authMethod}
                                onChange={(e) => setAuthMethod(e.target.value)}
                                defaultValue="LOGIN"
                                label="Authentication Method"

                            >
                                <MenuItem value="LOGIN">Login</MenuItem>
                            </Select>
                        </FormControl>
                    </td>
                    <td>

                    </td >
                    <td style={{ minWidth: "370px" }}>

                    </td>
                </tr>
                <tr>
                    <td style={{ minWidth: "370px" }}>
                        <TextField
                            style={{ marginLeft: "8px", marginTop: "10px" }}
                            fullWidth
                            label="Server Address"
                            value={serverAddress}
                            onChange={(e) => setServerAddress(e.target.value)}
                        />
                    </td>
                    <td>

                    </td >
                    <td style={{ minWidth: "370px" }}>
                        <TextField
                            style={{ marginLeft: "8px", marginTop: "10px" }}
                            fullWidth
                            label="Port"
                            value={port}
                            onChange={(e) => setPort(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td style={{ minWidth: "370px" }}>
                        <TextField
                            style={{ marginLeft: "8px", marginTop: "10px" }}
                            fullWidth
                            label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </td>
                    <td>

                    </td >
                    <td style={{ minWidth: "370px" }}>
                        <TextField
                            style={{ marginLeft: "8px", marginTop: "10px" }}
                            fullWidth
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                        />
                    </td>
                </tr>

                <LoadingButton type='submit' loading={updateLoading2} style={{ float: "right", marginTop: "15px", marginBottom: "20px" }} variant="outlined" color="secondary" endIcon={<SendIcon />}>{t('Save')}</LoadingButton>


            </form>
        </motion.div>

    );
};

export default SMTPSettingsForm;
