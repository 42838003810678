import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { motion } from 'framer-motion';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Popover, Tab, Tabs, TextField } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box } from '@mui/system';
import MockApiDoc from './MockApiDoc';
import SwaggerUI from 'swagger-ui-react';
import "swagger-ui-react/swagger-ui.css"
import mockApiJson from './mock-api.json';
import { CopyBlock, dracula } from "react-code-blocks";
import { useTranslation } from 'react-i18next';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { showMessage } from 'app/store/fuse/messageSlice';
import CustomAxios from "app/shared-components/CustomAxios";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    text: {
        marginBottom: theme.spacing(4),
    },
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

function Api() {
    const { t } = useTranslation("navigation");
    const dispatch = useDispatch()
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        setIsAdmin(localStorage.getItem("profileUserRole") === "Admin")
        CustomAxios.GET(process.env.REACT_APP_WEB_API_GET_APIKEY).then((response) => {
            if (response.status == 200) {
                setapikeyValue(response.data)
            } else {

            }
        }).catch(() => {

        }).finally(() => {

        });
    }, []);

    function copyAlert() {
        navigator.clipboard.writeText(apikeyValue)
        dispatch(
            showMessage({
                message: t('Copy'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'success'//success error info warning null
            }))
    }
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [apikeyValue, setapikeyValue] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    function handleChangeTab(event, value) {
        setTabValue(value);
    }
    function generateNewApiKey() {
        setOpen2(true)
        let generateAPIKeyValue = generateAPIKey()
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_APIKEY,
            {
                apiKey: generateAPIKeyValue,
            }).then((response) => {
                if (response.status == 200) {
                    setapikeyValue(generateAPIKeyValue)
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html CheckCurrentPassword
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {
                setOpen2(false)
            });
    }
    function generateAPIKey() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const apiKeyLength = 32;
        let apiKey = '';

        for (let i = 0; i < apiKeyLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            apiKey += characters.charAt(randomIndex);
        }

        return apiKey;
    }
    return (
        <Root
            header={<>

            </>}
            content={
                <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
                    <motion.div >
                        <Dialog onClose={() => setOpen(false)} open={open}>
                            <DialogTitle >X-API-Key</DialogTitle>

                            <DialogContent className="grid place-items-center">

                                <div className="grid grid-cols-1 gap-24 w-full mt-32 sm:mt-16 p-16 sm:p-24 max-w">
                                    {isAdmin && <LoadingButton loading={open2} fullWidth style={{ marginTop: "23px" }} onClick={() => generateNewApiKey()} startIcon={<FuseSvgIcon>material-outline:pattern</FuseSvgIcon>} color='secondary' variant='outlined'>{t('GenerateNewApi')}</LoadingButton>}

                                    <div className="grid grid-cols-4">
                                        <div class="col-span-3">
                                            <TextField
                                                margin="normal"
                                                label="X-API-Key"
                                                type="text"
                                                value={apikeyValue}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                            />
                                        </div>
                                        <div class="col-span-1 ml-8">
                                            <Button style={{ marginTop: "23px" }} onClick={() => copyAlert()} startIcon={<FuseSvgIcon>material-outline:content_copy</FuseSvgIcon>} color='secondary' variant='outlined'>{t('CopyT')}</Button>
                                        </div>
                                    </div>


                                </div>

                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>

                                <Button type='submit' color='primary' variant='contained' onClick={() => setOpen(false)}>{t('OK')}</Button>
                            </DialogActions>
                        </Dialog>

                        <motion.div
                            style={{ borderBottom: "1px solid #dce5f3" }}
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >

                            <div className="flex flex-col flex-auto">
                                <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                    API
                                </Typography>
                                <Typography className="font-medium tracking-tight" color="text.secondary">
                                    {t('RestApiUsage')}
                                </Typography>
                            </div>
                        </motion.div>
                        <div style={{ paddingTop: "33px" }} className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 flex-1 w-full items-center justify-between py-12 px-8 md:px-12">
                            <Tabs
                                value={tabValue}
                                onChange={handleChangeTab}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"
                                scrollButtons={false}
                                className="w-full px-24 -mx-4 min-h-40"
                                classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                                TabIndicatorProps={{
                                    children: (
                                        <Box
                                            sx={{ bgcolor: 'text.disabled' }}
                                            className="w-full h-full rounded-full opacity-20"
                                        />
                                    ),
                                }}
                            >
                                <Tab
                                    className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                                    disableRipple
                                    label="Modern API"
                                />
                                <Tab
                                    className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12"
                                    disableRipple
                                    label="Classic API"
                                />

                            </Tabs>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<FuseSvgIcon>material-outline:vpn_key</FuseSvgIcon>}
                                onClick={() => setOpen(true)}
                                style={{ float: "right", minWidth: "200px" }}
                            >
                                X-API-Key
                            </Button>
                        </div>
                        <motion.div
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >

                            <Card style={{ width: "100%" }}>

                                {tabValue === 0 && <motion.div initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }} ><MockApiDoc></MockApiDoc></motion.div>}
                                {tabValue === 1 && <motion.div initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }} className="mr-32 ml-32" ><SwaggerUI spec={mockApiJson}></SwaggerUI></motion.div>}
                            </Card>
                        </motion.div>

                    </motion.div>
                </div>
            }
        />

    );
}

export default Api;
