import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Tooltip } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import FuseLoading from '@fuse/core/FuseLoading';
import { motion } from 'framer-motion';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

function VisitorsVsPageViewsWidget(props) {
  const { t } = useTranslation("navigation");
  const theme = useTheme();

  const series = [
    {
      "name": t('AvarageRatio'),
      "data": props.selectedMerchantGraph.data,
    }
  ]

  const chartOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '100%',
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.light, theme.palette.primary.light],
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: [theme.palette.primary.dark, theme.palette.primary.light],
      opacity: 0.5,
    },
    grid: {
      show: false,
      padding: {
        bottom: -40,
        left: 0,
        right: 0,
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    tooltip: {
      followCursor: true,
      theme: 'dark',
      x: {
        formatter: function (value) {
          return format(new Date(value), 'MMM dd, y');
        }
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        offsetY: -20,
        rotate: 0,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      tickAmount: 3,
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.divider,
        },
      },
      max: (max) => max + 250,
      min: (min) => min - 250,
      show: false,
      tickAmount: 5,
    },
  };
  function truncateDecimal(value, fix) {
    const roundedValue = Math.round(value * 100) / 100;
    const truncatedValue = Number(roundedValue); // Convert the rounded value back to a number

    return truncatedValue;
  }

  function isPositive(number) {
    return Math.sign(number) === 1; // Returns true if the number is positive, false otherwise
  }
  console.log(props.selectedMerchantGraph.overallScore)
  if (props.selectedMerchantGraph.overallScore === undefined) {
    return (<FuseLoading></FuseLoading>)
  }
  return (
    <motion.div
      initial={{ x: 10, opacity: 0 }}
      animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
        <div className="flex items-start justify-between m-24 mb-0">
          <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
            {t('MerchantRatioAnalysis')}
          </Typography>
          <div className="ml-8">
            <Chip size="small" className="font-medium text-sm" label={t('ThirtyDays')} />
          </div>
        </div>
        <div className="flex items-start mt-24 mx-24">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-42 sm:gap-48">
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="font-medium text-secondary leading-5">{t('Overall')}</div>
                <Tooltip title={t('OverallDesc')}>
                  <FuseSvgIcon className="ml-6" size={16} color="disabled">
                    heroicons-solid:information-circle
                  </FuseSvgIcon>
                </Tooltip>
              </div>
              <div className="flex items-start mt-8">
                <div className="text-4xl font-bold tracking-tight leading-none">{truncateDecimal(props.selectedMerchantGraph.overallScore, 3)}</div>
                <div className="flex items-center ml-8">
                  {props.selectedMerchantGraph.percentageChangeOverall == 0 ? <FuseSvgIcon className="text-blue-500" size={20}>
                    material-outline:multiple_stop
                  </FuseSvgIcon> : (isPositive(props.selectedMerchantGraph.percentageChangeOverall) ?
                    <FuseSvgIcon className="text-green-500" size={20}>
                      heroicons-solid:arrow-circle-up
                    </FuseSvgIcon> : <FuseSvgIcon className="text-red-500" size={20}>
                      heroicons-solid:arrow-circle-down
                    </FuseSvgIcon>)}
                  <Typography className={props.selectedMerchantGraph.percentageChangeOverall == 0 ? "ml-4 text-md font-medium text-blue-500" : (isPositive(props.selectedMerchantGraph.percentageChangeOverall) ? "ml-4 text-md font-medium text-green-500" : "ml-4 text-md font-medium text-red-500")}>{truncateDecimal(props.selectedMerchantGraph.percentageChangeOverall, 2)}%</Typography>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="font-medium text-secondary leading-5">{t('AvarageRatio')}</div>
                <Tooltip title={t('AvrRatioDesc')}>
                  <FuseSvgIcon className="ml-6" size={16} color="disabled">
                    heroicons-solid:information-circle
                  </FuseSvgIcon>
                </Tooltip>
              </div>
              <div className="flex items-start mt-8">
                <div className="text-4xl font-bold tracking-tight leading-none">{truncateDecimal(props.selectedMerchantGraph.averageRatioLast30Days, 3)}%</div>
                <div className="flex items-center ml-8">
                  {props.selectedMerchantGraph.percentageChangeLastDay == 0 ? <FuseSvgIcon className="text-blue-500" size={20}>
                    material-outline:multiple_stop
                  </FuseSvgIcon> : (isPositive(props.selectedMerchantGraph.percentageChangeLastDay) ?
                    <FuseSvgIcon className="text-green-500" size={20}>
                      heroicons-solid:arrow-circle-up
                    </FuseSvgIcon> : <FuseSvgIcon className="text-red-500" size={20}>
                      heroicons-solid:arrow-circle-down
                    </FuseSvgIcon>)}
                  <Typography className={props.selectedMerchantGraph.percentageChangeLastDay == 0 ? "ml-4 text-md font-medium text-blue-500" : (isPositive(props.selectedMerchantGraph.percentageChangeLastDay) ? "ml-4 text-md font-medium text-green-500" : "ml-4 text-md font-medium text-red-500")}>{truncateDecimal(props.selectedMerchantGraph.percentageChangeLastDay, 2)}%</Typography>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="flex flex-col flex-auto h-320 mt-12">
          <ReactApexChart
            className="flex-auto w-full h-full"
            options={chartOptions}
            series={series}
            type={chartOptions.chart.type}
            height={chartOptions.chart.height}
          />
        </div>
      </Paper>
    </motion.div>
  );
}

export default VisitorsVsPageViewsWidget;
