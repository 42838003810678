import Paper from '@mui/material/Paper';
import { lighten, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { memo, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { selectWidgets } from '../../../store/widgetsSlice';
import { useTranslation } from 'react-i18next';

function GithubIssuesWidget(props) {
  const { t } = useTranslation("navigation");
  const theme = useTheme();
  const [awaitRender, setAwaitRender] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const labels = [
    t('l1'),
    t('l2'),
    t('l3'),
    t('l4'),
    t('l5'),
    t('l6'),
    t('l7')
  ]
  const series = [
    {
      "name": t('Rd'),
      "type": "line",
      "data": [
        props.totalObjects?.Devices?.Monday || 0,
        props.totalObjects?.Devices?.Tuesday || 0,
        props.totalObjects?.Devices?.Wednesday || 0,
        props.totalObjects?.Devices?.Thursday || 0,
        props.totalObjects?.Devices?.Friday || 0,
        props.totalObjects?.Devices?.Saturday || 0,
        props.totalObjects?.Devices?.Sunday || 0
      ]
    },
    {
      "name": t('Th'),
      "type": "column",
      "data": [
        props.totalObjects.Threats.Monday || 0,
        props.totalObjects.Threats.Tuesday || 0,
        props.totalObjects.Threats.Wednesday || 0,
        props.totalObjects.Threats.Thursday || 0,
        props.totalObjects.Threats.Friday || 0,
        props.totalObjects.Threats.Saturday || 0,
        props.totalObjects.Threats.Sunday || 0
      ]
    }
  ]
  const chartOptions = {
    chart: {
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '100%',
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    labels,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      background: {
        borderWidth: 0,
      },
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        },
      },
    },
    stroke: {
      width: [3, 0],
    },
    tooltip: {
      followCursor: true,
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        offsetX: -16,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };

  useEffect(() => {
    setAwaitRender(false);
  }, []);

  if (awaitRender) {
    return null;
  }

  return (
    <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          {t('AnalysisSummary')}
        </Typography>
        <div className="mt-12 sm:mt-0 sm:ml-8">

        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-24 w-full mt-32 sm:mt-16">
        <div className="flex flex-col flex-auto">
          <Typography className="font-medium" color="text.secondary">
            {t('Rdth')}
          </Typography>
          <div className="flex flex-col flex-auto">
            <ReactApexChart
              className="flex-auto w-full"
              options={chartOptions}
              series={series}
              height={320}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Typography className="font-medium" color="text.secondary">
            {t('Overview')}
          </Typography>
          <div className="flex-auto grid grid-cols-4 gap-16 mt-24">
            <div className="col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
              <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                {props.totalObjects.DeviceCount}
              </Typography>
              <Typography className="mt-4 text-sm sm:text-lg font-medium">{t('Devices2')}</Typography>
            </div>
            <div className="col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-red-50 text-red-800">
              <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                {props.totalObjects.ThreatCount}
              </Typography>
              <Typography className="mt-4 text-sm sm:text-lg font-medium">{t('Threats2')}</Typography>
            </div>

          </div>
        </div>
      </div>
    </Paper>
  );
}

export default memo(GithubIssuesWidget);
