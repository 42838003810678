import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { makeStyles } from '@mui/styles';
import { Box, Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import format from 'date-fns/format';
import clsx from 'clsx';
import Paper from '@mui/material/Paper';
import { motion } from 'framer-motion';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import VisitorsVsPageViewsWidget from './VisitorsVsPageViewsWidget';
import { GetDescMcc } from './GetDescMcc';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    chartContainer: {
        marginTop: '20px',
        width: '100%',
        height: '400px',
    },
});
function getDomainFromUrl(urlString) {
    if (!urlString || typeof urlString !== "string") {
        return "NA"; // Return "NA" if the URL string is invalid or empty
    }

    const url = new URL(urlString);
    const domain = url.hostname.startsWith("www.")
        ? url.hostname.substring(4)
        : url.hostname;

    return domain;
}
const container = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};
const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
};
const SearchAnalysisChart = (props) => {
    const { t } = useTranslation("navigation");

    const labels = [t('Eligible'), t('NonEligible')]

    const columns = [t('Date'), t('Merchant'), t('MCC'), t('Ratio'), t('Status')]

    const min = 60;
    const max = 80;
    const randomNum = Math.floor(Math.random() * (max - min + 1) + min);
    const theme = useTheme();
    let series = [props.responseRatio, 100 - props.responseRatio]

    const chartOptions = {
        chart: {
            animations: {
                speed: 400,
                animateGradually: {
                    enabled: false,
                },
            },
            fontFamily: 'inherit',
            foreColor: 'inherit',
            height: '100%',
            type: 'donut',
            sparkline: {
                enabled: true,
            },
        },
        colors: ['#319795', '#4FD1C5'],
        labels,
        plotOptions: {
            pie: {
                customScale: 0.9,
                expandOnClick: false,
                donut: {
                    size: '70%',
                },
            },
        },
        stroke: {
            colors: [theme.palette.background.paper],
        },
        series,
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
        tooltip: {
            enabled: true,
        },
    };
    const classes = useStyles();
    const [chartData, setChartData] = useState([]);

    const dat = [{ date: Date.now(), merchant: props.website, mcc: props.mcc, ratio: props.selectedMerchant ? props.selectedMerchant.ratio : props.responseRatio, status: props.selectedMerchant ? (props.selectedMerchant.ratio >= props.threshold ? 'success' : 'failed') : 'success' }]
    useEffect(() => {

        const formattedData = dat.map(({ date, ratio }) => ({
            date: new Date(date).toLocaleDateString(),
            ratio: Number(ratio),
        }));
        setChartData(formattedData);
    }, []);



    return (
        <>

            {props.goBack && <Typography variant="h5" className='m-10' style={{ marginLeft: "30px", marginBottom: "-5px" }}>             <div onClick={props.goBack} style={{ cursor: "pointer" }}>
                <FuseSvgIcon size={25} style={{ color: "blue", marginRight: "5px", marginTop: "5px", float: "left" }} >
                    heroicons-outline:arrow-sm-left
                </FuseSvgIcon>
            </div>{t('SingleSearchResult')}</Typography>}
            <div >
                <motion.div
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
                    variants={container}
                    initial="hidden"
                    animate="show"
                >
                    <motion.div variants={item} className="sm:col-span-2 md:col-span-4 lg:col-span-2">
                        {props.goBack && <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden p-24">
                            <div className="flex flex-col sm:flex-row items-start justify-between">
                                <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
                                    {t('Eligibility')}
                                </Typography>
                                <div className="ml-8">
                                    <Chip size="small" className="font-medium text-sm" label={t('Result')} />
                                </div>
                            </div>

                            <div className="flex flex-col flex-auto mt-24 h-192">
                                <ReactApexChart
                                    className="flex flex-auto items-center justify-center w-full h-full"
                                    options={chartOptions}
                                    series={series}
                                    type={chartOptions.chart.type}
                                    height={chartOptions.chart.height}
                                />
                            </div>
                            <div className="mt-32">
                                <div className="-my-12 divide-y">
                                    {series.map((dataset, i) => (
                                        <div className="grid grid-cols-3 py-12" key={i}>
                                            <div className="flex items-center">
                                                <Box
                                                    className="flex-0 w-8 h-8 rounded-full"
                                                    sx={{ backgroundColor: chartOptions.colors[i] }}
                                                />
                                                <Typography className="ml-12 truncate">{labels[i]}</Typography>
                                            </div>

                                            <Typography className="text-right" color="text.secondary">
                                                {dataset}%
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Paper>}
                        {!props.goBack && <VisitorsVsPageViewsWidget selectedMerchantGraph={props.selectedMerchantGraph}></VisitorsVsPageViewsWidget>}
                    </motion.div>
                    <motion.div variants={item} className="sm:col-span-2 md:col-span-4 lg:col-span-2">
                        <Paper style={{ height: '40%', marginBottom: "40px" }} className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
                            <div className="table-responsive mt-24">
                                {props.selectedMerchant ? (
                                    props.selectedMerchant.ratio >= props.threshold ?
                                        <Typography className="font-medium tracking-tight" color="text.secondary" style={{ fontSize: "20px" }}>
                                            <FuseSvgIcon style={{ float: "left", marginTop: "2px", marginRight: "5px" }}>material-outline:store</FuseSvgIcon> {t('MerchantAnalyzeResultText')} <b><a style={{ background: "white", color: "rgb(11, 61, 90)" }} href={dat[0].merchant} target="_blank">{getDomainFromUrl(dat[0].merchant)}</a></b> {t('MerchantAnalyzeResultText2')} <Chip label={dat[0].mcc + " - " + GetDescMcc(dat[0].mcc)}></Chip> {t('MerchantAnalyzeResultText3')}
                                        </Typography>
                                        :
                                        <Typography className="font-medium tracking-tight" color="text.secondary" style={{ fontSize: "20px" }}>
                                            <FuseSvgIcon style={{ float: "left", marginTop: "2px", marginRight: "5px" }}>material-outline:store</FuseSvgIcon> {t('MerchantAnalyzeResultNText')} <b><a style={{ background: "white", color: "rgb(11, 61, 90)" }} href={dat[0].merchant} target="_blank">{getDomainFromUrl(dat[0].merchant)}</a></b> {t('MerchantAnalyzeResultNText2')} %{props.threshold - props.selectedMerchant.ratio} {t('MerchantAnalyzeResultNText3')} <Chip label={dat[0].mcc + " - " + GetDescMcc(dat[0].mcc)}></Chip> {t('MerchantAnalyzeResultNText4')}
                                        </Typography>
                                )
                                    :
                                    props.responseRatio >= props.threshold ?
                                        <Typography className="font-medium tracking-tight" color="text.secondary" style={{ fontSize: "20px" }}>
                                            <FuseSvgIcon style={{ float: "left", marginTop: "2px", marginRight: "5px" }}>material-outline:store</FuseSvgIcon> {t('MerchantAnalyzeResultText')} <b><a style={{ background: "white", color: "rgb(11, 61, 90)" }} href={dat[0].merchant} target="_blank">{getDomainFromUrl(dat[0].merchant)}</a></b> {t('MerchantAnalyzeResultText2')} <Chip label={dat[0].mcc + " - " + GetDescMcc(dat[0].mcc)}></Chip> {t('MerchantAnalyzeResultText3')}
                                        </Typography>
                                        :
                                        <Typography className="font-medium tracking-tight" color="text.secondary" style={{ fontSize: "20px" }}>
                                            <FuseSvgIcon style={{ float: "left", marginTop: "2px", marginRight: "5px" }}>material-outline:store</FuseSvgIcon> {t('MerchantAnalyzeResultNText')} <b><a style={{ background: "white", color: "rgb(11, 61, 90)" }} href={dat[0].merchant} target="_blank">{getDomainFromUrl(dat[0].merchant)}</a></b> {t('MerchantAnalyzeResultNText2')} %{props.threshold - props.responseRatio} {t('MerchantAnalyzeResultNText3')} <Chip label={dat[0].mcc + " - " + GetDescMcc(dat[0].mcc)}></Chip> {t('MerchantAnalyzeResultNText4')}
                                        </Typography>
                                }

                            </div>
                        </Paper>
                        <Paper style={{ height: '50%' }} className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
                            <div className="table-responsive mt-24">
                                <Table className="simple w-full min-w-full">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <TableCell key={index}>
                                                    <Typography
                                                        color="text.secondary"
                                                        className="font-semibold text-12 whitespace-nowrap"
                                                    >
                                                        {column}
                                                    </Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {dat.map((row, index) => (
                                            <TableRow >
                                                <TableCell component="th" scope="row">
                                                    <Typography className="">
                                                        {props.selectedMerchant ? format(new Date(props.selectedMerchant.updatedTime), 'hh:mm MM/dd/y') : format(new Date(Date.now()), 'hh:mm MM/dd/y')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography className="" >
                                                        {row.merchant}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography className="" >
                                                        <Chip label={row.mcc}></Chip>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography className="" >
                                                        {row.ratio}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    <Typography>
                                                        <FuseSvgIcon className={row.status === 'success' ? "text-green" : "text-red"} size={25}>
                                                            {row.status === 'success' ? "heroicons-outline:check-circle" : "heroicons-outline:minus-circle"}
                                                        </FuseSvgIcon>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {
                                    dat.length == 0 ? (
                                        <motion.div
                                            initial={{ x: 10, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
                                            style={{ textAlign: "center", paddingTop: '20px' }}>
                                            <div className="logo">
                                                <Typography className="font-medium tracking-tight" color="text.secondary">{t('LNo3')}</Typography>
                                            </div>
                                        </motion.div>
                                    ) : <></>
                                }
                            </div>
                        </Paper>
                    </motion.div>
                </motion.div>

            </div>
        </>
    );
};

export default SearchAnalysisChart;