import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Paper from '@mui/material/Paper';
import ReactApexChart from 'react-apexcharts';
import Box from '@mui/material/Box';
import { lighten, useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ApiIcon from '@mui/icons-material/Store';
import ShieldMoonOutlinedIcon from '@mui/icons-material/AccessAlarm';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HistoryEduIcon from '@mui/icons-material/FingerprintOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUserOutlined';
import { DeveloperBoard } from '@mui/icons-material';
import Plugin from '@mui/icons-material/Category';
import CustomAxios from "app/shared-components/CustomAxios";
import Autosuggest from 'react-autosuggest';
import withRouter from '@fuse/core/withRouter';
import { Card } from '@mui/material';

function Operations(props) {
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const labels = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
    ]
    const series = [
        {
            "name": "Eligible Merchants",
            "type": "line",
            "data": [
                42,
                28,
                43,
                34,
                20,
                25,
                22
            ]
        },
        {
            "name": "Threat Detection",
            "type": "column",
            "data": [
                11,
                10,
                8,
                11,
                8,
                10,
                17
            ]
        }
    ]
    const chartOptions = {
        chart: {
            fontFamily: 'inherit',
            foreColor: 'inherit',
            height: '100%',
            type: 'line',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        colors: [theme.palette.primary.main, theme.palette.secondary.main],
        labels,
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            background: {
                borderWidth: 0,
            },
        },
        grid: {
            borderColor: theme.palette.divider,
        },
        legend: {
            show: false,
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
            },
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.75,
                },
            },
        },
        stroke: {
            width: [3, 0],
        },
        tooltip: {
            followCursor: true,
            theme: theme.palette.mode,
        },
        xaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                color: theme.palette.divider,
            },
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            labels: {
                offsetX: -16,
                style: {
                    colors: theme.palette.text.secondary,
                },
            },
        },
    };

    const [seriesState, setSeriesState] = useState(series);
    const [totalObjects, setTotalObjects] = useState([]);
    const [weekOp, setWeekOp] = useState([]);
    useEffect(() => {

        CustomAxios.GET(process.env.REACT_APP_WEB_API_DASHBOARD_TOTAL_OBJECTS).then((response) => {
            if (response.status == 200) {
                console.log(response.data)
                setTotalObjects(response.data);
            } else {

            }

        }).finally(() => {
            forceUpdate()
        });
        CustomAxios.GET(process.env.REACT_APP_WEB_API_DASHBOARD_LOGS_TYPE_WEEK).then((response) => {
            if (response.status == 200) {

                setWeekOp(response.data);
            } else {

            }

        }).finally(() => {
            forceUpdate()
        });

        CustomAxios.GET(process.env.REACT_APP_WEB_API_DASHBOARD_LOGS_WEEK).then((response) => {
            if (response.status == 200) {

                response.data.forEach(function (item) {
                    const existingItem = seriesState[0].data.find(function (d) {
                        return d.x === item.x;
                    });
                    if (existingItem) {
                        existingItem.y = item.y;
                    }
                });

                setSeriesState(seriesState)
                console.log(response.data);
            } else {

            }

        }).finally(() => {
            forceUpdate()
        });
    }, []);
    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
        // A function that increment 👆🏻 the previous state like here 
        // is better than directly setting `setValue(value + 1)`
    }
    const forceUpdate = useForceUpdate();
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    return (
        <div>
            <div className="flex-auto grid grid-cols-1 sm:grid-cols-3 gap-16 mt-24 mb-24">
                <div style={{ cursor: "pointer" }} onClick={() => props.navigate(`/apps`)} className="divShadow  flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-indigo-50 text-indigo-800">
                    <ApiIcon fontSize='large'></ApiIcon>
                    <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                        {totalObjects.appCount ?? 113}
                    </Typography>

                    <Typography className="mt-4 text-sm sm:text-lg font-medium">Merchants</Typography>
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => props.navigate(`/keys`)} className="divShadow  flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
                    <ShieldMoonOutlinedIcon fontSize='large'></ShieldMoonOutlinedIcon>
                    <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                        {totalObjects.soCount ?? 0}
                    </Typography>
                    <Typography className="mt-4 text-sm sm:text-lg font-medium">Alarms</Typography>
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => props.navigate(`/plugins`)} className="divShadow  flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-yellow-50 text-yellow-800">
                    <Plugin fontSize='large'></Plugin>
                    <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                        {totalObjects.pluginCount ?? 25}
                    </Typography>
                    <Typography className="mt-4 text-sm sm:text-lg font-medium">MCC's</Typography>
                </div>

            </div>
            <Card style={{ padding: "20px" }}>
                <div className="flex flex-col flex-auto">
                    <Typography className="font-medium" color="text.secondary">
                        This Week
                    </Typography>
                    <div className="flex flex-col flex-auto">
                        <ReactApexChart
                            className="flex-auto w-full"
                            options={chartOptions}
                            series={seriesState}
                            height={320}
                        />
                    </div>
                </div>
            </Card>
        </div>

    );
}

export default withRouter(memo(Operations));
