import React from 'react';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import HookIcon from '@mui/icons-material/Security';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import CableIcon from '@mui/icons-material/Cable';
import RootIcon from '@mui/icons-material/AdminPanelSettings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmulatorIcon from '@mui/icons-material/ImportantDevices';
import MalwareIcon from '@mui/icons-material/Dangerous';
import DebugIcon from '@mui/icons-material/BugReport';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';

const ThreatColumn = ({ hasThreats }) => {
    const parsedData = JSON.parse(JSON.parse(hasThreats));
    console.log("ml len: ", parsedData.MalwareDetected.length)
    // Check each property one by one
    const hasThreatsTest =
        parsedData != null || undefined || "" ?
            (parsedData.Hooked ||
                parsedData.Rooted ||
                parsedData.RunningInEmulator ||

                parsedData.DebuggerAttached ||

                parsedData.ScreenMirror ||

                parsedData.IsRunningInVirtualEnvironment ||

                parsedData.MalwareDetected.length > 0) : false

    console.log(hasThreatsTest)
    // Define the content of the chip based on the threat status
    const chipContent = (hasThreatsTest) ? (
        <div style={{ paddingLeft: '10px' }}>
            {parsedData.IsRunningInVirtualEnvironment && <Tooltip title={'RunningInVirtualEnvironment'}><VpnLockIcon style={{ color: 'red' }} /></Tooltip>}
            {parsedData.ScreenMirror && <Tooltip title={'ScreenMirror'}><MobileScreenShareIcon style={{ color: 'red' }} /></Tooltip>}
            {parsedData.DebuggerAttached && <Tooltip title={'DebuggerAttached'}><DebugIcon style={{ color: 'red' }} /></Tooltip>}
            {parsedData.Hooked && <Tooltip title={'Hooked'}><HookIcon style={{ color: 'red' }} /></Tooltip>}
            {parsedData.Rooted && <Tooltip title={'Rooted'}><RootIcon style={{ color: 'red' }} /></Tooltip>}
            {parsedData.RunningInEmulator && <Tooltip title={'Emulator Detected'}><EmulatorIcon style={{ color: 'red' }} /></Tooltip>}
            {parsedData.MalwareDetected.length > 0 && (
                <Tooltip
                    title={"Malware Found"}
                >
                    <CoronavirusOutlinedIcon style={{ color: 'red' }} />
                </Tooltip>
            )}

        </div>
    ) : (
        <CheckIcon style={{ color: 'green' }} />
    );

    // Define the label for the chip
    const chipLabel = hasThreatsTest ? '' : 'No Threats';

    return (
        <Chip
            icon={chipContent}
            label={chipLabel}
            variant="outlined"
            color={hasThreatsTest ? 'error' : 'success'}
        />
    );
};

export default ThreatColumn;
