import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { motion } from 'framer-motion';
import TwoFactorAuthentication from '../sign-in/TwoFactorAuthentication';
import SendIcon from '@mui/icons-material/Send';
import Draggable from 'react-draggable';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { lg } from '@tailwindcss/typography/src/styles';
import QRCode from 'qrcode.react';
import FormGroup from '@mui/material/FormGroup';
import CustomAxios from "app/shared-components/CustomAxios";
import Switch from '@mui/material/Switch';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import SMTPSettingsForm from './SMTPSettingsFrom';
import { useTranslation } from 'react-i18next';

const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
        margin: "auto",
        maxWidth: "800px",
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    qrCode: {
        margin: theme.spacing(2),
    },
    input: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),

    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
    },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const editSchema = yup.object().shape({
    userName: yup.string().required(''),
    email: yup.string().required(''),
    phoneNumber: yup.string().required('').matches(phoneRegExp, 'Phone number is not valid'),
    password: yup.string().required('')
});

const SMTPSettings = () => {
    const { t } = useTranslation("navigation");
    const dispatch = useDispatch();

    const [loggedInfoMail, setLoggedInfoMail] = useState('');
    const [loggedInfoPhone, setLoggedInfoPhone] = useState('');
    const [loggedInfoUsername, setLoggedInfoUsername] = useState('');
    const [loggedInfoTwoFa, setLoggedInfoTwoFa] = useState('');
    const [loggedInfoHasAuth, setLoggedInfoHasAuth] = useState('');
    const [recoveryCodes, setRecoveryCodes] = useState([]);
    const [setupOtpUrl, setSetupOtpUrl] = useState('');
    const [sharedKey, setSharedKey] = useState('');

    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
        // A function that increment 👆🏻 the previous state like here 
        // is better than directly setting `setValue(value + 1)`
    }
    const forceUpdate = useForceUpdate();

    React.useEffect(() => {

    }, []);



    function successAlert() {
        dispatch(
            showMessage({
                message: t('Success'),//text or html
                autoHideDuration: 3000,//ms
                anchorOrigin: {
                    vertical: 'top',//top bottom
                    horizontal: 'center'//left center right
                },
                variant: 'success'//success error info warning null
            }))
    }
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [twoFactorAuth, setTwoFactorAuth] = useState(false);

    const classes = useStyles();
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const handleCloseTest = () => {
        setOpenEditDialog(false);
    };
    const [qrCodeValue, setQrCodeValue] = useState('');
    const connectTo2FAApp = () => {

    };

    function handelSwitch(e) {
        var doSet = e.target.checked
        CustomAxios.POST(process.env.REACT_APP_WEB_API_MANAGE_2FA,
            {
                mail: localStorage.getItem('profileMail'),
                disable: doSet
            }).then((response) => {
                if (response.status == 200) {
                    dispatch(
                        showMessage({
                            message: doSet ? t('Enabled') : t('Disabled'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    setLoggedInfoTwoFa(doSet)
                } else {

                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('Success2'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            }).finally(() => {

            });

    }
    const [updateLoading, setUpdateLoading] = React.useState(false);
    const { control, formState, handleSubmit, setError, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(editSchema),
    });
    const { isValid, dirtyFields, errors } = formState;

    function updateUser({ userName, email, phoneNumber, password }) {
        setUpdateLoading(true)
        CustomAxios.POST(process.env.REACT_APP_WEB_API_UPDATE_USER,
            { ClientId: localStorage.getItem('clientId'), userName: userName, email: email, phoneNumber: phoneNumber, password: password }).then((response) => {
                if (response.status == 200) {
                    localStorage.setItem('profileUsername', userName)
                    setLoggedInfoUsername(userName)
                    localStorage.setItem('profilePhone', phoneNumber)
                    setLoggedInfoPhone(phoneNumber)
                    dispatch(
                        showMessage({
                            message: t('Success'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'success'//success error info warning null
                        }))
                    window.location.reload();
                } else {
                    dispatch(
                        showMessage({
                            message: t('Success2'),//text or html
                            autoHideDuration: 3000,//ms
                            anchorOrigin: {
                                vertical: 'top',//top bottom
                                horizontal: 'center'//left center right
                            },
                            variant: 'error'//success error info warning null
                        }))
                }
            }).catch(() => {
                dispatch(
                    showMessage({
                        message: t('CheckPassword'),//text or html
                        autoHideDuration: 3000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }))
            })
            .finally(() => {
                setUpdateLoading(false)
            });
    }
    return (
        <Root
            header={<div />}
            content={
                <div className="w-full p-12 pt-2 sm:pt-2 lg:ltr:pr-0 lg:rtl:pl-0">
                    <Dialog PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={openEditDialog} PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}>

                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Setup TwoFactor<div onClick={handleCloseTest} style={{ cursor: "pointer", float: "right", marginTop: "-8px" }}><FuseSvgIcon>material-outline:close</FuseSvgIcon></div></DialogTitle>

                        <DialogContent className="grid place-items-center p-0" >

                        </DialogContent>

                    </Dialog>
                    <motion.div >
                        <motion.div
                            style={{ borderBottom: "1px solid #dce5f3" }}
                            className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <div className="flex flex-col flex-auto">
                                <Typography className="text-3xl font-semibold tracking-tight leading-8">
                                    {t('SETTINGS')}
                                </Typography>
                                <Typography className="font-medium tracking-tight" color="text.secondary">
                                    {t('Customizesystemsettings')}
                                </Typography>
                            </div>

                        </motion.div>

                        <motion.div
                            className="items-center justify-between py-32 px-24 md:px-32"
                            initial={{ x: 20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                        >
                            <Card style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "-20px" }}>

                                <div className={classes.root}>
                                    <div>
                                        <Grid item xs={12} sm={6} >

                                            <Typography variant="h5"><FuseSvgIcon style={{ float: "left", margin: "15px" }}>material-outline:mail</FuseSvgIcon> {t('SMTPsettings')}</Typography>
                                            <Typography variant="subtitle2">
                                                {t('SmtpDesc')}
                                            </Typography>
                                            <div className="p-16 sm:p-24 max-w-3xl">
                                                <SMTPSettingsForm successAlert={successAlert}></SMTPSettingsForm>
                                            </div>

                                        </Grid>


                                    </div>
                                </div>


                            </Card>
                        </motion.div>


                    </motion.div>

                </ div>
            }
        />

    );
};

export default SMTPSettings;
