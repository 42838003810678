import React, { useState } from 'react';
import { Button, Snackbar, IconButton, InputAdornment, TextField } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const CopyComponent = ({ textToCopy }) => {
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(textToCopy);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message="Copied to clipboard!"
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <span>&times;</span>
                    </IconButton>
                }
                style={{ marginTop: "50px" }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            <TextField
                color='secondary' variant='standard'
                style={{ maxWidth: "300px", borderRadius: '0 0 0 0', border: "0" }}
                value={textToCopy}
                fullWidth
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color='secondary' variant='outlined' onClick={handleCopyClick}>
                                <FileCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default CopyComponent;
