import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import format from 'date-fns/format';
import clsx from 'clsx';
import Button from '@mui/material/Button';

function RecentLogs() {
    const columns = ['Transaction ID', 'Date', 'Type', 'KeyLabel', 'Status']
    const rows = [
        { id: '528651571NT', date: '2022-10-07T22:22:37.274Z', type: 'encrypt', keyLabel: "des3_test", status: 'success' },
        { id: '421436904YT', date: '2022-12-18T14:51:24.461Z', type: 'encrypt', keyLabel: "aes_256", status: 'success' },
        { id: '685377421YT', date: '2022-12-25T17:52:14.304Z', type: 'sign', keyLabel: "aes_256", status: 'failed' },
        { id: '884960091RT', date: '2022-11-29T06:32:16.111Z', type: 'verify', keyLabel: "aes_256", status: 'success' },
        { id: '361402213NT', date: '2022-11-24T12:13:23.064Z', type: 'decrypt', keyLabel: "aes_128", status: 'success' }
    ]
    return (
        <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
            <div className="">
                <Typography className="mr-16 text-lg font-medium tracking-tight leading-6 truncate">
                    Recent Logs
                </Typography>
                <Typography className="font-medium" color="text.secondary">
                    Transactions
                </Typography>
            </div>

            <div className="table-responsive mt-24">
                <Table className="simple w-full min-w-full">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index}>
                                    <Typography
                                        color="text.secondary"
                                        className="font-semibold text-12 whitespace-nowrap"
                                    >
                                        {column}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                {Object.entries(row).map(([key, value]) => {
                                    switch (key) {
                                        case 'id': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="" color="text.secondary">
                                                        {value}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        case 'date': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="">
                                                        {format(new Date(value), 'MMM dd, y')}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        case 'amount': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="">
                                                        {value.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                        })}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        case 'status': {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography
                                                        className={clsx(
                                                            'inline-flex items-center font-bold text-10 px-10 py-2 rounded-full tracking-wide uppercase',
                                                            value === 'failed' &&
                                                            'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                                                            value === 'success' &&
                                                            'bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50'
                                                        )}
                                                    >
                                                        {value}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        default: {
                                            return (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography className="">{value}</Typography>
                                                </TableCell>
                                            );
                                        }
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className="pt-24">
                    <Button variant="outlined">See all transactions</Button>
                </div>
            </div>
        </Paper>
    );
}

export default RecentLogs;
