import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectWidgets } from '../../../store/widgetsSlice';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
function FeaturesWidget(props) {
  const { t } = useTranslation("navigation");
  const { mostCommonMerchantCode } = props.totalObjects;

  const renderMerchantCode = () => {
    if (mostCommonMerchantCode) {
      if (mostCommonMerchantCode.length > 15) {
        return (
          <Tooltip title={mostCommonMerchantCode}>
            <b className="px-8">{mostCommonMerchantCode.substring(0, 15) + '...'}</b>
          </Tooltip>
        );
      } else {
        return <b className="px-8">{mostCommonMerchantCode}</b>;
      }
    } else {
      return <b className="px-8">NA</b>;
    }
  };
  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
        <Typography
          className="px-16 text-lg font-medium tracking-tight leading-6 truncate"
          color="text.secondary"
        >
          MCC's
        </Typography>
        <IconButton aria-label="more" size="large">
          <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="text-center mt-8">
        <Typography className="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-green-500">
          {props.totalObjects.merchantCodes}
        </Typography>
        <Typography className="text-lg font-medium text-green-600">{t('Mcc')}</Typography>
      </div>
      <Typography className="flex items-baseline justify-center w-full mt-20 mb-24" color="text.secondary">
        <span className="truncate">{t('TopMcc')}</span>:
        {renderMerchantCode()}
      </Typography>
    </Paper>
  );
}

export default memo(FeaturesWidget);
