import React, { Component } from 'react';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import { Button } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { LoadingButton } from '@mui/lab';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: props.hasError };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // Inline styles for error message and container
            const containerStyle = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '10vh',
            };
            const containerStyle2 = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '6vh',
            };

            const errorMessageStyle = {
                fontSize: '16px',

            };

            return (
                <>
                    <div style={containerStyle}>
                        <NearbyErrorIcon style={{ color: "red" }}></NearbyErrorIcon> <h4 style={errorMessageStyle}>Something went wrong.</h4>

                    </div>
                    <div style={containerStyle2}>

                        <LoadingButton
                            loading={this.props.hasErrorLoading}
                            component="span" style={{ textAlign: "center", float: "left" }}
                            variant="outlined"
                            color="secondary"
                            startIcon={<FuseSvgIcon>material-outline:refresh</FuseSvgIcon>}
                            onClick={this.props.tryAgainFunction}
                        >
                            Try Again
                        </LoadingButton>
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
